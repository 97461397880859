import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import OpenPorts from "../../home/components/tables/AttackSurface/OpenPorts";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { NmapFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { useLocation } from "react-router";

const OpenPortsTable = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const { title } = props;
  const { state } = useLocation();

  const [openPortsTableData, setOpenPortsTableData] = useState([]);
  const [openPortsFilterData, setOpenPortsFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getLiveHostTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardOpenPortsTable(id).then((data) => {
    //   if (data) {
    //     setOpenPortsTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    NmapFilterAPI.post({ ...filterParams, ...state })
      .then((res) => {
        setOpenPortsTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    NmapFilterAPI.get({ scanID: scanID, ...state })
      .then((res) => {
        setOpenPortsFitlerData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (Object.keys(filterParams).length > 1) getLiveHostTable();
  }, [filterParams]);

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap  dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <OpenPorts
          openPortsTableData={openPortsTableData}
          openPortsFilterData={openPortsFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          loadingTable={loadingTable}
        />
      </div>
    </>
  );
};

export default OpenPortsTable;
