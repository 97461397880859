import { useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { IoAddCircleOutline } from "react-icons/io5";
import { GrDocument } from "react-icons/gr";
import { GoDownload } from "react-icons/go";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { CiUser } from "react-icons/ci";
import axios from "axios";

import { DateTimeToDate } from "../../../../../helpers";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import fileUpload from "../../../../../utils/fileUpload";
import HandleResponseError from "../../../../../utils/error";
import UseTemplate from "./UseTemplate";

export default function ViewPolicy({
  currentDetails,
  setCurrentDetails,
  onHide,
  columns,
}) {
  const fileInputRef = useRef(null);
  const commentRef = useRef(null);

  const uploadAttachment = () => {
    const file = fileInputRef.current.files[0];

    if (file) {
      fileUpload("ultraheals/v1/compliance/attachments/", file)
        .then((res) => {
          if (res.status === "success") {
            complianceAPI
              .patch(`/policy/${currentDetails.id}/`, {
                attachments: [...currentDetails.attachments, res.data.id],
              })
              .then((res) => {
                setCurrentDetails(res);
                // setCurrentDetails({
                //   ...currentDetails,
                //   attachments: [...currentDetails.attachments, res.data.id],
                //   attachments_info: [
                //     ...currentDetails.attachments_info,
                //     res.data,
                //   ],
                // });
                toast.success("Attachment uploaded successfully");
              })
              .catch((err) => {
                toast.error("Error in uploading attachment");
              });
          } else {
            HandleResponseError(res.message);
          }
        })
        .catch((err) => {
          toast.error("Error in uploading attachment");
        });
    }
  };

  const handleDownload = (file) => {
    axios
      .get(file.file, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers.contentType,
        });
        const url = URL.createObjectURL(blob);

        // Create a link and trigger a click event to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name; // Change file extension to .yml
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = (id) => {
    const updatedAttachements = currentDetails.attachments_info.filter(
      (i) => i.id != id
    );
    complianceAPI
      .patch(`/policy/${currentDetails.id}/`, {
        attachments: updatedAttachements.map((i) => i.id),
      })
      .then((res) => {
        // setCurrentDetails({
        //   ...currentDetails,
        //   attachments: updatedAttachements.map((i) => i.id),
        //   attachments_info: updatedAttachements,
        // });
        setCurrentDetails(res);
        toast.success("Attachment removed successfully");
      })
      .catch((err) => {
        toast.error("Error in uploading attachment");
      });
  };

  const uploadStatus = (status) => {
    complianceAPI
      .patch(`/policy/${currentDetails.id}/`, {
        status: status,
      })
      .then((res) => {
        if (res.id) {
          toast.success("Policy updated successfully");
          onHide();
        } else {
          HandleResponseError(res.message);
        }
      })
      .catch((err) => {
        toast.error("Error in uploading attachment");
      });
  };

  const renderStatusButton = (status) => {
    let nextStatus = null;
    let buttonText = null;

    switch (status) {
      case "not_uploaded":
        nextStatus = "draft";
        buttonText = "Draft";
        break;
      case "draft":
        //   nextStatus = "need_review";
        //   buttonText = "Request Review";
        //   break;
        // case "need_review":
        nextStatus = "approved";
        buttonText = "Approve";
        break;
      case "approved":
        nextStatus = "published";
        buttonText = "Publish";
        break;
      case "published":
        nextStatus = null;
        buttonText = "Published";
      default:
        break;
    }

    return (
      <button
        className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff]"
        onClick={() => uploadStatus(nextStatus)}
        disabled={!nextStatus}
      >
        {buttonText}
      </button>
    );
  };

  const AddComment = () => {
    const comment = commentRef.current.value;
    if (comment) {
      complianceAPI
        .post("/comments/", {
          comment: comment,
        })
        .then((data) => {
          if (data.id) {
            complianceAPI
              .patch(`/policy/${currentDetails.id}/`, {
                comments: [...currentDetails.comments, data.id],
              })
              .then(() => {
                setCurrentDetails({
                  ...currentDetails,
                  comments: [...currentDetails.comments, data.id],
                  comments_info: [...currentDetails.comments_info, data],
                });
                toast.success("Comment added successfully");
              })
              .catch((err) => {
                console.log(err);

                toast.error("Error in adding comment");
              });
          } else {
            toast.error("Error in adding comment");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error in adding comment");
        });
    }
  };

  const AddURLs = (e) => {
    e.preventDefault();
    const url = e.target.url.value;

    if (currentDetails?.urls?.find((i) => url === i)) {
      toast.error("URL already exists");
      return;
    }

    complianceAPI
      .patch(`/policy/${currentDetails.id}/`, {
        urls: currentDetails?.urls ? [...currentDetails?.urls, url] : [url],
      })
      .then((res) => {
        if (res.id) {
          toast.success("Policy updated successfully");
          setCurrentDetails(res);
          e.target.url.value = "";
        } else {
          HandleResponseError(res.message);
        }
      })
      .catch((err) => {
        toast.error("Error in adding Policy");
      });
  };

  const handleURLsDelete = (i) => {
    const updatedURLs = currentDetails?.urls.filter((d) => d !== i);
    complianceAPI
      .patch(`/policy/${currentDetails.id}/`, {
        urls: updatedURLs,
      })
      .then((res) => {
        if (res.id) {
          toast.success("Policy updated successfully");
          setCurrentDetails(res);
        } else {
          HandleResponseError(res.message);
        }
      })
      .catch((err) => {
        toast.error("Error in adding Policy");
      });
  };

  const sendNotification = () => {
    complianceAPI
      .post("/notify/policy/", {
        id: currentDetails.id,
      })
      .then((res) => {
        if (res.status === "success") {
          toast.success("Notification sent successfully");
        } else {
          toast.error("Notification sent error: " + res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={onHide}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl text-center">{currentDetails?.name}</p>
      <div className="px-6">
        <div className="flex justify-between pt-6">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Policy Requirement</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.requirement}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Assigned To</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.assignee
                ? columns
                    ?.find((c) => c.field === "assignee")
                    ?.value.find((v) => v.value === currentDetails?.assignee)
                    ?.label
                : null}
            </p>
          </div>
          <div className="w-1/2">
            <div className="flex justify-between">
              <h1 className="text-sm text-color font-bold">Approver</h1>
              <button
                className="text-[#074EE8] text-xs flex items-center gap-1"
                onClick={() => sendNotification()}
              >
                Notify Approver
              </button>
            </div>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.approver
                ? columns
                    ?.find((c) => c.field === "approver")
                    ?.value.find((v) => v.value === currentDetails?.approver)
                    ?.label
                : null}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Entity</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.entities
                ? columns
                    ?.find((c) => c.field === "entities")
                    ?.value.find((v) => v.value === currentDetails?.entities)
                    ?.label
                : null}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Department</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.department
                ? columns
                    ?.find((c) => c.field === "department")
                    ?.value.find((v) => v.value === currentDetails?.department)
                    ?.label
                : null}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Recurrence</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.recurrence
                ? columns
                    ?.find((c) => c.field === "recurrence")
                    ?.value.find((v) => v.value === currentDetails?.recurrence)
                    ?.label
                : null}
            </p>
          </div>
          <div className="w-1/2">
            <h1 className="text-sm text-color font-bold">Review Date</h1>
            <p className="text-xs text-color font-normal leading-5">
              {DateTimeToDate(currentDetails?.review_date)}
            </p>
          </div>
        </div>
        <div className="flex justify-between pt-6">
          <div className="w-full flex justify-between">
            <h1 className="text-sm text-color font-bold">Attachments</h1>
            <div className="flex gap-1 items-center">
              <button
                className="text-[#074EE8] text-xs flex items-center gap-1"
                onClick={() => fileInputRef.current.click()}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  hidden
                  onChange={uploadAttachment}
                />
                <IoAddCircleOutline />
                Add Attachment
              </button>
              <p>/</p>
              <UseTemplate
                id={currentDetails?.id}
                setCurrentDetails={setCurrentDetails}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col pt-6 gap-2">
          {currentDetails?.attachments_info?.map((a, i) => (
            <div
              key={i}
              className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border"
            >
              <div className="flex items-center">
                <GrDocument />
                <div className="p-1 ml-2 flex flex-col">
                  <p className="text-sm text-color font-normal leading-5">
                    {a.name}
                  </p>
                  <p className="text-xs">{DateTimeToDate(a.created_at)}</p>
                </div>
              </div>
              <div className="justify-self-end grid grid-cols-2 divide-x gap-2">
                <div
                  className="text-sm flex items-center gap-1 cursor-pointer"
                  onClick={() => handleDownload(a)}
                >
                  <GoDownload />
                  <p>Download</p>
                </div>
                <div
                  className="text-sm flex items-center gap-1 cursor-pointer"
                  onClick={() => handleDelete(a.id)}
                >
                  <MdDeleteOutline />
                  <p>Delete</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-between pt-6">
          <h1 className="text-sm text-color font-bold">URLs</h1>
          {currentDetails?.urls?.map((c, i) => (
            <div
              key={i}
              className="flex gap-2 my-1 items-center justify-between p-2 border rounded"
            >
              <a
                href={c}
                target="_blank"
                rel="noreferrer"
                className="text-blue-700"
              >
                {c}
              </a>
              <div
                className="text-sm flex items-center gap-1 cursor-pointer"
                onClick={() => handleURLsDelete(c)}
              >
                <MdDeleteOutline />
                <p>Delete</p>
              </div>
            </div>
          ))}
          <form onSubmit={(e) => AddURLs(e)} className="flex my-1">
            <input
              id="url"
              type="url"
              required
              rows={2}
              className="w-full border-b-[2px] rounded p-2"
              placeholder="Add a url to docs..."
            />
            <button
              className="mx-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff] self-end"
              type="submit"
            >
              Add
            </button>
          </form>
        </div>
        <div className="flex justify-end pt-6">
          {renderStatusButton(currentDetails?.status)}
        </div>
        <div className="flex flex-col justify-between pt-6">
          <h1 className="text-sm text-color font-bold">Comments</h1>
          {currentDetails?.comments_info?.map((c, i) => (
            <div
              key={i}
              className="flex gap-2 my-2 items-center p-2 border rounded"
            >
              <div className="flex w-10 h-10 rounded-full items-center justify-center">
                <CiUser />
              </div>
              <div className="flex flex-col">
                <p className="text-xs text-color font-normal">{c.comment}</p>
                {/* <p className="text-sm text-color font-bold">{c.user}</p> */}
              </div>
            </div>
          ))}
          <textarea
            ref={commentRef}
            rows={2}
            className="w-full border-b-[2px] rounded p-2"
            placeholder="Add a comment"
          ></textarea>
          <button
            className="mt-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] px-4 bg-[#181818] text-[#ffff] self-end"
            onClick={AddComment}
          >
            Add Comment
          </button>
        </div>
      </div>
    </Sidebar>
  );
}
