import React, { useState } from "react";
import { useEffect } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import UserInformationTable from "./tables/UserInformationTable";
import { useDispatch, useSelector } from "react-redux";
import { setSidebarVisible } from "../../../redux-toolkit/redux/TableData";
import { UserFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { isSubscriberOrAdminOrSuperAdmin } from "../../../utils/accessCheck";

const UserInformation = (props) => {
  const userDetails = useSelector(getUserDetails);
  const { isDarkMode, title, subtitle, btnText } = props;
  const [userTableData, setUserTableData] = useState([]);
  const [userTableViewData, setTableViewData] = useState(false);
  const [role, setRole] = useState([]);
  const [orgUsers, setOrgUsers] = useState([]);
  const [loadingTable, setLoadingTable] = useState(true);
  const [FilterData, setFilterData] = useState([]);
  const [filterParams, setFilterParams] = useState({});

  const getUserInformationTableData = () => {
    setLoadingTable(true);
    // TableApi.userInformationTableData().then((data) => {
    //   setUserTableData(data.data);
    //   setLoadingTable(false);
    // });
    UserFilterAPI.post(filterParams)
      .then((res) => {
        setUserTableData(
          res.data.map((item) => ({
            ...item,
            status: item.status ? "Active" : "Pending",
          }))
        );
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    UserFilterAPI.get()
      .then((res) => {
        setFilterData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  const getRoles = () => {
    TableApi.getUserRole().then((data) => {
      setRole(data.data);
    });
  };

  const getUserByRole = () => {
    let payload = {
      id: "5",
    };
    console.log("orgUsers1", payload);
    TableApi.getUserByRole(payload).then((data) => {
      console.log("orgUsers2", data);
      if (data.status === "success") {
        // toast.success("User Created Successfully");
        setOrgUsers(data.data);
        // message.success(data.message)
        // setVisibleRight(false);
        // } else {
        // toast.error("Something went wrong");
      }
    });
  };

  useEffect(() => {
    getRoles();
    getTableFilterData();
    getUserByRole();
  }, []);

  useEffect(() => {
    if (Object.keys(filterParams).length > 0) getUserInformationTableData();
  }, [filterParams]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <UserInformationTable
          userInformation={userTableData}
          setTableViewData={setTableViewData}
          loadingTable={loadingTable}
          isDarkMode={isDarkMode}
          btnText={isSubscriberOrAdminOrSuperAdmin(userDetails.role)}
          btnName="Create User"
          role={role}
          userTableViewData={userTableViewData}
          getUserInformationTableData={getTableFilterData}
          FilterData={FilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          orgUsers={orgUsers}
        />
      </div>
    </>
  );
};

export default UserInformation;
