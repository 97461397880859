import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import VerticalBarChart from "../../../common/Charts/VerticalBarChart";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import ComplianceAffectAssets from "./ComplianceAffectAssets";
import { useNavigate } from "react-router-dom";
import complianceLockImage from "../../../assets/lockscreen/compliance.jpg";
import complianceAssetsLockImage from "../../../assets/lockscreen/compliance_assets.jpg";

import { getSubscription } from "../../../redux-toolkit/redux/TableData";

const Compliances = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [complianceData, setComplianceData] = useState([]);
  // const [showCompliance, setShowCompliance] = useState(true);
  const [complianceAffectAssetData, setComplianceAffectAssetData] = useState(
    []
  );
  // const [showComplianceAsset, setShowComplianceAsset] = useState(true);

  const userDetails = useSelector(getUserDetails);
  const subScriptionData = useSelector(getSubscription);

  const complianceLabels = [
    "IS0 27001",
    "PCI DSS",
    "SOC 2",
    "GDPR",
    // "OWASP",
    "HIPAA",
  ];
  const getCompliance = (payload) => {
    DashboardApi.dashboardCompliancesData(payload).then((data) => {
      const iso = data.data?.iso;
      const pci = data.data?.pci;
      const soc = data.data?.soc;
      const nist = data.data?.nist;
      // const owasp = data.data?.owasp;
      const gdpr = data.data?.gdpr;
      const compliance = [iso, pci, soc, gdpr, nist];
      setComplianceData([...compliance]);
      // setShowCompliance(data.show);
    });
  };

  const getComplianceAffectAsset = (payload) => {
    DashboardApi.dashboardCompliancesAffectData(payload).then((data) => {
      if (data.status === "success") {
        setComplianceAffectAssetData(data.data);
        // setShowComplianceAsset(data.show);
      }
    });
  };

  // const handleNavigate = (e, type) => {
  //   e.preventDefault();
  //   if (type === "compliance" && showCompliance) {
  //     navigate(`/attack-surface-management/${type}`);
  //   }
  // };

  const handleBarGraphOnClick = (c, i) => {
    if (!i.length) return;
    const index = i[0].index;
    navigate(
      `/attack-surface-management/compliance#${complianceLabels[index].replace(
        " ",
        "-"
      )}`
    );
  };

  useEffect(() => {
    const payload = {
      unique_id: userDetails.unique_id,
      scan_id: userDetails.scan_id,
    };
    if (subScriptionData?.plan !== "Basic") {
      getCompliance(payload);
      getComplianceAffectAsset(payload);
    }
  }, [userDetails]);
  // console.log("showCompliance:", showCompliance);

  return (
    <div className="py-10">
      <div className="pb-6">
        <h1 className="text-2xl font-bold">Compliance</h1>
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-6 flex flex-col gap-6">
        <div
          // onClick={(e) => handleNavigate(e, "compliance")}
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl cursor-pointer`}
        >
          <VerticalBarChart
            title="Compliances"
            data={complianceData}
            labels={complianceLabels}
            lockedWidget={subScriptionData?.plan !== "Basic"}
            lockImage={complianceLockImage}
            onClick={handleBarGraphOnClick}
            tooltip="The Compliance Module aligns vulnerabilities with impacted controls for robust regulatory compliance"
            backgroundColors={[
              "#33B2DF",
              "#546E7A",
              "#D4526E",
              "#13D8AA",
              // "#A5978B",
              "#33B2DF",
            ]}
          />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl cursor-pointer`}
        >
          <ComplianceAffectAssets
            title="Compliance Affect Assets"
            complianceAffectAssetData={complianceAffectAssetData}
            lockedWidget={subScriptionData?.plan !== "Basic"}
            lockImage={complianceAssetsLockImage}
            tooltip="Number of assets affected by compliance regulations"
          />
        </div>
      </div>
    </div>
  );
};

export default Compliances;
