import React, { useContext, useEffect, useState } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../context/ThemeContext";
import IpAddress from "../../home/components/tables/AttackSurface/IpAddress";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { NmapFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";

const IpAddressTable = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const { title } = props;
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id

  const [ipAddressTableData, setIpAddressTableData] = useState([]);
  const [FilterData, setFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({scanID: scanID})
  const [loadingTable, setLoadingTable] = useState(true);
  const [countData, setCountData] = useState();

  const getIpAddressDataTable = () => {
    setLoadingTable(true);
    NmapFilterAPI.post({...filterParams, unique_by: 'ip'})
    .then(res=> {
      setIpAddressTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
    })
    .catch(err => console.log(err))
  };

  const getTableFilterData = () => {
    NmapFilterAPI.get({scanID: scanID, unique_by: 'ip'})
    .then(res => {
      setFitlerData(res.data)
      setFilterParams({...filterParams, ...res.data})
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getTableFilterData()
  }, []);

  useEffect(()=>{
    if (Object.keys(filterParams).length > 1) getIpAddressDataTable()
  },[filterParams])
  
  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">{title} <span> ({countData}) </span></p>
        </div>
        <IpAddress loadingTable={loadingTable} ipAddressTableData={ipAddressTableData}
                FilterData={FilterData}
                setFilterParams={setFilterParams}
                filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default IpAddressTable;
