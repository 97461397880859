import React, { useState, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import { FiMoreVertical } from "react-icons/fi";
import { ThemeContext } from "../../context/ThemeContext";

import { DateTimeToDate, SeverityLevelLabel } from "../../helpers";

import { FiArrowUpRight } from "react-icons/fi";
import { SidebarSlide } from "../../Component/Header/SidebarSlide";

import paginationTemplate from "../../utils/paginationTemplate";

const DarkWebDataTables = (props) => {
  const { dt, data, globalFilter, type } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [openVulnerabilitySideBar, setOpenVulnerabilitySideBar] =
    useState(false);
  const [currentDetail, setCurrentDetail] = useState(null);
  const [sidebarTitle, setSidebarTitle] = useState("");

  const items = (data) => [
    {
      label: "Mark important",
      icon: "pi pi-refresh",
      command: (e) => {},
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: (e) => {},
    },
  ];

  const splitBtn = (e, d) => {
    // console.log("dtaa",d,e); /* Getting data here*/
  };

  const infoBody = (rowData) => {
    return (
      <button onClick={() => handleShowDetailVulnerability(rowData)}>
        <FiArrowUpRight size={20} color="#074EE8" />
      </button>
    );
  };

  const handleShowDetailVulnerability = (rowData) => {
    console.log("title", type, rowData);
    if (type) {
      setSidebarTitle(type);
      setOpenVulnerabilitySideBar(true);
      // setLoadingSidebarVocDetail(true);
      setCurrentDetail(rowData);
    }
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const renderLink = (rowData) => {
    const urlWithoutAnchor = rowData?.replace(/<\/?a[^>]*>/g, "");
    return (
      <a href={urlWithoutAnchor} className="text-[#074EE8]">
        {urlWithoutAnchor}
      </a>
    );
  };

  return (
    <>
      <DataTable
        removableSort
        value={data.map((item) => {
          return {
            ...item,
            severity: SeverityLevelLabel(item.severity),
            date_added: DateTimeToDate(item.date_added),
          };
        })}
        ref={dt}
        selection={selectedUsers}
        onSelectionChange={(e) => setSelectedUsers(e.value)}
        paginator
        rows={10}
        sortField="severity"
        sortOrder={1}
        globalFilter={globalFilter}
        paginatorTemplate={paginationTemplate()}
        className={`${
          isDarkMode ? "dark-mode text-dark" : " text-color"
        } text-xs`}
      >
        <Column selectionMode="multiple" exportable={false}></Column>
        <Column field="keyword" header="Keyword" sortable></Column>
        <Column
          field="threat"
          header="Threat Classification"
          sortable
          style={{ width: "50%" }}
          body={(rowData) => {
            if (type === "Darkweb Threats - Telegram") {
              return rowData?.label;
            } else {
              return rowData?.threat;
            }
          }}
        ></Column>
        <Column
          field="severity"
          header="Severity"
          sortable
          body={(rowData) => {
            let color = null;
            if (rowData.severity === "Medium") {
              color = "#F5B207";
            } else if (rowData.severity === "Critical") {
              color = "#A30123";
            } else if (rowData.severity === "High") {
              color = "#E8001D";
            } else if (rowData.severity === "Low") {
              color = "#148842";
            } else {
              color = "#7B7DE2";
            }
            return <p className={`text-[${color}]`}>{rowData.severity}</p>;
          }}
        ></Column>
        <Column field="date_added" header="Date" sortable></Column>
        <Column
          field="url"
          header="Source"
          sortable
          body={(rowData) => {
            if (type === "Darkweb Threats - Telegram") {
              return rowData?.channel;
            } else {
              return rowData?.url;
            }
          }}
        ></Column>
        <Column field="findings" header="Tags" sortable></Column>
        <Column field="findings" header="Status" sortable></Column>
        <Column
          header="Full Info"
          sortable
          body={(rowData) => infoBody(rowData)}
        ></Column>
      </DataTable>
      <div>
        <SidebarSlide
          title={sidebarTitle}
          visibleRight={openVulnerabilitySideBar}
          setVisibleRight={setOpenVulnerabilitySideBar}
          customWidth={true}
          sideBarVocIntelligenceDetail={currentDetail}
          darkweb={true}
        />
      </div>
    </>
  );
};

export default DarkWebDataTables;
