import { createElement, useContext, useEffect, useState } from "react";
import DetailedView from "./DetailedView";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { useParams } from "react-router-dom";
import MitigationTask from "../vendor/MitigationTask";
import Controls from "../../../../../screens/compliance/Controls";

export default function ViewRisk() {
  const { id } = useParams();
  const { isDarkMode } = useContext(ThemeContext);

  const [data, setData] = useState(null);
  const [currentTab, setCurrentTab] = useState("details");

  const getData = () => {
    complianceAPI
      .get(`/risk/${id}/`)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const TABS = [
    {
      id: "details",
      title: "Details",
      component: DetailedView,
      props: {
        data,
        setData,
        getData,
      },
    },
    {
      id: "controls",
      title: "Controls",
      component: Controls,
      props: {
        id: data?.controls,
      },
    },
    {
      id: "mitigation_task",
      title: "Mitigation Task",
      component: MitigationTask,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h1 className="font-bold mb-2">{data?.name}</h1>
      <div
        className={`flex gap-5 border-b-2 my-2 mx-2 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-4 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component, {
          ...TABS.find((tab) => tab.id === currentTab)?.props,
        })}
      </div>
    </>
  );
}
