import { createElement, useContext, useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { CiUnlock } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { FaRegEdit } from "react-icons/fa";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import Overview from "./Overview";
import Compliance from "./Compliance";
import Controls from "./Controls";

export default function Dashboard() {
  const { isDarkMode } = useContext(ThemeContext);
  const [data, setData] = useState(null);

  const STATS = [
    {
      title: "Total Portal Views",
      icon: FaEye,
      data: data?.logs.view || 0,
    },
    {
      title: "Documents Accessed",
      icon: HiOutlineDocumentDownload,
      data: data?.logs.download || 0,
    },
    {
      title: "Access Requests Granted",
      icon: CiUnlock,
      data: data?.access.active || 0,
    },
    {
      title: "Pending Requests",
      icon: IoIosSearch,
      data: data?.access.inactive || 0,
    },
  ];

  const getData = () => {
    complianceAPI
      .get("/trust-center/dashboard/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-3">
        <div className="grid lg:grid-cols-2 gap-3">
          {STATS.map((item) => (
            <div
              className={`rounded-2xl  p-5  ${
                isDarkMode ? "header-dark" : "header-light white-background"
              }`}
            >
              <div className="flex justify-between">
                <p className="font-bold">{item.title}</p>
                {createElement(item.icon)}
              </div>
              <p className="font-bold text-lg">{item.data}</p>
            </div>
          ))}
        </div>
        <Overview />
      </div>
      <Compliance />
      <Controls />
    </div>
  );
}
