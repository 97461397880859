import React from "react";
import { LoginHeader, LoginFooter } from "../../Component/Header";
import LoginForm from "../../modules/login/components/LoginForm";
import AuthSideBar from "../../Component/Header/AuthSideBar";

const Login = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <LoginHeader
        title=""
        subTitle=""
        navigate=""
      />
      <div className="flex justify-evenly w-full">
        <div className="header-dark hidden md:block w-[60%]">
          <AuthSideBar />
        </div>
        <div className="md:w-[40%] sm:w-full">
        <LoginForm />
        </div>
      </div>
      <div className="mt-auto">
        <LoginFooter />
      </div>
    </div>
  );
};

export default Login;
