import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useSelector } from "react-redux";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { DateTimeToDateTime } from "../../../../helpers";
import paginationTemplate from "../../../../utils/paginationTemplate";

export default function Impersonation() {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.scan_id;

  const api = new TableFilterApiBase(
    "ultraheals/v1/web/digital-risk/impersonationleaks/filter/"
  );
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filterParams, setFilterParams] = useState({ scan });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const columns = [
    { field: "user_url", header: "Account", value: filterData?.user_url },
    { field: "reason", header: "Reason", value: filterData?.reason },
    { field: "platform", header: "Platform", value: filterData?.platform },
    {
      field: "original_account",
      header: "Original Account",
      value: filterData?.original_account,
    },
    { field: "update_field_name", header: "Tags", value: [] },
    { field: "update_field_name", header: "Status", value: [] },
  ];

  const getFilterData = () => {
    api
      .get({ scan })
      .then((res) => {
        setFilterData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({ ...filterParams, scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (Object.keys(filterParams).length > 1) getData();
  }, [filterParams]);

  return (
    <div className="ssl-active">
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Impersonation <span> ({data?.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              value={data}
              rows={10}
              ref={ref}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.type === "date") {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      body={(rowData) =>
                        DateTimeToDateTime(rowData?.details?.created_at)
                      }
                    />
                  );
                }
                return (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                  />
                );
              })}
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
