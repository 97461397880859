import React, { useState } from "react";
// import { scanHistoryData } from '../../../constants';
import { useNavigate } from "react-router-dom";
import {
  DateTimeToDate,
  convertIsoToReadableFormat,
  groupDataByYear,
} from "../../../helpers";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { UserIcon } from "../../../assets/svg";
import { useEffect } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  setUserDetails,
} from "../../../redux-toolkit/redux/auth";
import { isAdminOrSuperAdmin } from "../../../utils/accessCheck";
import { cspmAPI } from "../../../Api/CSPM/api";

const ScanHistoryCSPM = (props) => {
  const { handleCloseSideBar, scanHistoryData } = props;

  const userDetails = useSelector(getUserDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(null);

  const [data, setData] = useState([]);

  const getData = () => {
    cspmAPI
      .get("/scan-history/")
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (scanHistoryData.length === 0) {
      getData();
    } else {
      setData(scanHistoryData);
    }
  }, [scanHistoryData]);

  const years = Array.from(
    new Set(
      data.map((item) => {
        return new Date(item.started_at).getFullYear();
      })
    )
  );

  useEffect(() => {
    if (userDetails?.cpsm_scan_id) {
      const date = data.find(
        (item) => item.id === userDetails.cpsm_scan_id
      )?.started_at;
      setSelectedYear(new Date(date).getFullYear());
    }
    // else {
    // dispatch(setUserDetails({ ...userDetails, cpsm_scan_id: data[0]?.id }));
    // setSelectedYear(new Date(data[0]?.started_at).getFullYear());
    // }
    else {
      setSelectedYear(new Date().getFullYear());
    }
  }, [userDetails]);

  const handleYearClick = (e) => {
    e.preventDefault();
    // setSelectedYear(selectedYear === year ? null : year);
    setSelectedYear(!selectedYear);
  };

  const handleScanData = (e, item) => {
    e.preventDefault();
    dispatch(setUserDetails({ ...userDetails, cpsm_scan_id: item.id }));
    handleCloseSideBar();
  };

  return (
    <div className="py-8 px-5">
      <div>
        {years.map((year) => {
          return (
            <>
              <button
                className="px-10 text-[#F14D34] font-bold text-sm flex items-center gap-3 py-3"
                onClick={(e) => {
                  if (selectedYear === year) setSelectedYear(null);
                  else setSelectedYear(year);
                }}
              >
                {year}
                <span>
                  {year === selectedYear ? (
                    <IoIosArrowDown size={20} color="#181818" />
                  ) : (
                    <IoIosArrowForward size={20} color="#181818" />
                  )}
                </span>
              </button>
              <ol className="relative border-l-2 mt-4">
                {year === selectedYear &&
                  data
                    .filter(
                      (item) => year === new Date(item.started_at).getFullYear()
                    )
                    .map((item) => (
                      <li key={item.id} className="mb-10 ml-6">
                        <div
                          className={`absolute flex items-center mt-1 justify-center w-2 h-2 rounded-full -left-[5px] ring-4 ring-white bg-[#F14D34]`}
                        ></div>
                        <div
                          onClick={(e) => handleScanData(e, item)}
                          style={{ cursor: "pointer" }}
                        >
                          <p className="text-base font-semibold text-color leading-4 cursor-pointer">
                            {DateTimeToDate(item.started_at)}
                          </p>
                          <div className="py-2 flex gap-2 items-center">
                            <img src={UserIcon} alt="user" />
                            <h1 className="text-color text-xs">
                              {isAdminOrSuperAdmin(userDetails.role) || userDetails.role === "MT Admin" 
                                ? item.user__name
                                : item.type}
                            </h1>
                          </div>
                          <div>
                            <p className="text-xs font-normal text-[#9F9F9F] py-1">
                              {`${item.provider} - ${
                                item.account_id
                              } (${item.status
                                .replace("_", " ")
                                .charAt(0)
                                .toUpperCase()}${item.status.slice(1)})`}
                            </p>
                          </div>

                          {userDetails?.cpsm_scan_id == item.id && (
                            <div>
                              <p className=" text-[12px] text-[#F14D34] font-normal">
                                Current on display
                              </p>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
              </ol>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ScanHistoryCSPM;
