import { useLocation, useParams } from "react-router-dom";

import AddRequirement from "../../../modules/home/components/compliance/AddRequirement";
import ComplianceTable from "../../../modules/home/components/compliance/complianceTable";

export default function Requirement({ data = null }) {
  const { id } = useParams();
  const { state } = useLocation();

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "category",
      header: "Category",
    },
    {
      field: "code",
      header: "Code",
    },
    {
      field: "description",
      header: "Description",
    },
    {
      field: "out_of_scope",
      header: "Scope",
      type: "select",
      value: [
        { label: "In Scope", value: false },
        { label: "Out of Scope", value: true },
      ],
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title={`${
        state?.name && !data?.filters ? state.name + " - " : ""
      }Requirement`}
      columns={columns}
      url_path="/requirement/"
      filter={{ framework: state?.id || id, ...data?.filters }}
      AddComponent={AddRequirement}
      allowAdd={!data?.filters}
    />
  );
}
