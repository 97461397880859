import { useEffect, useState } from "react";
import ViewTemplateLibrary from "../../modules/home/components/compliance/ViewTemplateLibrary";
import { complianceAPI } from "../../Api/CSPM/api";

export default function TemplateLibrary() {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(null);

  const getData = () => {
    complianceAPI
      .post("/template-library/filter/")
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold">Template Library</h1>
        <button
          className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
          onClick={() => setVisible(true)}
        >
          Add new template
        </button>
      </div>
      <div className="grid grid-cols-4 gap-3 mt-3">
        {data.map((item) => (
          <div
            className="p-3 border rounded-md grid gap-3 content-between"
            onClick={() => {
              setCurrent(item);
              setVisible(true);
            }}
          >
            <p className="font-bold">{item.title}</p>
            <p className="text-xs">{item?.frameworks?.join(",")}</p>
          </div>
        ))}
      </div>
      <ViewTemplateLibrary
        visible={visible}
        setVisible={setVisible}
        current={current}
        setCurrent={setCurrent}
        getData={getData}
      />
    </>
  );
}
