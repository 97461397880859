import React, { useContext, useEffect, useState } from "react";
import DashboardApi from "../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../context/ThemeContext";
import SubDomain from "../../home/components/tables/AttackSurface/SubDomain";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { SubdomainFilterAPI } from "../../../Api/FIlterAPI/tableFilterApi";

const SubDomainTable = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id

  const { title } = props;

  const [subDomainTableData, setSubDomainTableData] = useState([]);
  const [subDomainFilterData, setSubDomainFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({scanID: scanID})
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const getSubDomainDataTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardSubDomainTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setSubDomainTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    SubdomainFilterAPI.post(filterParams)
    .then(res=> {
        setSubDomainTableData(res.data);
        setCountData(res.data_len);
        setLoadingTable(false);
    })
    .catch(err => console.log(err))
  };

  const getTableFilterData = () => {
    SubdomainFilterAPI.get({scanID: scanID})
    .then(res => {
      setSubDomainFitlerData(res.data)
      setFilterParams({...filterParams, ...res.data})
    })
    .catch(err => console.log(err))
  }


  useEffect(() => {
    // const id = userDetails.scan_id
    // getSubDomainDataTable(id);
    getTableFilterData()
  }, []);

  useEffect(()=>{
    if (Object.keys(filterParams).length > 1) getSubDomainDataTable()
  }, [filterParams])

  return (
    <>
      <div
        className={`rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <SubDomain
          subDomainTableData={subDomainTableData}
          subDomainFilterData={subDomainFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          loadingTable={loadingTable}
        />
      </div>
    </>
  );
};

export default SubDomainTable;
