import React, { useState, useRef, useEffect } from "react";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { maskedEmailAt } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../../redux-toolkit/redux/auth";
import { message } from "antd";
import OtpInputComponent from "../../../Component/otp/OtpInputComponent";
import { confirmDialog } from "primereact/confirmdialog";
import Auth0 from "../../../services/auth0";

const AuthenticateLoginForm = () => {
  const inputRefs = useRef([]);
  const { state } = useLocation();
  const [verificationCodes, setVerificationCodes] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [isErrorVerificationCode, setIsErrorVerificationCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(2 * 60);
  const [maxLimit, setMaxLimit] = useState(2);

  const loginEmail = localStorage.getItem("userEmail");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleVerificationCodeChange = (index, value) => {
    const newVerificationCodes = [...verificationCodes];
    newVerificationCodes[index] = value;
    setVerificationCodes(newVerificationCodes);
    if (value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (!value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && verificationCodes[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setDisabled(false);
  //     setTimeLeft(0); // Reset time left when button becomes enabled
  //   }, 2 * 60 * 1000);

  //   return () => clearTimeout(timer);
  // }, []); // Runs once on component mount

  // useEffect(() => {
  //   if (disabled) {
  //     const interval = setInterval(() => {
  //       setTimeLeft((prevTimeLeft) => {
  //         if (prevTimeLeft === 0) {
  //           clearInterval(interval);
  //           return 0;
  //         }
  //         return prevTimeLeft - 1;
  //       });
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }
  // }, [disabled]);
  // useEffect(() => {
  //   AuthApi.loginStatusApi({
  //     email: loginEmail,
  //   }).then((data) => {
  //     if (data?.message === "Max request reached") {
  //       toast.warning(data.message);
  //       navigate("/login");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    if (maxLimit === 0) {
      setSeconds(0);
    }
  }, [maxLimit]);

  const handleResendCode = (e) => {
    e.target.setAttribute("disabled", "disabled");

    if (seconds !== 0) {
      e.target.removeAttribute("disabled");
      return;
    }

    if (maxLimit === 0) {
      toast.warning("Max request exceeded. Please try after an hour.");
      e.target.removeAttribute("disabled");
      return;
    }
    Auth0.passwordlessStart(
      {
        connection: "email",
        send: "code",
        email: loginEmail,
      },
      (err, result) => {
        console.log(err, result);
        // setLoading(false);
        if (!err) {
          e.target.removeAttribute("disabled");
          toast.success("Resended the code.");
        } else toast.error(err.errorDescription);
      }
    );
    // AuthApi.resendCode({ email: loginEmail }).then((data) => {
    //   if (data.status === "success") {
    //     toast.success(data.message);
    //     setSeconds(2 * 60);
    //     setMaxLimit((prev) => prev - 1);
    //     e.target.removeAttribute("disabled");
    //   } else {
    //     if (data?.message === "Max request reached") {
    //       toast.warning("Max request exceeded. Please try after an hour.");
    //       setSeconds(0);
    //       setMaxLimit(0);
    //       return;
    //     }
    //     toast.error(data?.message);
    //     e.target.removeAttribute("disabled");
    //   }
    // });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsErrorVerificationCode(false);
    setIsLoading(true);
    Auth0.passwordlessLogin(
      {
        connection: "email",
        email: loginEmail,
        verificationCode: otp,
      },
      (err, result) => {
        if (err) {
          setIsLoading(false);
          setIsErrorVerificationCode(true);
          toast.error(err.errorDescription);
        } else {
          console.log(err, result);
        }
      }
    );
    // AuthApi.otpVerify({
    //   email: loginEmail,
    //   code: otp,
    // }).then((data) => {
    //   if (data.status === "success") {
    //     toast.success(data.message);
    //     localStorage.setItem("token", data.data.access);
    //     dispatch(
    //       setUserDetails({ ...data.data, org_id: data.data.org?.[0]?.id })
    //     );
    //     setTimeout(() => {
    //       setIsErrorVerificationCode(true);
    //       setIsLoading(false);
    //     }, 2000);
    //     setTimeout(() => {
    //       if (data.data.subscription === "expired") {
    //         confirmDialog({
    //           message: "Your current Subscription is expired, please upgrade.",
    //           header: "Subscription Expired",
    //           icon: "pi pi-info-circle",
    //           acceptClassName: "p-button-danger",
    //           acceptLabel: "Ok",
    //           rejectLabel: "Cancel",
    //         });
    //       }
    //     }, 3000);
    //   } else {
    //     setIsLoading(false);
    //     setIsErrorVerificationCode(true);
    //     toast.error(data.message);
    //   }
    // });
  };
  const vpwidth = document.documentElement.clientWidth;
  const inputStyle = {
    fontSize: "1.5rem",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    height: `${vpwidth > 667 ? "3rem" : "2rem"}`,
    width: `${vpwidth > 667 ? "3rem" : "2rem"}`,
    marginRight: "8px",
    lineHeight: "2rem",
  };

  return (
    <div className="flex flex-col items-center py-24 lg:px-10 2xl:px-[115px] px-6 h-screen">
      <div className="w-full label-color">
        <div className="flex flex-col justify-center mx-5">
          <h1 className="text-2xl font-bold text-color text-center pb-4">
            Authenticate your account
          </h1>
          <p className=" pb-2 text-base text-center">
            {state?.type === "app"
              ? "Enter the code from your authenticator app."
              : "Enter the code sent to your email for added security."}
          </p>
          <div className="text-center">
            <h1 className="text-base font-bold text-color ">
              {maskedEmailAt(loginEmail)}
            </h1>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="pb-4 pt-9">
              <label className="label-color text">Verification Code</label>
              <div id="otp" className="flex flex-row">
                <OtpInputComponent
                  inputStyle={inputStyle}
                  otp={otp}
                  setOtp={setOtp}
                  numInputs={6}
                />
                {/* {Array(6)
              .fill()
              .map((_, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`m-2 h-10 w-[2.7rem] py-2 px-4 rounded-md text-lg placeholder-color text-color
                  ${
                    isErrorVerificationCode
                      ? "border-color-error border-error-focus"
                      : "border-color-disable border-focus"
                  }`}
                  type="text"
                  maxLength="1"
                  value={verificationCodes[index]}
                  onChange={(e) =>
                    handleVerificationCodeChange(index, e.target.value)
                  }
                  placeholder="_"
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))} */}
              </div>
              {isLoading && (
                <div className="flex items-center gap-1">
                  <h1 className="link-color">Verification...</h1>
                </div>
              )}
              {isErrorVerificationCode && (
                <h1 className="error text-sm">Invalid Verification Code</h1>
              )}
            </div>
            <div className="flex justify-evenly items-center gap-4">
              <button
                type="submit"
                className="w-full button-bg-color p-3 rounded-[48px] text-white text-base"
              >
                Submit
              </button>
              <button
                type="button"
                className="w-full bg-white border-[2px] border-[#181818] p-3 rounded-[48px] text-color text-base"
                onClick={() => navigate("/login")}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
        {state?.type !== "app" && (
          <div className="pt-6 text-center">
            <button
              className={`text-base border-none ${
                seconds !== 0 || maxLimit === 0
                  ? "text-slate-600"
                  : "link-color"
              } `}
              onClick={(e) => handleResendCode(e)}
              disabled={seconds !== 0}
            >
              Resend a code{" "}
              {seconds === 0
                ? ""
                : `(${seconds < 10 ? `${seconds}` : seconds}s)`}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthenticateLoginForm;
