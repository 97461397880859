import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserDetails } from "../redux-toolkit/redux/auth";

function Root() {
  const token = localStorage.getItem("token");
  const userDetails = useSelector(getUserDetails);

  return (
    <>
      {token ? (
        <div className="w-screen h-screen flex justify-center items-center">
          {!userDetails.first_name |
          (userDetails.first_name === "") |
          !userDetails.last_name |
          (userDetails.first_name === "") ? (
            <Navigate to="/complete-profile" />
          ) : userDetails.compliance ? (
            <Navigate to="/compliance" />
          ) : userDetails.cspm ? (
            <Navigate to="/cloud-security-posture-management" />
          ) : userDetails.asm ? (
            <Navigate to="/attack-surface-management" />
          ) : userDetails.voc ? (
            <Navigate to="/vulnerability-operation-center" />
          ) : userDetails.drp ? (
            <Navigate to="/digital-risk-protection" />
          ) : userDetails.bti ? (
            <Navigate to="/brand-threat-intelligence" />
          ) : (
            <Navigate to="/my-profile" />
          )}
        </div>
      ) : (
        <>
          <Outlet />
        </>
      )}
      {/* indicates where <Home/> should be rendered within <Root> */}
    </>
  );
}

export default Root;
