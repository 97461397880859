import { useContext, useEffect, useRef, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import EditorView from "../editor/EditorView";
import { toast } from "react-toastify";

export default function UseTemplate({ id, setCurrentDetails }) {
  const editorRef = useRef();
  const sideRef = useRef();

  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(null);
  const [data, setData] = useState([]);

  const cleanClose = () => {
    setVisible(false);
    setCurrent(null);
  };

  const getData = () => {
    complianceAPI
      .post("/template-library/filter/")
      .then(({ data }) => setData(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = (e) => {
    e.target.setAttribute("disabled", "disabled");
    complianceAPI
      .post("/use-template-library/", {
        id: id,
        template_id: current.id,
        name: current.title,
        content: editorRef.current.getContent(),
      })
      .then((res) => {
        toast.success(res.message);
        cleanClose();
        // getFilterData();
        setCurrentDetails(res.data);
        e.target.removeAttribute("disabled");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        e.target.removeAttribute("disabled");
      });
  };

  return (
    <>
      <button
        className="text-[#074EE8] text-xs flex items-center gap-1"
        onClick={() => setVisible(true)}
      >
        Use Template
      </button>
      <Sidebar
        visible={visible}
        position="right"
        onHide={cleanClose}
        className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
        ref={sideRef}
      >
        <p className="font-bold text-2xl">Template Library</p>
        <p className="text-sm">
          Choose relevant pre-generated policy templates
        </p>
        {!current ? (
          <div className="grid grid-cols-2 gap-3 m-2">
            {data.map((item) => (
              <div>
                <div
                  className="p-3 border rounded-md grid gap-3 content-between"
                  onClick={() => {
                    setCurrent(item);
                  }}
                >
                  <p className="font-bold">{item.title}</p>
                  <p className="text-xs">{item?.frameworks?.join(",")}</p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="flex justify-between">
              <div className="w-2/3">
                <label className="block text-gray-500 text-sm ">Title</label>
                <input
                  className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                  type="text"
                  placeholder="Title"
                  value={current?.title}
                  required
                  onChange={(e) => {
                    setCurrent({ ...data, title: e.target.value });
                    // setError({ ...error, name: false });
                  }}
                />
              </div>
              <div className="self-end mb-1">
                <button
                  className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
                  onClick={(e) => onSubmit(e)}
                >
                  Add
                </button>
                <button
                  className="p-1 px-5 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px]"
                  // onClick={() => onDelete()}
                  onClick={() => setCurrent(null)}
                >
                  Back
                </button>
              </div>
            </div>
            <EditorView editorRef={editorRef} data={current} />
          </>
        )}
      </Sidebar>
    </>
  );
}
