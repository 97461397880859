import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import DarkWebSecondTable from "../../../home/components/tables/DarkWeb/DarkWebSecondTable";
import TableFilterApiBase from "../../../../Api/FIlterAPI/base";

const DarkWebSecond = (props) => {
  const { isDarkMode } = useContext(ThemeContext);

  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;

  const api = new TableFilterApiBase(
    "ultraheals/v1/web/digital-risk/telegramleaks/filter/"
  );

  const { title } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filterParams, setFilterParams] = useState({ scan });

  const getFilterData = () => {
    api
      .get({ scan })
      .then((res) => {
        setFilterData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    api
      .post({ ...filterParams, scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (Object.keys(filterParams).length > 1) getData();
  }, [filterParams]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({data.length}) </span>
          </p>
        </div>
        <DarkWebSecondTable
          loadingTable={loading}
          data={data}
          filterData={filterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default DarkWebSecond;
