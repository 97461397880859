import React, { useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { useEffect } from "react";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { useSelector } from "react-redux";
import { getUserTableData } from "../../../redux-toolkit/redux/TableData";
import { toast } from "react-toastify";
import { complianceAPI } from "../../../Api/CSPM/api";
import { MODULES } from "./CreateUserForm";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
import { MultiSelect } from "primereact/multiselect";

const UserInformationForm = (props) => {
  const {
    handleCloseSideBar,
    userTableViewData,
    role,
    setVisibleRight,
    getUserInformationTableData,
    orgUsers,
  } = props;
  const userDetails = useSelector(getUserDetails);
  const UserTableData = useSelector(getUserTableData);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleAuth, setSelectedRoleAuth] = useState(null);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyUrl, setCompanyUrl] = useState();
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [modules, setModules] = useState([]);
  const [users, setUser] = useState([]);

  const roleAuth = [
    { name: "Authentication App", code: "AA" },
    { name: "Email Auth", code: "EA" },
    { name: "Mobile Auth", code: "MA" },
  ];

  let roles = [
    { name: "Auditor", value: "Auditor" },
    { name: "Editor", value: "Editor" },
    { name: "Employee", value: "Employee" },
    { name: "Vendor", value: "Vendor" },
  ];

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!email || !selectedRole) {
      toast.error("Please fill all the fields");
      return;
    }
    const payload = {
      id: UserTableData.id,
      email: email,
      first_name: firstName,
      last_name: lastName,
      company: companyName,
      role: selectedRole,
      department: department,
      editor: modules,
      users: users,
    };
    TableApi.getUserTableDataEdit(payload).then((data) => {
      if (data.status === "success") {
        // message.success(data.message)
        toast.success(data.message);
        setVisibleRight(false);
        getUserInformationTableData();
      } else {
        toast.error(data.message);
      }
    });
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartments(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDepartment();
  }, []);

  useEffect(() => {
    if (UserTableData !== null) {
      setFirstName(UserTableData.first_name);
      setLastName(UserTableData.last_name);
      setEmail(UserTableData.email);
      setCompanyName(UserTableData.company);
      // const result = role.filter((data) => data.id === UserTableData.role);
      // setSelectedRole(result[0]);
      setSelectedRole(UserTableData.role);
      console.log(departments.find((i) => i.name === UserTableData.department)); // DON'T remove
      setDepartment(
        departments.find((i) => i.name === UserTableData.department)?.id
      );
      setModules(UserTableData.modules);
      setUser(UserTableData.user);
    }
  }, [UserTableData, role]);

  const handleSubmodule = (item) => {
    if (modules?.includes(item)) {
      setModules(modules.filter((i) => i !== item));
    } else {
      setModules([...modules, item]);
    }
  };

  const handleModule = (item) => {
    if (item.submodules.every((i) => modules.includes(i))) {
      setModules(modules.filter((i) => !item.submodules.includes(i)));
    } else {
      setModules([
        ...modules,
        ...item.submodules.filter((i) => !modules.includes(i)),
      ]);
    }
  };

  return (
    <>
      <form className="py-5 flex flex-col w-full login-style">
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">First Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="First Name"
              disabled={userTableViewData ? true : false}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Last Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Last Name"
              disabled={userTableViewData ? true : false}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Email</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="email"
              placeholder="john@gmail.com"
              disabled={true}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Company</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Company Name"
              disabled={userTableViewData ? true : false}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Company URL</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="http://www.sample.com"
              disabled={userTableViewData ? true : false}
              value={companyUrl}
              onChange={(e) => setCompanyUrl(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2 focus:ring-[1px] focus:border focus:ring-[#686868]">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Role</label>
            <Dropdown
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.value)}
              options={roles}
              optionLabel="name"
              placeholder="Select a Role"
              className=" w-full md:w-14rem h-[40px] my-1"
              disabled={userTableViewData ? true : false}
            />
          </div>
        </div>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Department</label>
          <Dropdown
            filter
            value={department}
            onChange={(e) => setDepartment(e.value)}
            options={departments}
            optionLabel="name"
            optionValue="id"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
            disabled={userTableViewData ? true : false}
          />
        </div>
        {/* <div className="flex flex-wrap -mx-3 mb-2 focus:ring-[1px] focus:border focus:ring-[#686868]">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">
              Multifactor Authentication
            </label>
            <Dropdown
              value={selectedRoleAuth}
              onChange={(e) => setSelectedRoleAuth(e.value)}
              options={roleAuth}
              optionLabel="name"
              placeholder="Select a Role"
              className=" w-full md:w-14rem h-[40px] my-1"
              disabled={userTableViewData ? true : false}
            />
          </div>
        </div> */}
        {userDetails.role === "Super Admin" && selectedRole === "Auditor" && (
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3">
              <label className="block text-gray-500 text-sm ">
                Organisation
              </label>
              <MultiSelect
                className="w-full"
                optionLabel="name"
                optionValue="id"
                filter
                value={users}
                options={orgUsers}
                placeholder="Select Users"
                onChange={(e) => setUser(e.value)}
              />
            </div>
          </div>
        )}
        {selectedRole === "Editor" && (
          <>
            <p className="font-bold text-[16px] text-[#111]">Module</p>

            {MODULES.map((module) => {
              if (
                module.name === "Attack Surface Management" &&
                !userDetails.asm
              )
                return null;
              else if (
                module.name === "Digital Risk Protection" &&
                !userDetails.drp
              )
                return null;
              else if (
                module.name === "Brand Threat Intelligence" &&
                !userDetails.bti
              )
                return null;
              else if (
                module.name === "Cloud Security Posture Management" &&
                !userDetails.cspm
              )
                return null;
              else if (module.name === "Compliance" && !userDetails.compliance)
                return null;

              return (
                <>
                  <div
                    className="flex items-center gap-2 pt-2"
                    onClick={() => handleModule(module)}
                  >
                    <input
                      id={module.name}
                      type="radio"
                      checked={
                        module.submodules.every((i) => modules.includes(i)) ||
                        false
                      }
                      disabled={userTableViewData ? true : false}
                      className="h-5 w-5 bg-red-500"
                    />
                    <label
                      className={`text-base font-normal ${
                        true ? "text-color" : "text-inactive"
                      }`}
                      htmlFor={module.name}
                    >
                      {module.name}
                    </label>
                  </div>
                  {module.submodules?.map((item) => {
                    return (
                      <div
                        key={item}
                        className="flex items-center gap-2 py-1 ml-[28px]"
                        onClick={() => handleSubmodule(item)}
                      >
                        <input
                          type="checkbox"
                          className="h-4 w-4"
                          checked={modules?.includes(item) || false}
                          disabled={userTableViewData ? true : false}
                        />
                        <label
                          className={`text-base font-normal ${
                            true ? "text-color" : "text-inactive"
                          }`}
                        >
                          {item}
                        </label>
                      </div>
                    );
                  })}
                </>
              );
            })}
          </>
        )}
      </form>
      {userTableViewData ? (
        <div className="flex w-full">
          <button
            onClick={handleCloseSideBar}
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
          >
            Back
          </button>
          {/* <button className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full ">
            Delete
          </button> */}
        </div>
      ) : (
        <div className="flex w-full">
          <button
            onClick={(e) => handleUpdate(e)}
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
          >
            Update
          </button>
          <button
            onClick={handleCloseSideBar}
            className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
          >
            Back
          </button>
        </div>
      )}
    </>
  );
};

export default UserInformationForm;
