import { useNavigate } from "react-router-dom";
import GeoChart from "../../common/Charts/GeoChart";
import ResourceMapping from "./ResourceMapping";

const LAT_LONG = {
  "us-east-1": { lat: 37.0902, long: -95.7129, title: "US East (N. Virginia)" },
  "us-east-2": { lat: 37.0902, long: -95.7129, title: "US East (Ohio)" },
  "us-west-1": {
    lat: 37.7749,
    long: -122.4194,
    title: "US West (N. California)",
  },
  "us-west-2": { lat: 37.7749, long: -122.4194, title: "US West (Oregon)" },
  "ap-south-1": { lat: 20.5937, long: 78.9629, title: "Asia Pacific (Mumbai)" },
  "ap-northeast-1": {
    lat: 35.6895,
    long: 139.6917,
    title: "Asia Pacific (Tokyo)",
  },
  "ap-northeast-2": {
    lat: 35.6895,
    long: 139.6917,
    title: "Asia Pacific (Seoul)",
  },
  "ap-southeast-1": {
    lat: 1.3521,
    long: 103.8198,
    title: "Asia Pacific (Singapore)",
  },
  "ap-southeast-2": {
    lat: 1.3521,
    long: 103.8198,
    title: "Asia Pacific (Sydney)",
  },
  "ap-northeast-3": {
    lat: 35.6895,
    long: 139.6917,
    title: "Asia Pacific (Osaka)",
  },
  "eu-central-1": { lat: 50.8503, long: 4.3517, title: "Europe (Frankfurt)" },
  "eu-west-1": { lat: 51.5074, long: -0.1278, title: "Europe (Ireland)" },
  "eu-west-2": { lat: 51.5074, long: -0.1278, title: "Europe (London)" },
  "eu-west-3": { lat: 51.5074, long: -0.1278, title: "Europe (Paris)" },
  "eu-north-1": { lat: 59.3293, long: 18.0686, title: "Europe (Stockholm)" },
  "sa-east-1": {
    lat: -23.5505,
    long: -46.6333,
    title: "South America (Sao Paulo)",
  },
  "ca-central-1": { lat: 45.4215, long: -75.6972, title: "Canada (Central)" },
  "cn-north-1": { lat: 39.9042, long: 116.4074, title: "China (Beijing)" },
  "cn-northwest-1": { lat: 39.9042, long: 116.4074, title: "China (Ningxia)" },
  "me-south-1": {
    lat: 25.276987,
    long: 55.296249,
    title: "Middle East (Bahrain)",
  },
  "af-south-1": { lat: -30.5595, long: 22.9375, title: "Africa (Cape Town)" },
  "eu-south-1": { lat: 41.9028, long: 12.4964, title: "Europe (Milan)" },
  "ap-east-1": {
    lat: 22.3193,
    long: 114.1694,
    title: "Asia Pacific (Hong Kong)",
  },
};

export default function Maps({ isDarkMode, data }) {
  const navigate = useNavigate();

  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-1 gap-6 mb-4 py-2">
      <div className="pb-2">
        <h1 className="text-2xl font-bold py-2">Findings by Region</h1>
        <div
          className={`${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl h-[350px] p-2 flex flex-row`}
        >
          <GeoChart
            width="80%"
            data={Object.keys(data?.regions || {}).map((key) => {
              return {
                title: `${LAT_LONG[key]?.title} (${
                  data.regions[key]["success"]
                }/${
                  data.regions[key]["failure"] +
                  data.regions[key]["success"] +
                  data.regions[key]["unknown"]
                })`,
                latitude: LAT_LONG[key]?.lat,
                longitude: LAT_LONG[key]?.long,
              };
            })}
          />
          <div className="p-5" style={{ width: "30%", height: "320px" }}>
            <div className="flex justify-between pb-2">
              <p className="font-bold text-[12px]">Region</p>
              <p className="font-bold text-[12px]">Findings</p>
            </div>
            {Object.keys(data?.regions || {})
              .sort((a, b) => {
                return (
                  data.regions[b]["failure"] +
                  data.regions[b]["success"] +
                  (data.regions[b]["unknown"] | 0) -
                  (data.regions[a]["failure"] +
                    data.regions[a]["success"] +
                    (data.regions[a]["unknown"] | 0))
                );
              })
              .slice(0, 10)
              .map((key) => (
                <div
                  className="flex justify-between pb-2 cursor-pointer"
                  onClick={() =>
                    navigate(`./${key}`, {
                      state: {
                        title: LAT_LONG[key]?.title,
                        filter: { resource__region: key },
                      },
                    })
                  }
                >
                  <p className="text-[14px] truncate mr-1">
                    {LAT_LONG[key]?.title}
                  </p>
                  <p className="text-[14px]">
                    {data.regions[key]["success"]}/
                    {(data.regions[key]["failure"] | 0) +
                      (data.regions[key]["success"] | 0) +
                      (data.regions[key]["unknown"] | 0)}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div>
        <h1 className="text-2xl font-bold py-2">Resource mapping</h1>
        <div
          className={`${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl h-[350px] p-2 cursor-pointer`}
        >
          <ResourceMapping />
        </div>
      </div>
    </div>
  );
}
