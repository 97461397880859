import { useEffect, useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { cspmAPI } from "../../../../../Api/CSPM/api";
import { toast } from "react-toastify";

export default function CloudIntegrateSideBar({
  handleCloseSideBar,
  editData,
  getTableFilterData,
}) {
  const [data, setData] = useState({});
  const providers = [
    {
      id: "aws",
      name: "Amazon Web Services",
      regions: [
        // { id: "all", name: "All Regions" },
        { id: "us-east-1", name: "US East (N. Virginia)" },
        { id: "us-west-1", name: "US West (N. California)" },
        { id: "us-west-2", name: "US West (Oregon)" },
        { id: "ap-south-1", name: "Asia Pacific (Mumbai)" },
        { id: "ap-northeast-1", name: "Asia Pacific (Tokyo)" },
        { id: "ap-northeast-2", name: "Asia Pacific (Seoul)" },
        { id: "ap-southeast-1", name: "Asia Pacific (Singapore)" },
        { id: "ap-southeast-2", name: "Asia Pacific (Sydney)" },
        { id: "ca-central-1", name: "Canada (Central)" },
        { id: "eu-central-1", name: "EU (Frankfurt)" },
        { id: "eu-west-1", name: "EU (Ireland)" },
        { id: "eu-west-2", name: "EU (London)" },
        { id: "eu-west-3", name: "EU (Paris)" },
        { id: "sa-east-1", name: "South America (São Paulo)" },
      ],
    },
    {
      id: "azure",
      name: "Microsoft Azure",
    },
    {
      id: "gcp",
      name: "Google Cloud Platform",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!editData) {
      cspmAPI
        .post("/cloud-integration/", data)
        .then((res) => {
          if (res.status === "success") {
            // open url in new tab
            // not for aws only
            window.open(res.data.url, "_blank");
            toast.success(
              "Once your installation is complete, refresh the page to see the changes"
            );
            getTableFilterData();
            handleCloseSideBar();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      cspmAPI
        .patch(`/cloud-integration/${editData.id}`, data)
        .then((res) => {
          if (res.status === "success") {
            // open url in new tab
            // not for aws only
            toast.success("Cloud data updated successfully");
            getTableFilterData();
            handleCloseSideBar();
          } else {
            toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    console.log(editData);
    if (editData) setData(editData);
  }, []);

  return (
    <div>
      <div className="w-full py-3">
        {!editData && (
          <>
            <label className="block text-gray-500 text-sm">Provider</label>
            <Dropdown
              multiple
              value={data.provider}
              onChange={(e) => setData({ ...data, provider: e.value })}
              options={providers}
              optionValue="id"
              optionLabel="name"
              placeholder="Select Provider"
              className="w-full md:w-14rem"
            />
          </>
        )}
        {data?.provider === "aws" && (
          <>
            <div className="mt-3">
              <label className="block text-gray-500 text-sm">Region</label>
              <MultiSelect
                className="w-full"
                optionLabel="name"
                optionValue="id"
                filter
                value={
                  data?.regions?.length === 1 && data?.regions == ["all"]
                    ? null
                    : data?.regions?.filter((i) => i !== "all")
                }
                // value={data.region}
                options={providers[0].regions}
                placeholder="Select region"
                onChange={(e) => setData({ ...data, regions: e.value })}
              />
            </div>
            <div className="mt-3 flex w-full">
              <button
                onClick={(e) => handleSubmit(e)}
                className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
                type="submit"
              >
                {editData ? "Save" : "Integrate with Cloud Formation"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
