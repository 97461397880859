import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { projectsTableData } from '../../../../constants';
import { Toolbar } from "primereact/toolbar";
import { DownloadIcon, SearchIcon } from "../../../../assets/svg";
import { BsDot } from "react-icons/bs";
import { FiMoreVertical, FiPlay } from "react-icons/fi";
import { SidebarSlide } from "../../../../Component/Header/SidebarSlide";
import TableFilter from "../../../../common/Tables/TableFilter";
import { useSelector, useDispatch } from "react-redux";
import { getProjectTable } from "../../../../redux-toolkit/redux/TableData";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import TableApi from "../../../../Api/AuthenticationApi/TableApi";
import { toast } from "react-toastify";
import { AiOutlineCalendar, AiOutlineClose } from "react-icons/ai";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { ProgressBar } from "primereact/progressbar";
import { ScheduleCalender, Start, Close } from "../../../../assets/svg";
import { setScanViewData } from "../../../../redux-toolkit/redux/Sidebar";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { DateTimeToDate } from "../../../../helpers";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { isAdminOrSuperAdmin } from "../../../../utils/accessCheck";
import paginationTemplate from "../../../../utils/paginationTemplate";
import ViewPtaasScan from "../ptaas/viewPtaasScan";
import { useSearchParams } from "react-router-dom";
import GenerateCertificate from "../ptaas/generateCertificate";
import handleDownload from "../../../../utils/fileDownload";

const MyProjectsTable = ({
  isDarkMode,
  projectsTableData,
  loadingProjectTable,
  btnText,
  btnName,
  getProjectTableData,
  FilterData,
  setFilterParams,
  filterParams,
}) => {
  const dt = useRef(null);
  const dispatch = useDispatch();
  // const projectsTableData = useSelector(getProjectTable)
  const userInfo = useSelector(getUserDetails);
  const [projects, setProjects] = useState(projectsTableData);
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedProjects, setSelectedProjects] = useState(null);
  const [visibleTargetRight, setVisibleTargetRight] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [projectsFilterData, setProjectsFilterData] = useState([]);
  const [openScanningSchedule, setOpenScanningSchedule] = useState(false);
  const [visibleScaningView, setVisibleScaningView] = useState(false);
  const [target, setTarget] = useState(null);
  const [ruleOfEngagement, setRuleOfEngagement] = useState(null);
  const [scanId, setScanId] = useState(null);

  // useEffect(() => {
  //   setProjects(projectsTableData);
  //   TableApi.myProjectsFilter().then((data) => {
  //     setProjectsFilterData(data.data);
  //   });
  // }, [projectsTableData]);

  const columns = [
    { field: "domain", header: "Domain", value: FilterData?.domain },
    { field: "email", header: "Email", value: FilterData?.email },
    { field: "status", header: "Status", value: FilterData?.status },
    {
      field: "scan_started",
      header: "Scan Started",
      value: FilterData?.scan_started,
    },
    {
      field: "scan_ended",
      header: "Scan Ended",
      value: FilterData?.scan_ended,
    },
    // { field: "scan_progress", header: "Scanning" , value: FilterData?.scan_progress},
    // { field: "Action", header: "action" },
  ];

  const accept = (data) => {
    TableApi.deleteTarget(data.target_id).then((item) => {
      if (item.status === "success") {
        getProjectTableData();
        toast.success(item.message);
      }
    });
  };

  const items = (data) => {
    let _items = [
      {
        label: "View",
        icon: "pi pi-refresh",
        command: (e) => {
          TableApi.getViewScanDataApi(data.target_id).then((data) => {
            if (data.status === "success") {
              dispatch(setScanViewData(data.data));
              setVisibleScaningView(true);
            } else {
              toast.error(data.message);
            }
          });
        },
      },
    ];
    if (isAdminOrSuperAdmin(userInfo?.role)) {
      _items = [
        ..._items,
        {
          label: "Pentest History",
          icon: "pi pi-times",
          command: (e) => {
            setRuleOfEngagement(data?.target_id);
          },
        },
        {
          label: "Assign",
          icon: "pi pi-times",
          command: (e) => {
            console.log("Assign Agent", data);
            setTarget(data.target_id);
          },
        },
        {
          label: data.ptaas_certificate
            ? "Download Certificate"
            : "Generate Certificate",
          icon: "pi pi-search",
          command: () => {
            if (data.ptaas_certificate) {
              handleDownload(data.ptaas_certificate);
              toast.success("Certificate downloading...");
            } else {
              setScanId(data.target_id);
            }
          },
        },
        {
          label: "Delete",
          icon: "pi pi-times",
          command: (e) => {
            confirmDialog({
              message: "Do you want to delete this record?",
              header: "Delete Confirmation",
              icon: "pi pi-info-circle",
              acceptClassName: "p-button-danger",
              accept: () => accept(data),
              // reject
            });
          },
        },
      ];
    }
    return _items;
  };

  const splitBtn = (e, d) => {
    console.log("dtaa", d, e); /* Getting data here*/
  };

  const actionBodyTemplate = (data) => {
    return (
      <>
        <SplitButton
          icon={<FiMoreVertical size={24} />}
          className="p-button-text outline-none split-style"
          model={items(data)}
          onClick={(e) => splitBtn(e, data)}
        ></SplitButton>
      </>
    );
  };

  const handleInitiateScan = (e, rowData) => {
    e.preventDefault();
    if (rowData.target_type === "network") {
      const payload = {
        target: rowData.target_id,
        scan_type: "full",
        scan_darkweb_keywords: "",
        scan_twitter_keywords: "",
        scan_github_keywords: "",
        scan_cloud_keywords: "",
      };
      TableApi.initiateScanWeb(payload).then((data) => {
        if (data.status === "success") {
          getProjectTableData();
          setTimeout(() => {
            toast.success(data.message);

            getStartScan(data.scan_id);
          }, 1000);
        }
      });
    }
    if (rowData.target_type === "web") {
      localStorage.setItem("targetID", rowData.target_id);
      setVisibleRight(true);
    }
  };

  const handleScheduleScan = (e, rowData) => {
    console.log("handle", e, rowData);
    e.preventDefault();

    if (rowData.target_type === "web") {
      localStorage.setItem("targetID", rowData.target_id);
      setOpenScanningSchedule(true);
    }
  };

  const getStartScan = (startScanId) => {
    const payload = {
      scan_id: startScanId,
    };
    TableApi.startScanWeb(payload).then((data) => {
      if (data.status === "success") {
        setTimeout(() => {
          toast.success(data.message);
        }, 1000);
      } else {
        setTimeout(() => {
          toast.error(data.message);
        }, 1000);
      }
    });
  };
  const acceptClose = (rowData) => {
    const payload = {
      target_id: rowData.target_id,
      domain: rowData.domain,
    };
    TableApi.cancelScanApi(payload).then((data) => {
      if (data.status === "success") {
        getProjectTableData();
        setTimeout(() => {
          toast.success(data.message);
        }, 1000);
      }
    });
    // TableApi.deleteTarget(data.target_id).then((item)=>{
    //   if(item.status === "success"){
    //     getProjectTableData()
    //     toast.success(item.message)
    //   }
    // })
  };
  const handleClose = (e, rowData) => {
    e.preventDefault();
    confirmDialog({
      message: "Do you want to Cancel this Scan?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => acceptClose(rowData),
      // reject : ()=>
    });
  };
  return (
    <div
      className={`rounded-2xl userTable-style  ${
        isDarkMode
          ? "header-dark  text-white"
          : "header-light white-background text-color"
      }`}
    >
      <ConfirmDialog />
      <TableFilter
        dt={dt}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        isDarkMode={isDarkMode}
        btnText
        btnName={btnName}
        setVisibleRight={setVisibleTargetRight}
        columns={columns}
        tableData={projectsTableData}
        tableHeader={columns}
        setFilterParams={setFilterParams}
        filterParams={filterParams}
      />
      {loadingProjectTable ? (
        <SkeletonDataTable columns={columns} />
      ) : (
        <DataTable
          removableSort
          selection={selectedProjects}
          onSelectionChange={(e) => setSelectedProjects(e.value)}
          ref={dt}
          value={projectsTableData}
          paginator
          rows={10}
          paginatorTemplate={paginationTemplate()}
          // rowsPerPageOptions={[5, 10, 25]}
          // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          // currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilter={globalFilter}
          className={`${
            isDarkMode ? "dark-mode text-white" : "text-color"
          } text-xs`}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column field="domain" header="Domain" sortable></Column>
          <Column field="email" header="Email" sortable></Column>
          <Column
            field="status"
            header="Status"
            body={(rowData) => {
              if (rowData.status === "Completed") {
                return (
                  <div className={`activeStatusClass flex gap-1 items-center`}>
                    <BsDot size={24} />
                    {rowData.status}
                  </div>
                );
              } else if (rowData.status === "In Progress") {
                return (
                  <>
                    <div
                      className={`progressStatusClass flex gap-1 items-center`}
                    >
                      <BsDot size={24} />
                      {rowData.status}{" "}
                      {rowData.scan_progress > "0"
                        ? parseInt(rowData.scan_progress)
                        : 0}
                      %
                    </div>
                    <div className=" pl-[30px] progressBarStyle">
                      <ProgressBar value={Number(rowData.scan_progress)} />
                    </div>
                  </>
                );
              } else if (rowData.status === "Not Scanned") {
                return (
                  <div
                    className={`notscannedStatusClass flex gap-1 items-center`}
                  >
                    <BsDot size={24} />
                    {rowData.status}
                  </div>
                );
              } else if (rowData.status === "Error") {
                return (
                  <div className={`errorStatusClass flex gap-1 items-center`}>
                    <BsDot size={24} />
                    {rowData.status}
                  </div>
                );
              } else if (rowData.status === "Cancelled") {
                return (
                  <div className={`errorStatusClass flex gap-1 items-center`}>
                    <BsDot size={24} />
                    {rowData.status}
                  </div>
                );
              }
            }}
            sortable
          ></Column>
          <Column field="scan_started" header="Scan Started" sortable></Column>
          <Column field="scan_ended" header="Scan Ended" sortable></Column>
          {/* <Column
            field="scan_progress"
            header="Scanning"
            body={(rowData) => {
              const statusClass =
                rowData.status === "In Progress"
                  ? "progressButton"
                  : "completedButton";
              const statusDarkClass =
                rowData.status === "In Progress"
                  ? "completedButton"
                  : "progressButton";
                  const subDomainStatus = rowData.subdomain_status === "0" ? "Please wait" : " Initiate Scanning"
              const disable = rowData.status === "In Progress" ? true : false;
              return (
                <div>
                  <button
                    disabled={disable}
                    onClick={(e) =>
                      rowData.status === "In Progress"
                        ? ""
                        : handleInitiateScan(e, rowData)
                    }
                    className={`${
                      isDarkMode ? statusDarkClass : statusClass
                    } px-3 py-1 rounded-2xl font-semibold text-xs`}
                  >
                    {subDomainStatus}
                  </button>
                </div>
              );
            }}
            sortable
          ></Column> */}
          <Column
            field="scan_progress"
            header="Scanning"
            body={(rowData) => {
              const subDomainStatus =
                rowData.subdomain_status === "0"
                  ? "Please wait"
                  : " Initiate Scanning";
              return (
                <div>
                  {rowData.status === "In Progress" ? (
                    <button onClick={(e) => handleClose(e, rowData)}>
                      {/* <AiOutlineClose color="#CD1F43" size={18} /> */}
                      <img src={Close} />
                    </button>
                  ) : (
                    <div className="flex gap-3">
                      {rowData.subdomain_status === "0" ? (
                        "please wait"
                      ) : (
                        <>
                          <button
                            type="button"
                            onClick={(e) => {
                              if (userInfo.subscription === "active") {
                                handleInitiateScan(e, rowData);
                              } else {
                                // toast.error(
                                //   "You have no subscription to Initiate a scan..!"
                                // );
                                confirmDialog({
                                  message:
                                    "Your current Subscription is expired, please upgrade.",
                                  header: "Subscription Expired",
                                  icon: "pi pi-info-circle",
                                  acceptClassName: "p-button-danger",
                                  acceptLabel: "Ok",
                                  rejectLabel: "Cancel",
                                });
                              }
                            }}
                          >
                            {/* <FiPlay color="#074EE8" size={18} /> */}
                            <img src={Start} />
                          </button>
                          <button
                            onClick={(e) => {
                              if (userInfo.subscription === "active") {
                                handleScheduleScan(e, rowData);
                              } else {
                                // toast.error("You have no subscription to schedule a scan..!")
                                confirmDialog({
                                  message:
                                    "Your current Subscription is expired, please upgrade.",
                                  header: "Subscription Expired",
                                  icon: "pi pi-info-circle",
                                  acceptClassName: "p-button-danger",
                                  acceptLabel: "Ok",
                                  rejectLabel: "Cancel",
                                });
                              }
                            }}
                          >
                            {/* <AiOutlineCalendar color="#074EE8" size={18}  /> */}
                            <img src={ScheduleCalender} />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            }}
            sortable
          ></Column>
          <Column
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
          />
        </DataTable>
      )}
      <div>
        <SidebarSlide
          title="Initiate Scan"
          description="Initiate a scan to assess your system's security and vulnerability status"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
          getProjectTableData={getProjectTableData}
        />
      </div>

      <div>
        <SidebarSlide
          title="Add Target"
          description="Add a target to scan the domain"
          visibleRight={visibleTargetRight}
          setVisibleRight={setVisibleTargetRight}
          getProjectTableData={getProjectTableData}
        />
      </div>

      <div>
        <SidebarSlide
          title="Assign Agent"
          description="Assign an agent to the target"
          visibleRight={target}
          setVisibleRight={setTarget}
          // getProjectTableData={getProjectTableData}
        />
      </div>

      <div>
        <SidebarSlide
          title="Schedule Scanning"
          description="Automate your assessment by scheduling your scan"
          visibleRight={openScanningSchedule}
          setVisibleRight={setOpenScanningSchedule}
          getProjectTableData={getProjectTableData}
        />
      </div>

      <div>
        <SidebarSlide
          title="Target Initiate Scanning"
          description="Scan Configuration Settings"
          visibleRight={visibleScaningView}
          setVisibleRight={setVisibleScaningView}
        />
      </div>
      <ViewPtaasScan
        ruleOfEngagement={ruleOfEngagement}
        setRuleOfEngagement={setRuleOfEngagement}
      />
      <GenerateCertificate scanId={scanId} setScanId={setScanId} />
    </div>
  );
};

export default MyProjectsTable;
