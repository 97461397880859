import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import AddPolicy, {
  recurrence,
} from "../../modules/home/components/compliance/policy/AddPolicy";
import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import { complianceAPI } from "../../Api/CSPM/api";
import ViewPolicy from "../../modules/home/components/compliance/policy/ViewPolicy";
import { useSearchParams } from "react-router-dom";
// import UseTemplate from "../../modules/home/components/compliance/policy/UseTemplate";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";

export default function Policy() {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  let filters = {};
  const framework = useSelector(getUserDetails)?.framework;
  if (framework) filters["frameworks"] = framework;

  const [employee, setEmployee] = useState([]);
  const [department, setDepartment] = useState([]);
  const [entities, setEntities] = useState([]);
  const [frameworks, setFrameworks] = useState([]);

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "frameworks",
      header: "Frameworks",
      type: "multi_select",
      value: [
        ...frameworks.map((i) => ({
          label: i.name,
          value: i.id,
        })),
        { label: "No frameworks", value: null },
      ],
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Not Uploaded",
          value: "not_uploaded",
        },
        {
          label: "Draft",
          value: "draft",
        },
        {
          label: "Approved",
          value: "approved",
        },
        {
          label: "Published",
          value: "published",
        },
        {
          label: "Need Review",
          value: "need_review",
        },
      ],
    },
    {
      field: "entities",
      header: "Entities",
      type: "select",
      value: entities.map((e) => ({ label: e.name, value: e.id })),
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "recurrence",
      header: "Recurrence",
      type: "select",
      value: recurrence,
    },
    {
      field: "review_date",
      header: "Review Date",
      type: "date",
    },
    {
      field: "approver",
      header: "Approver",
      type: "select",
      value: employee.map((e) => ({
        label: e.email,
        value: e.id,
      })),
    },
    {
      field: "department",
      header: "Department",
      type: "select",
      value: department.map((e) => ({ label: e.name, value: e.id })),
    },
    {
      field: "source",
      header: "Source",
    },
    {
      field: "out_of_scope",
      header: "Scope",
      type: "select",
      value: [
        { label: "In Scope", value: false },
        { label: "Out of Scope", value: true },
      ],
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFrameworks = () => {
    complianceAPI
      .post("/framework/filter/")
      .then(({ data }) => {
        setFrameworks(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
    getDepartment();
    getEntities();
    getFrameworks();
  }, []);

  return (
    <ComplianceTable
      title="Policy"
      columns={columns}
      url_path="/policy/"
      AddComponent={AddPolicy}
      ViewComponent={ViewPolicy}
      onRowClick="view"
      defaultOpen={id}
      // IntegrationComponent={UseTemplate}
      filter={filters}
      defaultClose={() => {
        if (id) {
          searchParams.delete("id");
          setSearchParams(searchParams);
          window.history.replaceState({}, "", window.location.pathname);
        }
      }}
    />
  );
}
