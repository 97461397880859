import React from "react";
import SkeletonBasic from "../../../SkeletonAnimation/SkeletonPlanCard";
import SubscriptionTable from "../tables/SubscriptionTable";

import SubscriptionPlanCards from "./SubscriptionPlanCards";

const SubscriptionPlan = (props) => {
  const {
    isDarkMode,
    title,
    description,
    setPlanning,
    planPricing,
    loadingPlanCardData,
  } = props;
  return (
    <>
      <SubscriptionPlanCards
        isDarkMode={isDarkMode}
        setPlanning={setPlanning}
        planPricing={planPricing}
        loadingPlanCardData={loadingPlanCardData}
      />

      <div
        className={`p-8 rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "white-background  text-color"
        }`}
      >
        <SubscriptionTable isDarkMode={isDarkMode} />
      </div>
    </>
  );
};

export default SubscriptionPlan;
