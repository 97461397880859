import React, { useEffect, useRef, useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { Toast } from "primereact/toast";

const MultifactorAuthentication = (props) => {
  const {
    setVisibleRight,
    authInstruction,
    setAuthInstruction,
    setEmailVerify,
    setMobileVerify,
  } = props;
  const toast = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(
    "Select a verification method"
  );
  const [selectAuthType, setSelectAuthType] = useState(null);

  const dropdownRef = useRef(null);

  const handleItemClick = (item, value) => {
    console.log("item", item);
    setSelectAuthType(value);
    setSelectedRole(item);
    setIsOpen(false);
  };

  const mfaVerify = async () => {
    const userEmail = localStorage.getItem("userEmail");
    const payload = { email: userEmail, method: selectAuthType, status: "1" };
    await TableApi.enableMfaVerify(payload).then((data) => {
      if (data.status === "success") {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.message,
          life: 3000,
        });
        return "true";
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.message,
          life: 3000,
        });
        return "false";
      }
    });
  };

  const handleSubmit = async () => {
    const userEmail = localStorage.getItem("userEmail");
    const payload = { email: userEmail, method: selectAuthType, status: "1" };
    if (selectedRole === "Authentication App") {
      await TableApi.enableMfaVerify(payload).then((data) => {
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: data.message,
            life: 3000,
          });
          setAuthInstruction(data.data);
          setVisibleRight(true);
          setEmailVerify(false);
          setMobileVerify(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    } else if (selectedRole === "Email") {
      await TableApi.enableMfaVerify(payload).then((data) => {
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: data.message,
            life: 3000,
          });
          setAuthInstruction(true);
          setEmailVerify(true);
          setMobileVerify(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    } else if (selectedRole === "Mobile") {
      await TableApi.enableMfaVerify(payload).then((data) => {
        if (data.status === "success") {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: data.message,
            life: 3000,
          });
          setAuthInstruction(true);
          setMobileVerify(true);
          setEmailVerify(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: data.message,
            life: 3000,
          });
        }
      });
    }
    // TableApi.enableMfaVerify({email:userEmail,method:selectAuthType, status:"1"}).then((data)=>{
    //   console.log("selectedRole",data);
    // })
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <div className="pt-5 pb-1">
        <h1 className="text-base font-semibold text-color">
          Choose your verification method
        </h1>
      </div>

      <div className="relative w-full inline-block" ref={dropdownRef}>
        <button
          className="bg-transparent text-color w-full rounded-[14px] px-4 border-[1px] border-[#AAAAAA] py-2 cursor-pointer flex justify-between items-center text-base text-color"
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedRole && selectedRole}
          <div className="ml-2">
            {isOpen ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
          </div>
        </button>
        <div
          className={`${
            isOpen ? "block" : "hidden"
          } absolute bg-white w-full rounded-[14px] text-color shadow-md z-10 mt-1`}
        >
          <div
            className={`${
              selectedRole === "Select a verification method"
                ? "bg-gray-200"
                : ""
            } py-2 px-4 cursor-pointer rounded-t-[14px] hover:bg-gray-200 text-base`}
            onClick={() =>
              handleItemClick("Select a verification method", null)
            }
          >
            Select a verification method
          </div>
          <div
            className={`${
              selectedRole === "Authentication App" ? "bg-gray-200" : ""
            } py-2 px-4 cursor-pointer hover:bg-gray-200 text-base`}
            onClick={() => handleItemClick("Authentication App", "app")}
          >
            Authentication App
          </div>
          <div
            className={`${
              selectedRole === "Email Auth" ? "bg-gray-200" : ""
            } py-2 px-4 cursor-pointer hover:bg-gray-200 text-base`}
            onClick={() => handleItemClick("Email", "email")}
          >
            Email
          </div>
          {/* <div
            className={`${
              selectedRole === "Mobile Auth" ? "bg-gray-200" : ""
            } py-2 px-4 rounded-b-[14px] cursor-pointer hover:bg-gray-200 text-base`}
            onClick={() => handleItemClick("Mobile Auth", "phone")}
          >
            Mobile Auth
          </div> */}
        </div>
      </div>

      <div className="flex w-full py-9">
        <button
          type="button"
          className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
          onClick={handleSubmit}
        >
          Continue
        </button>
        <button
          className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
          onClick={() => setVisibleRight(false)}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default MultifactorAuthentication;
