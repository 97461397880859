import React, { useState, useContext, useEffect } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import DNSMonitorTable from "../../../home/components/tables/AttackSurface/DNSMonitorTable";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import ComplianceFirstTable from "../../../home/components/tables/AttackSurface/ComplianceFirstTable";
import { ComplianceFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";

const ComplianceFirst = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const { title } = props;
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);
  const [dnsTableData, setDNSTableData] = useState([]);
  const [FilterData, setFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });

  const getDNSDataTable = () => {
    setLoadingTable(true);
    // DashboardApi.getComplianceTableApi(id, "iso").then((data)=>{
    // if(data.status === "success"){
    //   setDNSTableData(data.data)
    //   setCountData(data.count)
    //   setLoadingTable(false)
    // }
    // })
    ComplianceFilterAPI.post({ ...filterParams, complaince: "ISO 27001:2022" })
      .then((res) => {
        setDNSTableData(
          res.data.sort((a, b) => parseInt(a.severity) > parseInt(b.severity))
        );
        setCountData(res.data_len);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    ComplianceFilterAPI.get({ scanID: scanID, complaince: "ISO 27001:2022" })
      .then((res) => {
        setFitlerData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (Object.keys(filterParams).length > 1) getDNSDataTable();
  }, [filterParams]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <ComplianceFirstTable
          loadingTable={loadingTable}
          dnsTableData={dnsTableData}
          FilterData={FilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default ComplianceFirst;
