import { GDPR, HIPPA, ISO, PCI, SOC_2 } from "../../../../../assets/svg";

export const frameworks = [
  {
    name: "HIPAA",
    logo: HIPPA,
    best_practices:
      "The Health Insurance Portability and Accountability Act of 1996 (HIPAA) is a law that requires organizations to protect sensitive health information from being disclosed without a patient's consent or knowledge..\n\nAll organizations that store or process Protected Health Information (PHI) are required to be HIPAA compliant.\n\nCheck this guide from Zylo for some high level tips on becoming HIPAA compliant: <a href='https://zylo.com/blog/hipaa-saas-compliance/'>https://zylo.com/blog/hipaa-saas-compliance/</a>.\n\nContact us if you would like some recommendations on reputable audit firms or consultants who can assist with pre-audit preparation.",
  },
  {
    name: "SOC 2",
    logo: SOC_2,
    best_practices:
      "SOC 2 is a common compliance requirement for service companies.\n\n<ul><li>Privacy</li><li>Security</li><li>Availability</li><li>Processing Integrity</li><li>Confidentiality</li></ul>\n\nGetting a SOC 2 audit is one of the most common requirements from enterprise customers and can be a key blocking point during the sales process. Most SaaS companies choose to obtain a report for Security and do not formally get audited in the other four categories.\n\nNote that the SOC 2 is mostly used in North America. You may want to also consider an ISO 27001 audit if you are targeting international enterprises as well.\n\nFor more information about SOC 2, visit this page: <a href='https://www.blissfully.com/guides/soc-2-compliance/'>https://www.blissfully.com/guides/soc-2-compliance/</a>.\n\nContact us if you would like some recommendations on reputable audit firms or consultants who can assist with pre-audit preparation.",
  },
  {
    name: "GDPR",
    logo: GDPR,
    best_practices:
      "The General Data Protection Regulation is a regulation on data protection and privacy in the European Union and the European Economic Area.\n\nWe recommend becoming GDPR compliant if your application will process any data from residents of the EU/EEA. Note that GDPR applies to EU/EEA citizens that are not currently living in the EU/EEA. This has led to pretty much every major SaaS company complying with GDPR, even if their target market isn't in Europe. Companies usually address the GDPR in their privacy policy. SaaS solutions such as DataGrail can help with the process.\n\nIn addition, some tools you may already be using such as HubSpot may have GDPR friendly functionality built in: <a href='https://knowledge.hubspot.com/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account'>https://knowledge.hubspot.com/account/how-do-i-turn-on-gdpr-functionality-in-my-hubspot-account</a>\n\nFor full details about the GDPR, take a look a this page from Ethyca:\n\nhttps://ethyca.com/general-data-protection-regulation-gdpr/",
  },
  {
    name: "ISO 27001",
    logo: ISO,
    best_practices:
      "ISO 27001 is a popular information security management standard for cloud based companies. Many of the controls center around the processes that an organization has in place to ensure that sensitive data is protected properly.\n\nSimilar to a SOC 2, many organizations require third party SaaS vendors to complete an ISO 27001 certification prior to purchasing a new product. ISO 27001 is much more commonly requested by organizations outside of the United States. If your target audience includes international companies, we recommend looking into ISO 27001 as well as SOC 2.\n\nFor more information on this standard vs SOC 2, check out this blog post from Secureframe:<a href='https://secureframe.com/blog/soc-2-or-iso-27001'>https://secureframe.com/blog/soc-2-or-iso-27001.</a>\n\nContact us if you would like some recommendations on reputable audit firms or consultants who can assist with pre-audit preparation.",
  },
  {
    name: "PCI DSS",
    logo: PCI,
    best_practices:
      "The Payment Card Industry Data Security Standard (PCI DSS) is a security standard for organizations that store or process cardholder data from the major credit card brands such as Visa and Mastercard. Most of the controls center around the processes and procedures related to systems that handle cardholder information.\n\nMerchants that process more than 6 million credit card transactions a year are usually considered to be Level 1 merchants and are required to complete a third party audit annually. Others are required to complete a Self Assessment Questionnaire.\n\nWe always recommend using a third party such as Stripe or Braintree to store your customers' credit card numbers to reduce the scope of PCI. Note that simply using these third party processors does not mean that your company will be exempt from all PCI requirements. In many cases, unless your checkout flow uses an iframe or a redirect to an external payment gateway, parts of your application will most likely fall under the scope of PCI.\n\nCheck out this page from Braintree on PCI compliance for more details:\n\n<a href='https://articles.braintreepayments.com/risk-and-security/compliance/pci-compliance'>https://articles.braintreepayments.com/risk-and-security/compliance/pci-compliance.</a>\n\nContact us if you would like some recommendations on reputable audit firms or consultants who can assist with pre-audit preparation.",
  },
  // {
  //   name: "RM-IT",
  //   logo: "tenants/neotrix/file-uploader/1715329301635_soc2.png",
  //   best_practices: null,
  // },
];

export const controls = [
  {
    name: "Product Security",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M4.33325 18H20.3333M4.33325 6H20.3333H4.33325ZM4.33325 10H20.3333H4.33325ZM4.33325 14H20.3333H4.33325Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Role-Based Access Control",
        best_practices:
          "Many prospects like to see SaaS apps offer role-based access control (RBAC). Allowing your customers to assign roles to their internal users allows admins to grant access to your service based on a user's job function.\n\nAs a best practice, follow the principle of least privilege and only grant users the minimum number of permissions required. For example, someone who works in marketing usually doesn't need the ability to change user permissions, but someone working in IT probably does. If you don't currently have RBAC in place, we recommend starting with something like the following:\n\nAdmin: Users with full access to the application and can invite other users and change their role.\nEditor: Users with full access to the application, minus the ability to invite users and modify permissions.\nRead Only: Users who do not have the ability to edit anything in the application.\nDescribe your application's RBAC capabilities here.",
      },
      {
        name: "Audit Logging",
        best_practices:
          "Audit logs are critical for security teams in the event of a security incident.\n\nAs a best practice, your SaaS app should allow users to see key events such as their last login, if a user disabled multi-factor authentication, if a file was deleted, etc. This way, admins can conduct a detailed investigation if they suspect any malicious behavior within their instance.\n\nRefer to this article for more information about audit logging and their use cases: <a href='https://www.computerweekly.com/tip/Best-practices-for-audit-log-review-for-IT-security-investigations'>https://www.computerweekly.com/tip/Best-practices-for-audit-log-review-for-IT-security-investigations</a>\n\nDescribe your application's audit logging features, and in particular if customers are able to export audit logs to logging solutions such as Splunk or LogRhythm.",
      },
      {
        name: "Data Security",
        best_practices:
          "Use this section to provide a high level summary of your product's data security features.\n\nHere are some application features that you may want to highlight:\n\nSupport for multi-factor authentication and enforcement\nAutomatic logout after a certain period of no activity\nCustom password policies\nCustomer-specific data retention policies\nIntegration with Cloud Access Security Brokers such as Netskope and CipherCloud",
      },
      {
        name: "Integrations",
        best_practices:
          "Integrations are a big part of enterprise SaaS applications and are often a key selling point for prospects.\n\nServices such as Slack, Salesforce, and Amazon Web Services are some of the more well known products that SaaS apps often integrate with.\n\nDescribe any integrations that your potential customers may be interested in here.",
      },
      {
        name: "Multi-Factor Authentication",
        best_practices:
          "Describe your product's MFA options.\n\nApplications that support multi-factor authentication (MFA) require a user to use an additional factor such as an SMS code or a hardware Yubikey in addition to a password when logging in. Requiring users to authenticate using more than just a password severely reduces the risk of unauthorized access.\n\nProspects will often ask for either SAML, Google Workspace, or in-app MFA support to protect their users.\n\nThese days, it is much easier to implement MFA into your app by leveraging one of the popular authentication providers:\nAuth0\nAWS Cognito\nWorkOS",
      },
      {
        name: "Service-Level Agreement",
        best_practices:
          "Enterprise customers usually expect their third party vendors to offer a guaranteed Service-Level Agreement during product evaluations.\n\nCompanies do not want to onboard a new product, only for it to be unreliable and have outages during critical business hours.\n\nWe recommend starting out with a more reasonable SLA such as 99.5% or 99.9% before moving on to 99.95% once your infrastructure is more mature. Use this downtime calculator to determine what an attainable SLA is for your organization: <a href='https://uptime.is/'>https://uptime.is/</a>\n\nDescribe any applicable SLA agreement that you have here.",
      },
      {
        name: "Single Sign-On",
        best_practices:
          "A significant number of SaaS-heavy companies, especially large enterprises, have some form of Single Sign-On (SSO) solution such as Okta or OneLogin.\n\nThese SSO solutions reduce the risk of unauthorized access to SaaS applications by removing the need for users to have dozens of separate passwords for various services. In addition, SSO often comes with the additional benefit of multi-factor authentication.\n\nWe recommend supporting SSO for all customers, regardless of the pricing plan they are on. At a bare minimum, we recommend allowing users to login with their Google or Office accounts. For full SAML support, you can write this in house, or leverage a solution such as WorkOS to build in SSO more quickly.\n\nDescribe your application's SSO capabilities.",
      },
      {
        name: "Team Management",
        best_practices:
          "Enterprise-ready SaaS apps often include advanced features for team management to allow larger organizations to effectively use them.\n\nCommon features include the ability to create sub teams, link permissions with Active Directory groups, and granular object-level permissions.\n\nDescribe your application's team management capabilities.",
      },
    ],
  },
  {
    name: "Reports",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.66675 17V15V17ZM12.6667 17V13V17ZM15.6667 17V11V17ZM17.6667 21H7.66675C7.13632 21 6.62761 20.7893 6.25253 20.4142C5.87746 20.0391 5.66675 19.5304 5.66675 19V5C5.66675 4.46957 5.87746 3.96086 6.25253 3.58579C6.62761 3.21071 7.13632 3 7.66675 3H13.2527C13.5179 3.00006 13.7723 3.10545 13.9597 3.293L19.3737 8.707C19.5613 8.89449 19.6667 9.1488 19.6667 9.414V19C19.6667 19.5304 19.456 20.0391 19.081 20.4142C18.7059 20.7893 18.1972 21 17.6667 21Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "HIPAA Report",
        best_practices:
          "Upload your latest HIPAA report here.\n\nBe sure to update this once a year after you complete your annual audit.\n\nContact us if you would like some recommendations on reputable audit firms or consultants who can assist with pre-audit preparation.",
      },
      {
        name: "Network Diagram",
        best_practices:
          "Upload your network diagram here.\n\nUploading a high level network diagram allows prospective customers to understand your organization's infrastructure, and to see how their data flows throughout your system. Be sure to update this on a regular basis as your company expands.\n\nYou can use free or low cost tools like Lucidchart to create your diagram.",
      },
      {
        name: "Other Reports",
        best_practices:
          "Use this section to upload any reports that are not explicitly listed.",
      },
      {
        name: "PCI DSS",
        best_practices:
          "The Payment Card Industry Data Security Standard (PCI DSS) is a security standard for organizations that store or process cardholder data from the major credit card brands such as Visa and Mastercard. Most of the controls center around the processes and procedures related to systems that handle cardholder information.\n\nMerchants that process more than 6 million credit card transactions a year are usually considered to be Level 1 merchants and are required to complete a third party audit annually. Others are required to complete a Self Assessment Questionnaire.\n\nWe always recommend using a third party such as Stripe or Braintree to store your customers' credit card numbers to reduce the scope of PCI. Note that simply using these third party processors does not mean that your company will be exempt from all PCI requirements. In many cases, unless your checkout flow uses an iframe or a redirect to an external payment gateway, parts of your application will most likely fall under the scope of PCI.\n\nCheck out this page from Braintree on PCI compliance for more details: <a href='https://articles.braintreepayments.com/risk-and-security/compliance/pci-compliance'>https://articles.braintreepayments.com/risk-and-security/compliance/pci-compliance</a>.\n\nContact us if you would like some recommendations on reputable audit firms or consultants who can assist with pre-audit preparation.",
      },
      {
        name: "Pentest Report",
        best_practices:
          "Upload your latest pentest report here.\n\nWe recommend waiting until after you remediate any serious vulnerabilities before uploading. Be sure to update this at least once a year after remediation and retesting has been completed.\n\nContact us if you would like some recommendations on reputable pentest firms.",
      },
      {
        name: "Data Localization Report",
        best_practices:
          "The Personal Data Protection Bill, 2019, which requires companies to store personal data of Indian citizens within India.\nThe Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, which require intermediaries to store certain types of data, such as user content, within India.he Reserve Bank of India (Payments and Settlement Systems) Directions, 2022, which require payment system operators to store payment data within India.",
      },
      {
        name: "Security Prospectus",
        best_practices:
          "Here you can upload any other reports that don't fit under other items.\n",
      },
      {
        name: "Vulnerability Assessment Report",
        best_practices:
          "We recommend uploading a vulnerability scanner report once a quarter after you have performed remediation on any major vulnerabilities.\n\nVulnerability reports aren't a super common request from customers, but having one available to share can certainly demonstrate your organization's commitment to security.\n\nEnterprise Grade Options:\n\nTenable\nRapid7\nBudget Friendly Options:\n\nOpenVAS",
      },
      {
        name: "Security Whitepaper",
        best_practices:
          "Upload your security whitepaper here.\n\nSome customers may ask for an offline security whitepaper in PDF format during the sales process. We recommend building your whitepaper based on the information you already have on this Security Portal.",
      },
    ],
  },
  {
    name: "Completed Forms",
    icon: '<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M9 16H9.01M9 5H7C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V7C19 6.46957 18.7893 5.96086 18.4142 5.58579C18.0391 5.21071 17.5304 5 17 5H15H9ZM9 5C9 5.53043 9.21071 6.03914 9.58579 6.41421C9.96086 6.78929 10.4696 7 11 7H13C13.5304 7 14.0391 6.78929 14.4142 6.41421C14.7893 6.03914 15 5.53043 15 5H9ZM9 5C9 4.46957 9.21071 3.96086 9.58579 3.58579C9.96086 3.21071 10.4696 3 11 3H13C13.5304 3 14.0391 3.21071 14.4142 3.58579C14.7893 3.96086 15 4.46957 15 5H9ZM12 12H15H12ZM12 16H15H12ZM9 12H9.01H9Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "CAIQ",
        best_practices:
          "The Consensus Assessment Initiative Questionnaire (CAIQ) is a freely available, self-assessment questionnaire that contains questions covering 197 control objectives over 17 domains from the Cloud Controls Matrix created by the Cloud Security Alliance (CSA).\n\nWe recommend completing a CAIQ due to the amount of overlap it has with many custom questionnaires. Note that the latest version of the CAIQ comes bundled with the CSA's Cloud Controls Matrix. The older CAIQ Lite is now obsolete.\n\nYou can download a copy of the CAIQ here: <a href='https://cloudsecurityalliance.org/research/cloud-controls-matrix/'>https://cloudsecurityalliance.org/research/cloud-controls-matrix/</a>",
      },
      {
        name: "CAIQ Lite",
        best_practices:
          "The CAIQ Lite is an older, shortened version of the Consensus Assessment Initiative Questionnaire (CAIQ) Full that was created by the Cloud Security Alliance.\n\nThe CAIQ Lite is now obsolete with the introduction of v4 of the CAIQ and Cloud Controls Matrix (CCM). We recommend using the newest version, as v3 of the CAIQ was released several years ago and is now considered to be outdated.\n\nYou can download v4 here: <a href='https://cloudsecurityalliance.org/research/cloud-controls-matrix/'></a>",
      },
      {
        name: "HECVAT Full",
        best_practices:
          "The HECVAT (Higher Education Community Vendor Assessment Toolkit) is a questionnaire framework specifically designed for higher education to measure vendor risk.\n\nWe recommend completing a full HECVAT once you have completed your HECVAT Lite and have a good security program in place if your software processes highly sensitive data.\n\nThe HECVAT is becoming an increasingly common requirement from security and IT teams at major United States universities and colleges, and we strongly recommend proactively completing it if you intend to sell to these schools.\n\nNote that the HECVAT Full is quite long, but it does have overlap with other standards such as the CAIQ and the SIG that may make the process quicker.\n\nYou can find additional information about the HECVAT here: <a href='https://library.educause.edu/resources/2020/4/higher-education-community-vendor-assessment-toolkit'>https://library.educause.edu/resources/2020/4/higher-education-community-vendor-assessment-toolkit</a>.\n\nUpload a copy of your HECVAT Full here.",
      },
      {
        name: "HECVAT Lite",
        best_practices:
          "The HECVAT (Higher Education Community Vendor Assessment Toolkit) Lite is a is condensed questionnaire framework specifically designed for higher education to measure vendor risk.\n\nIf you intend to sell to United States-based higher education organizations, we recommend completing a HECVAT Lite, a much shorter version of the HECVAT Full, to proactively answer common questions that IT and security teams will ask.\n\nIn some cases, the HECVAT Lite may be sufficient if your software is not considered to be extremely high risk in terms of data processed.\n\nYou can find additional information about the HECVAT here: <a href='https://library.educause.edu/resources/2020/4/higher-education-community-vendor-assessment-toolkit'>https://library.educause.edu/resources/2020/4/higher-education-community-vendor-assessment-toolkit</a>.\n\nUpload a copy of your HECVAT Lite here.",
      },
      {
        name: "Other Questionnaires",
        best_practices:
          "Use this to upload any other previously completed questionnaires.\n\nWhile the CAIQ, SIG, HECVAT, and VSA are the most popular standardized questionnaire formats, we understand that there may be other ones that vendors wish to share with customers.\n\nIn the event that you would like to leverage a previously filled custom questionnaire from a previous sales cycle, be sure to remove all references to any customers or prospect names before uploading.\n\nUse this section as a catch all for other ones that you would like to share.",
      },
      {
        name: "SIG",
        best_practices:
          "The SIG Questionnaire was created by an organization called Shared Assessments. It contains a wide ranging set of questions over 18 risk domains such as Security Policy and Cloud Hosting.\n\nOne of the benefits of using SIG as a questionnaire is that it has significant overlap with several other popular standards, such as HIPAA, ISO 27001, and PCI.\n\nNote that the SIG requires a license to publish. You can purchase a SIG license through SafeBase. SafeBase will bundle the SIG license with a discounted SafeBase plan of your choosing. Please contact us for additional information.\n\nUpload a copy of your SIG here.",
      },
      {
        name: "SIG Core",
        best_practices:
          "The SIG Questionnaire was created by an organization called Shared Assessments. It contains a wide ranging set of questions over 18 risk domains such as Security Policy and Cloud Hosting.\n\nThe SIG Core is an updated version of the original SIG assessment that allows vendors to select the questions that they would like to share with their prospects.\n\nOne of the benefits of using SIG as a questionnaire is that it has significant overlap with several other popular standards, such as HIPAA, ISO 27001, and PCI.\n\nNote that the SIG requires a license to publish. You can purchase a SIG license through SafeBase. SafeBase will bundle the SIG license with a discounted SafeBase plan of your choosing. Please contact us for additional information.\n\nUpload a copy of your SIG Core here.",
      },
      {
        name: "SIG Lite",
        best_practices:
          "The SIG Questionnaire was created by an organization called Shared Assessments. It contains a wide ranging set of questions over 18 risk domains such as Security Policy and Cloud Hosting.\n\nThe SIG Lite is an abbreviated version of the original SIG assessment that allows vendors to provide shorter, more high level responses to save time.\n\nOne of the benefits of using SIG as a questionnaire is that it has significant overlap with several other popular standards, such as HIPAA, ISO 27001, and PCI.\n\nNote that the SIG requires a license to publish. You can purchase a SIG license through SafeBase. SafeBase will bundle the SIG license with a discounted SafeBase plan of your choosing. Please contact us for additional information.\n\nUpload a copy of your SIG Lite here.",
      },
      {
        name: "VSA Core\n",
        best_practices:
          "The Vendor Security Alliance (VSA) Core Questionnaire is a shorter version of the VSA Full Questionnaire that addresses several key security and privacy questions.\n\nThe VSA is a group of leading technology companies such as Adobe and Dropbox that have formed an alliance to improve vendor security by standardizing assessments.\n\nWe recommend completing this questionnaire if you intend to sell to VSA member companies.\n\nYou can find a copy of the VSA Core here: <a href='https://www.vendorsecurityalliance.org/downloadQuestionaire'>https://www.vendorsecurityalliance.org/downloadQuestionaire</a>.\n\nUpload a copy of your VSA Core here.",
      },
      {
        name: "VSA Full\n",
        best_practices:
          "The Vendor Security Alliance (VSA) Full Questionnaire addresses a variety of common security and privacy questions in a standardized format.\n\nThe VSA is a group of leading technology companies such as Adobe and Dropbox that have formed an alliance to improve vendor security by standardizing assessments.\n\nWe recommend completing this questionnaire if you intend to sell to VSA member companies.\n\nYou can find a copy of the VSA Full here: <a href='https://www.vendorsecurityalliance.org/downloadQuestionaire'>https://www.vendorsecurityalliance.org/downloadQuestionaire</a>.\n\nUpload a copy of your VSA Full here.",
      },
    ],
  },
  {
    name: "Data Security\n",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.33326 12.0001L11.3333 14.0001L15.3333 10.0001M20.9513 5.98409C17.7894 6.15201 14.69 5.0587 12.3333 2.94409C9.97652 5.0587 6.87715 6.15201 3.71526 5.98409C3.46079 6.96921 3.33243 7.98264 3.33326 9.00009C3.33326 14.5911 7.15726 19.2901 12.3333 20.6221C17.5093 19.2901 21.3333 14.5921 21.3333 9.00009C21.3333 7.95809 21.2003 6.94809 20.9513 5.98409Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Access Monitoring",
        best_practices:
          "Most modern software companies use a variety of SaaS apps, and have numerous databases containing sensitive data. In the event of a security incident, it is always a good idea to have access logs for SaaS apps, databases, and any other resources in your cloud infrastructure.\n\nMost major cloud providers incorporate access monitoring as a part of their security monitoring solutions. Note that pricing for these tools are based on resource count and/or traffic levels, so be sure to review their pricing pages before use.\n\nHere are the solutions available from some of the major cloud providers:\n\nAzure's Security Center. If you are interested, you can also leverage Azure Sentinel as a full fledged security monitoring solution.\nAmazon Web Service's GuardDuty. GuardDuty is designed to require next to no configuration from users. You can also set up manual alerts via CloudWatch alarms. Many startups use GuardDuty as their Intrusion Detection System.\nGoogle Cloud Platform's Security Command Center\nFor databases, we recommend at a bare minimum to stream login events to your logging solution.\n\nFor SaaS applications, there are a variety of SaaS management solutions that grant visibility into SaaS usage:\n\nEnterprise Grade Options:\n\nBetterCloud\nZylo\nBlissfully\nWe recommend having some form of basic access monitoring so that you are able to identify issues in a timely manner.\n\nDescribe your internal access monitoring here.",
      },
      {
        name: "Backups Enabled",
        best_practices:
          "Database and cloud storage backups are crucial in the event of unforeseen issues such as data centers becoming unavailable or data deletion due to human error.\n\nWe recommend backing up any databases with production data at least once a day. If your budget allows, we also suggest creating read-only replicas of your databases in different regions or availability zones to distribute load and to prevent any one database from being a single point of failure. Most cloud hosted databases have these options available, though they may result in significantly increased costs.\n\nIn addition to databases, we also recommend using features for general cloud storage such as bucket versioning for AWS S3 buckets, and taking advantage of multi-region filestores in Google Cloud Platform.\n\nIt is a best practice to test your backup plans on a regular basis to ensure that your backups are functional in the unfortunate event of an incident. Many customers will ask about backup testing in questionnaires.\n\nDescribe your data backup procedures here.",
      },
      {
        name: "Data Erasure",
        best_practices:
          "Many enterprise customers, and regulations such as GDPR and CCPA, require companies to be able to securely delete their data upon a user's request.\n\nIn many cases, simply deleting a file from a disk drive does not always make it unrecoverable. Sometimes data can be recovered using advanced forensics techniques.\n\nIf you store sensitive data on company owned laptops, you can use tools such as Eraser to ensure that the data cannot be recovered even with advanced forensics capabilities.\n\nFor resources in your cloud environment, read this link for information on how the major cloud providers securely erase data: <a href='https://searchcloudsecurity.techtarget.com/feature/How-Azure-AWS-Google-handle-data-destruction-in-the-cloud'>https://searchcloudsecurity.techtarget.com/feature/How-Azure-AWS-Google-handle-data-destruction-in-the-cloud</a>\n\nWe recommend leveraging the data deletion capabilities of your cloud provider to ensure that intentionally deleted data can never be recovered.\n\nDescribe your data deletion process here.",
      },
      {
        name: "Encryption-at-rest",
        best_practices:
          "Encrypting data at rest is important because it allows your data to remain inaccessible even in the event of a theft of an encrypted file or database dump.\n\nCloud database solutions such as Azure SQL and AWS RDS allow databases to be encrypted. Other resources such as EC2 volumes, Azure Disks, S3 buckets, blob storage, etc. can be encrypted as well.\n\nGoogle Cloud Platform encrypts all customer data at-rest by default.\n\nWe always recommend encrypting your databases at the database level as a bare minimum, and encrypting at the data level for ultra sensitive information such as Social Security Numbers.\n\nYou may be wondering why there is a need to perform encryption if you are using a major cloud infrastructure provider with high levels of physical security. The reality is that many questionnaires still ask about this, and doing so helps with the assessment process.\n\nDescribe how you store data encrypted-at-rest.",
      },
      {
        name: "Encryption-in-transit",
        best_practices:
          "Encrypting data in-transit is important because it prevents attackers who are monitoring your network from decrypting data within your infrastructure and to/from your users. In this day and age, many people work remotely and connect to public wireless networks that allow for anyone in the vicinity to sniff traffic.\n\nTo encrypt data in-transit internally, you will need to use internal certificates to secure internally hosted apps. Solutions such as Azure AD and Amazon Certificate Manager can make this process simpler. Google Cloud Platform encrypts customer data in transit by default.\n\nWe always recommend encrypting data in-transit both internally within your cloud infrastructure, and externally with users on your public facing web endpoints. If you decide not to do encryption-in-transit internally, be sure to at least enable it for all customer facing sites, and require a VPN or similar option for internal app access.\n\nDescribe how data in-transit is encrypted in your infrastructure.",
      },
      {
        name: "Physical Security",
        best_practices:
          "Common cloud infrastructure providers provide managed physical security for your cloud resources. With that being said, physical security is still important if your organization has a dedicated office.\n\nIn fact, many compliance frameworks have dedicated sections for physical security that are often a weak point for organizations.\n\nIf you have a dedicated office, we recommend implementing basic controls such as:\n\n24/7 lobby security guards (your office landlord might already provide this)\nRequiring all visitors to sign in and present identification\nRequiring visitors to wear visible guest badges and be accompanied by employees at all times\nCCTV cameras covering all entrances and exits\nRFID cards for office access, and restricted access to any rooms containing servers or other IT infrastructure\nDescribe any measures you take to physically secure your company's offices.",
      },
    ],
  },
  {
    name: "App Security",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M12.6667 15V17V15ZM6.66675 21H18.6667C19.1972 21 19.7059 20.7893 20.081 20.4142C20.456 20.0391 20.6667 19.5304 20.6667 19V13C20.6667 12.4696 20.456 11.9609 20.081 11.5858C19.7059 11.2107 19.1972 11 18.6667 11H6.66675C6.13632 11 5.62761 11.2107 5.25253 11.5858C4.87746 11.9609 4.66675 12.4696 4.66675 13V19C4.66675 19.5304 4.87746 20.0391 5.25253 20.4142C5.62761 20.7893 6.13632 21 6.66675 21ZM16.6667 11V7C16.6667 5.93913 16.2453 4.92172 15.4952 4.17157C14.745 3.42143 13.7276 3 12.6667 3C11.6059 3 10.5885 3.42143 9.83832 4.17157C9.08818 4.92172 8.66675 5.93913 8.66675 7V11H16.6667Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Bug Bounty",
        best_practices:
          "Bug bounties are popular ways for modern software companies to identify application security bugs via crowdsourcing.\n\nThese programs are often open to any security researcher on the internet, and allow companies to identify and remediate bugs on a continuous basis instead of having point-in-time pentests. In addition, open bug bounty programs can attract a large number of researchers, which in turn can result in very comprehensive bug identification due to a significant amount of people testing an application.\n\nHere are some of the popular platforms that companies use for their bug bounty programs:\n\nEnterprise Grade Options:\n\nHackerOne\nBugcrowd\nBudget Friendly Options:\n\nFederacy\nOpenBugBounty\nOpenly advertising a bug bounty program may lead to a large influx of submissions early on, and not all may be of high quality. Keep in mind that sorting through submissions can potentially take quite a bit of time. Using one of the managed services mentioned above can save you time, but they do charge extra for triaging.\n\nIf you do not decide to start a bug bounty program, we still recommend that you have a public security@[yourdomain] email to allow whitehat researchers to proactively submit any security bugs for your application. There are many out there that will submit bugs with no expectations of a reward and do so to improve the overall security of the internet.\n\nDescribe details about your bug bounty program, if applicable, here.",
      },
      {
        name: "Code Analysis",
        best_practices:
          "Developing secure code is not always easy. Even with great developer training, humans are always susceptible to making mistakes, especially when deadlines are tight and sprints move quickly. Automated code analysis tools can greatly assist your developers by catching common mistakes, and more importantly, informing them of ways to avoid security bugs in the future.\n\nHere are some solutions that you can use to test your code for issues:\n\nEnterprise Grade Options:\n\nCheckmarx\nVeracode\nFortify\nSnyk\nBudget Friendly Options:\n\nArachni\nSonarQube\nWe recommend using one of the budget friendly options at a bare minimum to reduce the risk of insecure code from deploying into production.\n\nDescribe your code analysis capabilities.",
      },
      {
        name: "Bot Detection",
        best_practices:
          "Describe your application's bot detection capabilities.\n\nBot detection solutions are used by software vendors who want to reduce the number of automated requests sent to their web applications. They are most commonly deployed as a defense against brute force login attempts, automated scalping, and ad impression fraud.\n\nThese solutions are quite complex and tend to be fairly expensive, so we recommend holding off on implementation until your organization is large enough that automated traffic is a significant enough problem that standard firewalls can't filter it out.\n\nVendors:\nPerimeterX\nAkamai\nF5",
      },
      {
        name: "Runtime Application Self Protection",
        best_practices:
          "Describe any RASP tools that are used to protect your web app.\n\nRuntime Application Self Protection tools are a fairly new category of security solutions that help to protect web applications from attacks in real-time. In contrast to traditional web application firewalls which use static rules, RASP platforms use machine learning and behavioral context to more accurately identify threats. In some cases, they may even be able to block exploits that would otherwise be successful against an unpatched server or web application.\n\nVendors:\nSignal Sciences\nImperva\nContrast Security",
      },
      {
        name: "Software Development Lifecycle",
        best_practices:
          "Many SaaS companies move quickly and push out features at a rapid pace. Having a formal Software Development Lifecycle (SDLC) allows companies to maintain their speed, all while having formalized procedures in place to reduce the risk of bugs and to ensure that features are created properly and on time.\n\nDescribe key aspects of your software development lifecycle here.\n\nYou may want to highlight security related aspects of your SDLC, such as:\n\nBranch protection on main branches\nVulnerability scanning as a part of your CI/CD build pipeline\nPeer review requirement for major changes",
      },
      {
        name: "Credential Management",
        best_practices:
          "Proper credential management is critical in any cloud infrastructure environment. Storing credentials in code can lead to secrets being publicly leaked accidentally on GitHub and GitLab.\n\nWe recommend using a dedicated credential management solution for all application credentials:\n\nHashicorp Vault\nAzure Key Vault\nAWS KMS\nDoppler\nGoogle Cloud Secret Manager\nSome of these solutions include the ability to automatically rotate secrets dynamically to reduce the impact of any stolen credentials.\n\nWe know that it may seem convenient to store secrets in environmental variables, but we strongly recommend using a dedicated solution that allows for secrets to be fetched dynamically. Customer questionnaires often ask about this as well. In addition, storing secrets in one centralized solution makes credential rotation much simpler.\n\nDescribe how you securely manage credentials in your infrastructure.",
      },
      {
        name: "Vulnerability & Patch Management",
        best_practices:
          "Vulnerability and patch management is one of the more time consuming, but important aspects of any security program.\n\nModern SaaS software relies heavily on open source and proprietary third party libraries and dependencies. Patching and updating library versions does indeed take a lot of time, but can be key to preventing data breaches.\n\nHere are some solutions that can help you identify security vulnerabilities and missing patches in your environment:\n\nEnterprise Grade Options:\n\nTenable.io\nNexpose\nBudget Friendly Options:\n\nOpenVAS\nAnchore\nClair\nWe recommend manually applying patches to laptops and servers at least monthly if you do not deploy a dedicated vulnerability scanner. At a bare minimum, ensure that your systems have patches for vulnerabilities deemed critical or high by the vendor.\n\nDescribe your vulnerability and patch management processes here.",
      },
      {
        name: "Web Application Firewall",
        best_practices:
          "Web application firewalls (WAF) are used to protect your application from common malicious web application attacks such as SQL injections and cross-site scripting.\n\nWAFs are also a useful tool to have because you can use them to do things like quickly apply rate limits to an endpoint, or outright block malicious IPs. This can come in handy once your company begins to gain traction and attracts the attention of malicious attackers.\n\nWe recommend using the Cloudflare Pro Plan as an inexpensive starting point to add basic protection to your web app.\n\nThere are also a few other options you can consider once your company grows:\n\nEnterprise Grade Options:\n\nCloudflare Enterprise\nSignal Sciences\nAkamai\nImperva\nBudget Friendly Option:\n\nCloudflare Pro\nDescribe your web application firewall.\n",
      },
    ],
  },
  {
    name: "\nAccess Control",
    icon: '<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M15 7.00008C15.5304 7.00008 16.0391 7.21079 16.4142 7.58586C16.7893 7.96093 17 8.46964 17 9.00008L15 7.00008ZM21 9.00008C21.0003 9.93725 20.781 10.8615 20.3598 11.6986C19.9386 12.5358 19.3271 13.2627 18.5744 13.821C17.8216 14.3792 16.9486 14.7535 16.0252 14.9136C15.1018 15.0737 14.1538 15.0153 13.257 14.7431L11 17.0001H9V19.0001H7V21.0001H4C3.73478 21.0001 3.48043 20.8947 3.29289 20.7072C3.10536 20.5196 3 20.2653 3 20.0001V17.4141C3.00006 17.1489 3.10545 16.8946 3.293 16.7071L9.257 10.7431C9.00745 9.91809 8.93857 9.04902 9.05504 8.19502C9.17152 7.34102 9.47062 6.52215 9.93199 5.79412C10.3934 5.0661 11.0062 4.44602 11.7287 3.97609C12.4512 3.50617 13.2665 3.19743 14.1191 3.07088C14.9716 2.94434 15.8415 3.00296 16.6693 3.24276C17.4972 3.48256 18.2637 3.89791 18.9166 4.46054C19.5696 5.02317 20.0936 5.71987 20.4531 6.50322C20.8127 7.28656 20.9992 8.13817 21 9.00008V9.00008Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Data Access\n",
        best_practices:
          "Most modern tech companies use multiple SaaS services for things like email, infrastructure, analytics etc. SaaS management is becoming an increasingly big problem due to the difficulty around properly managing permissions for users.\n\nHere are some best practices that we recommend:\n\nAssign permissions to users based on predefined roles decided by their managers\nGrant users permissions based on the principle of least privilege, meaning don't give someone edit or admin access unless they need it for their job function\nReview permissions for critical services quarterly to identify any excessive or outdated permissions\nIf possible, use an SSO solution like Okta or Onelogin to grant access based on pre-existing groups\nHere are some tools that you can use to better manage your SaaS permissions:\n\nEnterprise Grade Options:\n\nBetterCloud\nZylo\nBlissfully\nDescribe your company's data access procedures.",
      },
      {
        name: "Logging",
        best_practices:
          "Most modern software companies use a variety of SaaS apps, and have numerous databases containing sensitive data. In the event of a security incident, it is always a good idea to have access logs for SaaS apps, databases, and any other resources in your cloud infrastructure.\n\nAn increasing number of SaaS tools are beginning to allow the export of activity logs to a centralized logging solution. In addition, most popular databases also allow for log export.\n\nHere are some options for logging:\n\nEnterprise Grade Options:\n\nSplunk\nLogRhythm\nSumo Logic\nBudget Friendly Option:\n\nGraylog Open Source\nDescribe your company's logging solution.",
      },
      {
        name: "Password Security",
        best_practices:
          "Reused passwords are one of the most common causes of data breaches. Having a strong password policy reduces the risk of unauthorized access to your company's accounts.\n\nHere is a recommended baseline security complexity policy:\n\nMinimum of 8 characters\nUppercase character\nLowercase character\nNumber\nSpecial character !, #, %, ^, etc.\nIn addition to requiring strong passwords, we always recommend requiring users to use dedicated password managers for storing individual and shared credentials. Here are some team-friendly password managers that you can consider:\n\n1Password\nLastPass\nDashlane\nBitwarden\nDescribe your company's internal password security here.",
      },
    ],
  },
  {
    name: "Infrastructure",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M8.33325 14V17V14ZM12.3333 14V17V14ZM16.3333 14V17V14ZM3.33325 21H21.3333H3.33325ZM3.33325 10H21.3333H3.33325ZM3.33325 7L12.3333 3L21.3333 7H3.33325ZM4.33325 10H20.3333V21H4.33325V10Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Anti-DDoS",
        best_practices:
          "With the rise in cloud infrastructure, it is much easier for attackers to conduct massive denial of service attacks against targeted websites compared to the past.\n\nWe recommend deploying an anti-DDoS solution such as the ones listed here to ensure that your applications won't be brought down by a malicious DDoS attack in the future:\n\nEnterprise Grade Options:\n\nFastly\nAkamai\nCloudflare Enterprise\nBudget Friendly Option:\n\nCloudflare Free Plan\nWe highly recommend using Cloudflare's free plan at a bare minimum for basic DDoS protection. Simply using a big infrastructure provider like GCP or AWS does not mean that you will automatically be protected from DDoS attacks.\n\nDescribe any anti-DDoS mitigation techniques in your infrastructure.",
      },
      {
        name: "Amazon Web Services",
        best_practices:
          "Securing your Amazon Web Services environment is vital for ensuring the security of your company's data.\n\nAWS has published several whitepapers and blogs for AWS security best practices here: <a href='https://aws.amazon.com/architecture/security-identity-compliance/?cards-all.sort-by=item.additionalFields.sortDate&cards-all.sort-order=desc'>https://aws.amazon.com/architecture/security-identity-compliance/?cards-all.sort-by=item.additionalFields.sortDate&cards-all.sort-order=desc</a>.\n\nIn addition, there are several third party tools that you can use to secure your AWS accounts:\n\nEnterprise Grade Options:\n\nLacework\nPalo Alto Prisma\nThreat Stack\nBridgecrew Enterprise\nBudget Friendly Options:\n\nScout Suite\nCloudSploit Open Source\nBridgecrew Chekov\nVectrix\nDescribe the steps you take to increase security in your Amazon Web Services environment.\n\n",
      },
      {
        name: "Azure",
        best_practices:
          "Securing your Azure environment is vital for ensuring the security of your company's data.\n\nMicrosoft has published several guides for Azure security best practices here:\n\n<a href='https://docs.microsoft.com/en-us/azure/security/fundamentals/best-practices-and-patterns>https://docs.microsoft.com/en-us/azure/security/fundamentals/best-practices-and-patterns</a>.\n\nIn addition, there are several third-party tools that you can use to secure your Azure instances:\n\nEnterprise Grade Options:\n\nLacework\nPalo Alto Prisma\nBridgecrew Enterprise\nBudget Friendly Options:\n\nScout Suite\nCloudSploit Open Source\nBridgecrew Chekov\nDescribe the steps you take to increase security in your Azure environment.",
      },
      {
        name: "BC/DR",
        best_practices:
          "Describe your organization's ability to restore services and continue operating in the event of a major disruption.\n\nBusiness continuity and disaster recovery plans are a key part of any SaaS organization. It's important to have a plan to be able to continue operating your website in the event of unpredictable events such as a data center outage.\n\nProspects almost always ask about your BC/DR capabilities to ensure that you will not have a significant amount of downtime in the event of a major outage.",
      },
      {
        name: "Data Center",
        best_practices:
          "Use this section to describe your data center provider if you use one other than AWS/GCP/Azure/Heroku.\n\n",
      },
      {
        name: "Firebase",
        best_practices:
          "Securing your Firebase environment is vital for ensuring the security of your company's data.\n\nGoogle has published a Firebase security checklist here:<a href='https://firebase.google.com/support/guides/security-checklist'>https://firebase.google.com/support/guides/security-checklist.</a>\n\nDescribe the steps you take to increase security in your Firebase environment.",
      },
      {
        name: "Google Cloud Platform",
        best_practices:
          "Securing your Google Cloud Platform environment is vital for ensuring the security of your company's data.\n\nGoogle has published an official best practices guide here: https://cloud.google.com/docs/enterprise/best-practices-for-enterprise-organizations.\n\nTo make this process easier, we recommend using a third party solution that streamlines configuration checks and suggestions:\n\nEnterprise Grade Options:\n\nLacework\nPalo Alto Prisma\nBudget Friendly Options:\n\nScout Suite\nCloudSploit Open Source\nBridgecrew Chekov\nVectrix\nDescribe the steps you take to increase security in your Google Cloud Platform environment.",
      },
      {
        name: "Heroku",
        best_practices:
          "One of the benefits of using Heroku is that many typical infrastructure duties are fully managed.\n\nHowever, this does not mean that your Heroku environment is automatically secured. We recommend reading this official Heroku blog post for some tips on ensuring that your applications remain secure:<a href='https://blog.heroku.com/ten-ways-to-secure-your-apps'>https://blog.heroku.com/ten-ways-to-secure-your-apps.</a>\n\nDescribe the steps you take to increase security in your Heroku environment.",
      },
      {
        name: "Heroku Private Space",
        best_practices:
          "One of the benefits of using Heroku Private Space is that many typical infrastructure duties are fully managed.\n\nHowever, this does not mean that your Heroku environment is automatically secured. We recommend reading this official Heroku blog post for some tips on ensuring that your applications remain secure:<a href='https://blog.heroku.com/ten-ways-to-secure-your-apps'>https://blog.heroku.com/ten-ways-to-secure-your-apps.</a>\n\nDescribe the steps you take to increase security in your Heroku Private Space environment.\n\n",
      },
      {
        name: "Infrastructure Security",
        best_practices:
          "Use this space for anything else you would like to share about your infrastructure security.\n\nYou may want to detail items such as:\n\nHow users remotely access your infrastructure\nAny endpoint protection solutions deployed on servers\nInformation regarding container security\nUse of Yubikeys for privileged infrastructure access",
      },
      {
        name: "Separate Production Environment",
        best_practices:
          "It is always a good idea to ensure that no production customer data is ever used during testing.\n\nAs a best practice, your developers should use test data during development and debugging. While it may seem convenient, using production data during testing can sometimes lead to data leaks or breaches due to human error.\n\nDescribe how your infrastructure segregates production data from any non-production ones.",
      },
    ],
  },
  {
    name: "Security Grades",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M11.3333 4C11.3333 3.46957 11.544 2.96086 11.919 2.58579C12.2941 2.21071 12.8028 2 13.3333 2C13.8637 2 14.3724 2.21071 14.7475 2.58579C15.1225 2.96086 15.3333 3.46957 15.3333 4V5C15.3333 5.26522 15.4386 5.51957 15.6261 5.70711C15.8137 5.89464 16.068 6 16.3333 6H19.3333C19.5985 6 19.8528 6.10536 20.0404 6.29289C20.2279 6.48043 20.3333 6.73478 20.3333 7V10C20.3333 10.2652 20.2279 10.5196 20.0404 10.7071C19.8528 10.8946 19.5985 11 19.3333 11H18.3333C17.8028 11 17.2941 11.2107 16.919 11.5858C16.544 11.9609 16.3333 12.4696 16.3333 13C16.3333 13.5304 16.544 14.0391 16.919 14.4142C17.2941 14.7893 17.8028 15 18.3333 15H19.3333C19.5985 15 19.8528 15.1054 20.0404 15.2929C20.2279 15.4804 20.3333 15.7348 20.3333 16V19C20.3333 19.2652 20.2279 19.5196 20.0404 19.7071C19.8528 19.8946 19.5985 20 19.3333 20H16.3333C16.068 20 15.8137 19.8946 15.6261 19.7071C15.4386 19.5196 15.3333 19.2652 15.3333 19V18C15.3333 17.4696 15.1225 16.9609 14.7475 16.5858C14.3724 16.2107 13.8637 16 13.3333 16C12.8028 16 12.2941 16.2107 11.919 16.5858C11.544 16.9609 11.3333 17.4696 11.3333 18V19C11.3333 19.2652 11.2279 19.5196 11.0404 19.7071C10.8528 19.8946 10.5985 20 10.3333 20H7.33325C7.06804 20 6.81368 19.8946 6.62615 19.7071C6.43861 19.5196 6.33325 19.2652 6.33325 19V16C6.33325 15.7348 6.22789 15.4804 6.04036 15.2929C5.85282 15.1054 5.59847 15 5.33325 15H4.33325C3.80282 15 3.29411 14.7893 2.91904 14.4142C2.54397 14.0391 2.33325 13.5304 2.33325 13C2.33325 12.4696 2.54397 11.9609 2.91904 11.5858C3.29411 11.2107 3.80282 11 4.33325 11H5.33325C5.59847 11 5.85282 10.8946 6.04036 10.7071C6.22789 10.5196 6.33325 10.2652 6.33325 10V7C6.33325 6.73478 6.43861 6.48043 6.62615 6.29289C6.81368 6.10536 7.06804 6 7.33325 6H10.3333C10.5985 6 10.8528 5.89464 11.0404 5.70711C11.2279 5.51957 11.3333 5.26522 11.3333 5V4Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Qualys SSL Labs",
        best_practices:
          "Qualys has a free server test that allows you to review to a website's certificate and TLS configuration information. You can access it here.\n\nWe recommend configuring your servers to have an SSL Labs grade of an A or A+. While this grade on its own does not indicate that the rest of your security is also excellent, a lower score can potentially be a red flag for customers.",
      },
    ],
  },
  {
    name: "Endpoint Security",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M19.0307 5.636L15.4947 9.172L19.0307 5.636ZM15.4947 14.828L19.0307 18.364L15.4947 14.828ZM9.83875 9.172L6.30275 5.636L9.83875 9.172ZM9.83875 14.828L6.30275 18.364L9.83875 14.828ZM21.6667 12C21.6667 13.1819 21.434 14.3522 20.9817 15.4442C20.5294 16.5361 19.8664 17.5282 19.0307 18.364C18.195 19.1997 17.2028 19.8626 16.1109 20.3149C15.019 20.7672 13.8486 21 12.6667 21C11.4849 21 10.3145 20.7672 9.2226 20.3149C8.13067 19.8626 7.13851 19.1997 6.30279 18.364C5.46706 17.5282 4.80412 16.5361 4.35183 15.4442C3.89954 14.3522 3.66675 13.1819 3.66675 12C3.66675 9.61305 4.61496 7.32387 6.30279 5.63604C7.99061 3.94821 10.2798 3 12.6667 3C15.0537 3 17.3429 3.94821 19.0307 5.63604C20.7185 7.32387 21.6667 9.61305 21.6667 12ZM16.6667 12C16.6667 13.0609 16.2453 14.0783 15.4952 14.8284C14.745 15.5786 13.7276 16 12.6667 16C11.6059 16 10.5885 15.5786 9.83832 14.8284C9.08818 14.0783 8.66675 13.0609 8.66675 12C8.66675 10.9391 9.08818 9.92172 9.83832 9.17157C10.5885 8.42143 11.6059 8 12.6667 8C13.7276 8 14.745 8.42143 15.4952 9.17157C16.2453 9.92172 16.6667 10.9391 16.6667 12V12Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Disk Encryption",
        best_practices:
          "Describe the encryption used to protect data on your corporate devices.\n\nFull-disk encryption is vital for organizations that are heavily reliant on using laptops or other portable devices. In the event of a stolen or lost laptop, the data on these devices remain encrypted, preventing the data from falling into the wrong hands.\n\nWindows Professional or Enterprise based computers can leverage BitLocker, and macOS computers can be encrypted using FileVault. If possible, we recommend using your MDM solution to enforce encryption so that decryption keys can be stored in a centralized place.\n\nWhile there are third party disk encryption solutions, we usually recommend sticking with the native operating system options for ease of implementation.",
      },
      {
        name: "DNS Filtering",
        best_practices:
          "DNS filtering is used to block access to known malicious websites. DNS filtering can either be done manually by adding websites to block at the firewall or hosts file level, or by deploying a dedicated vendor solution.\n\nHere are some solutions that offer DNS filtering for malicious websites:\n\nWebTitan\nCisco Umbrella\nMimecast\nDNS filtering can take some effort to setup, and requires maintenance as trends in security change. We recommend waiting for your first IT hire before deploying a DNS filtering solution, and leveraging malicious website warnings from popular browsers such as Firefox and Chrome in the interim, along with a healthy dose of employee training and acceptable use reminders. In addition, some endpoint protection solutions may already incorporate DNS filtering as a feature.\n\nDescribe any DNS filtering on your network or endpoints.",
      },
      {
        name: "Mobile Device Management",
        best_practices:
          "Mobile Device Management (MDM) solutions allow IT administrators to centrally manage mobile devices such as laptops and smartphones.\n\nThese tools can enforce key policies such as disk encryption, password requirements, and patches. More importantly, they allow administrators to remotely wipe a device in the event of a theft or loss, ensuring that sensitive company data never ends up in the wrong hands.\n\nCommon MDM solutions:\n\nJamf for Apple devices\nMicrosoft Endpoint Manager for Windows, macOS, iOS, and Android devices\nFleetsmith for Apple devices\nVMware Workspace ONE for most types of devices\nDespite the cost, we always recommend that all companies use an MDM for company owned laptops given the major benefits of having managed security on endpoints. Many of them do not require an IT expert to configure essential policies such as encryption enforcement.\n\nDescribe your MDM solution.",
      },
      {
        name: "Threat Detection",
        best_practices:
          "Threat Detection solutions are used to identify any Advanced Persistent Threat (APT) actors that may be attempting to intrude your network.\n\nAPTs are typically nation state sponsored hacking groups with significant technical abilities and financial resources. Attacks from these groups are usually highly sophisticated and targeted and are not easy to detect with standard, traditional signature-based anti-virus solutions.\n\nAPTs typically target government organizations and large enterprises. Please read this article from FireEye for more information:<a href='https://www.fireeye.com/current-threats/apt-groups.html'>https://www.fireeye.com/current-threats/apt-groups.html</a>\n\nWe recommend investing in security solutions with a focus on APT detection such as FireEye and CrowdStrike if your company deals with government, critical infrastructure, or hold significant amounts of sensitive, personally identifiable information as you reach your growth stages and are heavily investing in your security program.\n\nDescribe any threat detection solution in your environment.",
      },
      {
        name: "Endpoint Detection & Response",
        best_practices:
          'Endpoint Detection & Response (EDR) solutions help protect computers from malware and other threats.\n\nDespite the existence of anti-virus solutions and improvements in software design over the past few decades, the reality is that attackers are still able to find ways to implant malware on computers. Modern EDR solutions use a combination of file signatures and machine learning to identify and stop malware from running on computers before they can cause any damage.\n\nHere are some solutions that you can consider deploying on your laptops:\n\nEnterprise Grade Options:\n\nCrowdstrike\nCarbon Black\nSentinelOne\nCylance\nBudget Friendly Options:\n\nBitDefender\nMalwarebytes\nNote that laptops running macOS can still be susceptible to malware, and that Macs are not automatically "more secure" than Windows machines. Phishing emails can still have malicious attachments targeting Mac users, so having anti-malware protection is important.\n\nDescribe any EDR solutions deployed to your endpoints.',
      },
    ],
  },
  {
    name: "Network Security",
    icon: '<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M3.055 11H5C5.53043 11 6.03914 11.2107 6.41421 11.5858C6.78929 11.9609 7 12.4696 7 13V14C7 14.5304 7.21071 15.0391 7.58579 15.4142C7.96086 15.7893 8.46957 16 9 16C9.53043 16 10.0391 16.2107 10.4142 16.5858C10.7893 16.9609 11 17.4696 11 18V20.945L3.055 11ZM8 3.935V5.5C8 6.16304 8.26339 6.79893 8.73223 7.26777C9.20107 7.73661 9.83696 8 10.5 8H11C11.5304 8 12.0391 8.21071 12.4142 8.58579C12.7893 8.96086 13 9.46957 13 10C13 10.5304 13.2107 11.0391 13.5858 11.4142C13.9609 11.7893 14.4696 12 15 12C15.5304 12 16.0391 11.7893 16.4142 11.4142C16.7893 11.0391 17 10.5304 17 10C17 9.46957 17.2107 8.96086 17.5858 8.58579C17.9609 8.21071 18.4696 8 19 8H20.064L8 3.935ZM15 20.488V18C15 17.4696 15.2107 16.9609 15.5858 16.5858C15.9609 16.2107 16.4696 16 17 16H20.064L15 20.488ZM21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "IDS/IPS",
        best_practices:
          "Best Practices\nIntrusion Detection Systems (IDS) and Intrusion Prevention Systems (IPS) are tools used to monitor for and prevent unauthorized access to your network. They are handy even if you do not have the personnel to constantly monitor them all day due to the fact that they can provide valuable historical information in the event of a security incident.\n\nThe popular cloud infrastructure providers have native detection solutions that work quite well for smaller companies, and require minimal configuration:\n\nAWS GuardDuty\nAzure Security Center\nGoogle Cloud Security Command Center\nEnterprise Grade Options:\n\nDarktrace\nSplunk\nFireEye\nBudget Friendly Options:\n\nSnort\nWazuh\nSecurity Onion\nWe recommend using the cloud infrastructure solutions over the other options if you lack a dedicated security team given their ease of deployment and lack of heavy configuration.\n\nDescribe your IDS or IPS solution.",
      },
      {
        name: "Data Loss Prevention",
        best_practices:
          "Describe how your organization prevents sensitive data from exfiltration.\n\nData Loss Prevention (DLP) tools are used to detect for exfiltration or other unauthorized movement of data within a network. They are most commonly used to prevent sensitive information such as Social Security Numbers or Credit Card Numbers from leaving a network.\n\nNote that DLP can be quite cumbersome to implement properly, so we recommend waiting until you have a dedicated IT and security team before implementation.\n\nVendors:\nDigital Guardian\nForcepoint\nBroadcom",
      },
      {
        name: "DNSSEC",
        best_practices:
          "DNSSEC is a technical standard that makes DNS queries to your domains more secure.\n\nVisit this page by Cloudflare for more information:<a href='https://www.cloudflare.com/dns/dnssec/how-dnssec-works/'>https://www.cloudflare.com/dns/dnssec/how-dnssec-works/.</a>\n\nDNSSEC can be prone to misconfiguration, so we recommend holding off on enabling it unless your infrastructure team has prior experience, or until you hire your first security team member.\n\nDescribe any DNSSEC configuration for your domains.",
      },
      {
        name: "Firewall",
        best_practices:
          "If your company has physical offices, you will likely need a hardware firewall to protect the internal network from external attacks and malicious websites.\n\nHere are some options to consider:\n\nEnterprise Grade Options:\n\nPalo Alto\nSonicwall\nCisco\nBudget Friendly Options:\n\npfSense\nWe recommend adding a firewall to your office network once your company is large enough to hire an IT person. As for your cloud environment, a dedicated network firewall is generally not necessary until you grow much larger given the use of security groups and tools like GuardDuty.\n\nDescribe your company's network firewalls.",
      },
      {
        name: "Security Information and Event Management",
        best_practices:
          "Describe your organization's SIEM tool.\n\nSecurity Information and Event Management (SIEM) tools are used by security teams to analyze application and server logs for security events and incidents. They are able to ingest large amounts of data and allow analysts to quickly create alerts or run custom queries during investigations.\n\nNote that while having these tools are useful, they are best used if there are personnel that can monitor them full time. Having a security team member monitor them part time can lead to increased time-to-discovery and alert overload.\n\nVendors:\nSplunk\nSumo Logic\nAzure Sentinel",
      },
      {
        name: "Spoofing Protection",
        best_practices:
          "Spoofing attacks occur when attackers attempt to impersonate other users on a network to try to access sensitive data or to install malware. Networks with spoofing protection enabled are less likely to be victims to these attacks.\n\nSee this page from Veracode for more information:<a href='https://www.veracode.com/security/spoofing-attack'>https://www.veracode.com/security/spoofing-attack.</a>\n\nWe recommend waiting until you have an IT person or network engineer to enable spoofing protection.\n\nDescribe any spoofing protection enabled on your network.",
      },
      {
        name: "Traffic Filtering",
        best_practices:
          "Describe your network's traffic filtering capabilities.\n\nTraffic filtering can be accomplished in a few different ways, including installing an agent on devices, enabling it via an existing network firewall, or by funneling traffic through a dedicated network gateway.\n\nEnterprise Grade:\nPalo Alto\nCisco\nBarracuda\n\nBudget Friendly:\nSquidGuard via pfSense",
      },
      {
        name: "Virtual Private Cloud",
        best_practices:
          "A virtual private cloud (VPC) is a network hosted on a public cloud environment such as AWS.\n\nCompanies are increasingly using VPC's to create their networks instead of hosting servers in their own data centers or offices.\n\nDescribe your use of any VPCs in your infrastructure.",
      },
      {
        name: "Wireless Security",
        best_practices:
          "Describe how your organization protects its wireless networks.\n\nWhile most people have wireless networks with a single shared password in their homes, this is usually not the case for enterprises. Large organizations typically require users to authenticate using their Active Directory credentials or certificates so that unauthorized personnel cannot connect rogue devices onto the network.\n\nIt is recommended to use WPA2-Enterprise with machine based certificates if possible to truly ensure that your wireless network is protected from unauthorized devices.",
      },
      {
        name: "Zero Trust",
        best_practices:
          "Describe your infrastructure's usage of Zero Trust for access.\n\nIn recent years enterprises have begun to adopt what's known as \"Zero Trust\" solutions. These tools are used to protect an organization's resources by using a combination of signals such as IP addresses, multi factor authentication, user behavior, and more to determine if a user should be allowed access to an application or server. This is in contrast to more traditional VPNs, where an initial authentication usually means access to all resources on a network.\n\nWe recommend considering deploying a Zero Trust solution if your team is remote heavy.\n\nVendors:\nCloudflare\nBanyan Security\nCyolo",
      },
    ],
  },
  {
    name: "Corporate Security",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M9.33325 19V13C9.33325 12.4696 9.12254 11.9609 8.74747 11.5858C8.37239 11.2107 7.86368 11 7.33325 11H5.33325C4.80282 11 4.29411 11.2107 3.91904 11.5858C3.54397 11.9609 3.33325 12.4696 3.33325 13V19C3.33325 19.5304 3.54397 20.0391 3.91904 20.4142C4.29411 20.7893 4.80282 21 5.33325 21H7.33325C7.86368 21 8.37239 20.7893 8.74747 20.4142C9.12254 20.0391 9.33325 19.5304 9.33325 19ZM9.33325 19V9C9.33325 8.46957 9.54397 7.96086 9.91904 7.58579C10.2941 7.21071 10.8028 7 11.3333 7H13.3333C13.8637 7 14.3724 7.21071 14.7475 7.58579C15.1225 7.96086 15.3333 8.46957 15.3333 9V19H9.33325ZM9.33325 19C9.33325 19.5304 9.54397 20.0391 9.91904 20.4142C10.2941 20.7893 10.8028 21 11.3333 21H13.3333C13.8637 21 14.3724 20.7893 14.7475 20.4142C15.1225 20.0391 15.3333 19.5304 15.3333 19H9.33325ZM15.3333 19V5C15.3333 4.46957 15.544 3.96086 15.919 3.58579C16.2941 3.21071 16.8028 3 17.3333 3H19.3333C19.8637 3 20.3724 3.21071 20.7475 3.58579C21.1225 3.96086 21.3333 4.46957 21.3333 5V19C21.3333 19.5304 21.1225 20.0391 20.7475 20.4142C20.3724 20.7893 19.8637 21 19.3333 21H17.3333C16.8028 21 16.2941 20.7893 15.919 20.4142C15.544 20.0391 15.3333 19.5304 15.3333 19Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Email Protection",
        best_practices:
          "Describe your organization's technical capabilities against phishing.\n\nIn addition to providing security awareness training, many organizations use dedicated email protection solutions that can further help reduce the risk of a successful phishing attack. These tools scan the contents of emails for suspicious language, known malicious domains, and malware-infested attachments. Although Google Workspace and Office 365 have good default protection, sometimes organizations choose to go further and add additional protection.\n\nThese tools can be pricey, so we recommend leveraging your cloud email provider's native solutions until your organization becomes bigger.\n\nVendors:\n\nProofpoint\nMimecast\nSpamTitan",
      },
      {
        name: "Employee Training",
        best_practices:
          "Employee security awareness is a key component of any security program. Employees should be trained at least once a year on basic security principles such as spotting phishing emails, avoiding password reuse, and sticking to software from trusted sources.\n\nHere are some low-cost platforms you can use for security training if you don't have a dedicated security team:\n\nKnowBe4\nWizer\nCurricula\nNearly every single questionnaire we've seen asks about security training. Explaining that you either have in-house created content or are using an external platforms are much more powerful than simply replying \"Yes\" to these questions.\n\nDescribe your company's employee training program.",
      },
      {
        name: "Incident Response",
        best_practices:
          "Having an incident response plan is incredibly important for your organization's security.\n\nSee this guide on building an incident response plan if you do not currently have one:<a href='https://www.exabeam.com/incident-response/incident-response-plan/'>https://www.exabeam.com/incident-response/incident-response-plan/.</a>\n\nWe recommend testing your incident response plan at least once a year by running through common scenarios, and ensuring that your team will be able to respond effectively in the event of a real incident. In many cases, companies realize that certain users don't have the proper access to necessary systems in the middle of incidents. Avoid this by being proactive.\n\nContact us if you would like recommendations on security firms that are available to assist with security incidents 24/7.\n\nDescribe your incident response capabilities.\n\n",
      },
      {
        name: "Internal Assessments",
        best_practices:
          "One of the main duties of a security team is to conduct ongoing, regular internal assessments to identify and risks and vulnerabilities for the organization.\n\nAssessments are not just limited to cloud configuration or code analysis, and may include things such as reviewing vendor security or ensuring that physical security policies are being followed. Hiring external consultants and auditors can be expensive, so conducting regular in-house assessments on a regular basis is an effective way to ensure that your security program is sufficiently protecting your company.\n\nIt can be tough to do proper internal assessments early on without a dedicated security subject matter expert. We recommend doing basic activities such as permissions reviews, patching updates, cloud configuration checks, etc. that your team members already are capable of doing. You can also hire external consultants for annual reviews until you are able to hire a dedicated security team.\n\nAs a start, we suggest using some of the following common frameworks to help you do internal benchmarking of your security capabilities:\n\nNIST 800-53\nCSA Cloud Controls Matrix\nContact us if you would like some recommendations for consultants who can perform security risk assessments.\n\nDescribe your internal assessments.",
      },
      {
        name: "Mobile Device Management",
        best_practices:
          "Mobile Device Management (MDM) solutions allow IT administrators to centrally manage mobile devices such as laptops and smartphones.\n\nThese tools can enforce key policies such as disk encryption, password requirements, and patches. More importantly, they allow administrators to remotely wipe a device in the event of a theft or loss, ensuring that sensitive company data never ends up in the wrong hands.\n\nCommon MDM solutions:\n\nJamf for Apple devices\nMicrosoft Endpoint Manager for Windows, macOS, iOS, and Android devices\nFleetsmith for Apple devices\nVMware Workspace ONE for most types of devices\nDespite the cost, we always recommend that all companies use an MDM for company owned laptops given the major benefits of having managed security on endpoints. Many of them do not require an IT expert to configure essential policies such as encryption enforcement.\n\nDescribe your MDM solution.",
      },
      {
        name: "Security Operations Center",
        best_practices:
          "Describe your organization's security operations center capabilities.\n\nA Security Operations Center (SOC) is a dedicated group of personnel whose job is to monitor an organization's internal logs for signs of a potential security incident. These analysts are responsible for making sense of the thousands of logs generated by an organization's servers, laptops, routers, etc. and determining if there is suspicious activity.\n\nThey may not seem necessary early on, but as a network grows larger it becomes much more difficult to rely on a handful of static alerting rules to truly have full visibility into an environment.\n\nSome organizations choose to outsource their SOC to a managed service provider such as deepwatch given the amount of time and money it takes for setup.\n\nWe recommend waiting until your organization is at least mid sized to have a dedicated SOC.",
      },
      {
        name: "Single Sign-On\n",
        best_practices:
          "Single Sign-On (SSO) solutions greatly reduce the risk of unauthorized access via reused passwords by eliminating the need for users to create separate usernames and passwords for different SaaS services. In addition, they reduce IT overhead by making it easy to grant SaaS permissions based on pre-defined groups.\n\nHere are some vendors that offer SSO solutions:\n\nOkta\nOneLogin\nPingIdentity\nIf these solutions are out of your budget, we recommend leveraging Google account logins for SaaS services in the interim. This indirectly enforces MFA for third-party accounts (assuming it's enforced in Google) and also reduces the need for users to create a separate dedicated password.\n\nDescribe your internal SSO usage.",
      },
    ],
  },
  {
    name: "Policies",
    icon: '<svg  viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M12.4058 6.253C13.5738 5.477 15.1598 5 16.9058 5C18.6528 5 20.2378 5.477 21.4058 6.253V19.253C20.2378 18.477 18.6528 18 16.9058 18C15.1598 18 13.5738 18.477 12.4058 19.253M12.4058 6.253V19.253V6.253ZM12.4058 6.253C11.2378 5.477 9.65176 5 7.90576 5C6.15976 5 4.57376 5.477 3.40576 6.253V19.253C4.57376 18.477 6.15976 18 7.90576 18C9.65176 18 11.2378 18.477 12.4058 19.253V6.253Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Acceptable Use Policy",
        best_practices:
          "An Acceptable Use Policy is guide for users on how to properly use computers, accounts, and any other company owned resources.",
      },
      {
        name: "Access Control Policy",
        best_practices:
          "An Access Control Policy defines how users are granted permissions to systems and services.",
      },
      {
        name: "Anti-Malicious Software Policy",
        best_practices:
          "An Anti-Malicious Software Policy describes a company's use of anti-malicious software tools on company owned equipment to reduce the impact of malware and other types of unauthorized software.",
      },
      {
        name: "Asset Management Policy",
        best_practices:
          "An Asset Management Policy describes how an organization maintains an inventory of important assets.",
      },
      {
        name: "Backup Policy\n",
        best_practices:
          "A Backup Policy describes how an organization creates backups of databases and important company data.",
      },
      {
        name: "Business Continuity Policy",
        best_practices:
          "A Business Continuity Policy defines how your organization will respond in the event of a serious disruption to your business such as a data center power outage.\n\n",
      },
      {
        name: "BYOD Policy",
        best_practices:
          "A Bring Your Own Device (BYOD) Policy describes how employees are allowed to use non-company owned mobile devices to access company data.",
      },
      {
        name: "Internal and External Communication Policy",
        best_practices:
          "An Internal and External Communication Policy describes the methods in which users are to communicate with internal and external parties using company owned equipment and services.",
      },
      {
        name: "Data Classification Policy",
        best_practices:
          "A Data Classification Policy provides details on how information is to be labelled internally, ex. public/non-public/sensitive.",
      },
      {
        name: "Data Security Policy",
        best_practices:
          "A Data Security Policy describes a company's required safeguards around company data, such as restricted access or database encryption.",
      },
      {
        name: "Encryption Policy",
        best_practices:
          "An Encryption Policy describes a company's requirements for encryption for laptops, servers, mobile devices, databases, files, etc.",
      },
      {
        name: "ISMS Policy\n",
        best_practices:
          "An Integrated Management System (IMS) Policy describes how an organization organizes all of its systems such as compliance, health & safety, and quality management. It is not strictly a security standard.",
      },
      {
        name: "Incident Response Policy",
        best_practices:
          "A General Incident Response Policy describes a company's procedures in the event of a security incident, such as documentation requirements and communication processes.",
      },
      {
        name: "Information Security Policy\n",
        best_practices:
          "Upload your security policy here if you have a single file containing all of your security policies.",
      },
      {
        name: "Network Security Policy",
        best_practices:
          "A Network Security Policy describes a company's network security controls used to protect the network, such as hardware firewalls, DNS filtering, and intrusion detection systems.\n\nHere is a list of some policies you may want to incorporate into your overall Network Security policy from PurpleSec:<a href='https://purplesec.us/network-security-policies/'>https://purplesec.us/network-security-policies/.</a>",
      },
      {
        name: "Other Policies",
        best_practices:
          "Here you can upload anything that doesn't fit under other policies.\n",
      },
      {
        name: "Password Policy",
        best_practices:
          "A Password Policy is used to describe a company's requirements for passwords such as minimum length.",
      },
      {
        name: "Physical Security\n",
        best_practices:
          "A Physical Security Policy describes a company's requirements for physical security such as cameras or employee badges.",
      },
      {
        name: "Risk Management Policy",
        best_practices:
          "A Risk Management Policy describes how a company identifies, documents, and manages risk to the business.",
      },
      {
        name: "Software Development Lifecycle",
        best_practices:
          "A Software Development Lifecycle document describes the processes and procedures for developing software.",
      },
    ],
  },
  {
    name: "\nLegal",
    icon: '<svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n<path d="M12 21.2935H15M3 6.29346L6 7.29346L3 6.29346ZM6 7.29346L3 16.2935C3.8657 16.9425 4.91852 17.2933 6.0005 17.2933C7.08248 17.2933 8.1353 16.9425 9.001 16.2935L6 7.29346ZM6 7.29346L9 16.2935L6 7.29346ZM6 7.29346L12 5.29346L6 7.29346ZM18 7.29346L21 6.29346L18 7.29346ZM18 7.29346L15 16.2935C15.8657 16.9425 16.9185 17.2933 18.0005 17.2933C19.0825 17.2933 20.1353 16.9425 21.001 16.2935L18 7.29346ZM18 7.29346L21 16.2935L18 7.29346ZM18 7.29346L12 5.29346L18 7.29346ZM12 3.29346V5.29346V3.29346ZM12 21.2935V5.29346V21.2935ZM12 21.2935H9H12Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n</svg>',
    sub_category: [
      {
        name: "Cyber Insurance",
        best_practices:
          "Upload your certificate of insurance.\n\nIf you store or process sensitive data, we recommend inquiring about a cyber insurance policy to ensure that you have financial and public relations support in the unfortunate event of a data breach. We recommend this even if you have a dedicated security team due to the potential financial impact of a data breach.\n\nCoalition is a company that offers cyber insurance to companies of all sizes.\n\nDescribe any cyber liability insurance coverage that you have.",
      },
      {
        name: "Data Processing Agreement\n",
        best_practices:
          "Upload a copy of your Data Protection Addendum.\n\nMany customers, especially those based out of the European Union, will expect vendors to sign a Data Protection Addendum to ensure that sensitive data is handled and processed correctly according to privacy regulations like GDPR and CCPA.",
      },
      {
        name: "Master Services Agreement",
        best_practices:
          "Upload a copy of your Master Services Agreement here.\n\nWhile this isn't security specific, some prospects like to see a vendor's Master Services Agreement ahead of time to review security specific terms.",
      },
      {
        name: "Privacy Policy",
        best_practices:
          "We always recommend adding a publicly available Privacy Policy to your landing page. This document should reviewed by your General Counsel prior to publication.\n\nProvide a link to your Privacy Policy here.",
      },
      {
        name: "Service-Level Agreement",
        best_practices:
          "Enterprise customers usually expect their third party vendors to offer a guaranteed Service-Level Agreement during product evaluations.\n\nCompanies do not want to onboard a new product, only for it to be unreliable and have outages during critical business hours.\n\nWe recommend starting out with a more reasonable SLA such as 99.5% or 99.9% before moving on to 99.95% once your infrastructure is more mature. Use this downtime calculator to determine what an attainable SLA is for your organization:<a href='https://uptime.is/'>https://uptime.is/.</a>\n\nDescribe any applicable SLA agreement that you have here.",
      },
      {
        name: "Terms of Service",
        best_practices:
          "We always recommend adding a publicly available Terms of Service page to your landing page. This document should reviewed by your General Counsel prior to publication.\n\nProvide a link to your Terms of Service here.",
      },
    ],
  },
];
