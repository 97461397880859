export function isSuperAdmin(role) {
  return role === "Super Admin";
}

export function isAdminOrSuperAdmin(role) {
  return role === "Admin" || isSuperAdmin(role);
}

export function isAdminOrSuperAdminInfoAccount(role, email) {
  let emailSplit = email.split("@");
  let emailSplitted = emailSplit[1];
  return (
    role === "Admin" || isSuperAdmin(role) || emailSplitted === "cyberheals.com"
  );
}

export function isAgentOrAdminOrSuperAdmin(role) {
  return role === "Agent" || isAdminOrSuperAdmin(role);
}

export function isSubscriberOrAdminOrSuperAdmin(role) {
  return role === "Super User" || isAdminOrSuperAdmin(role);
}

export function isVendorOrSubscriberOrAdminOrSuperAdmin(role) {
  return role === "Vendor" || isSubscriberOrAdminOrSuperAdmin(role);
}

export function isAuditorOrVendorOrSubscriberOrAdminOrSuperAdmin(role) {
  return role === "Auditor" || isVendorOrSubscriberOrAdminOrSuperAdmin(role);
}

export function isAuditorOrSubscriberOrAdminOrSuperAdmin(role) {
  return role === "Auditor" || isSubscriberOrAdminOrSuperAdmin(role);
}
