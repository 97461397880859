import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import SecureScore from "../../modules/attackSurfaceManagement/components/SecureScore";
import { ThemeContext } from "../../context/ThemeContext";
import Listing from "../../common/Listing";
import DonutChart from "../../common/Charts/DonutChart";
import ListingWithBtn from "../../common/Charts/ListingWithBtn";
import Header from "../../common/Charts/Header";
import AreaChart from "../../common/Charts/AreaChart";
import PassFailDonut from "../../common/Charts/PassFailDonut";
import { complianceAPI } from "../../Api/CSPM/api";
import SeverityDonut from "../../common/Charts/SeverityDonut";
import JustDonutListing from "../../common/JustDonutListing";
import JustDonut from "../../common/Charts/JustDonut";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import RiskHeatMap from "../../modules/home/components/compliance/RiskHeatMap";

export default function Compliance({ frameworks, setFrameworks }) {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const userDetails = useSelector(getUserDetails);
  const user = userDetails?.org_id;
  const framework = userDetails?.framework;
  const [riskAssessment, setRiskAssessment] = useState("inherent_risk");

  const getData = () => {
    complianceAPI
      .get("/dashboard/", { user, framework })
      .then((res) => {
        setData(res.data);
        setFrameworks(
          frameworks.map((i) => ({
            ...i,
            value: res.data?.framework?.[i.key],
          }))
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, [user, framework]);

  console.log(data, "asd");

  return (
    <>
      <div className="lg:grid lg:grid-cols-4 lg:gap-6 flex flex-col gap-6">
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <SecureScore
            title="Compliance Status"
            secureScoreGraphCategory={[]}
            secureScoreGraphData={[]}
            progressBar={[]}
            avg={Math.round(data?.action_score, 2) || 0}
            tooltip="Overview of the completion progress for audit readiness."
          />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <Listing
            title="Audit Status"
            tooltip="Overview of the current and total scheduled audits."
            data={[
              {
                key: "Total Audits",
                value: data?.audit?.total || 0,
                onClick: () => navigate("/compliance/audit"),
              },
              {
                key: "Current Audits",
                value: data?.audit?.in_progress || 0,
                onClick: () => navigate("/compliance/audit"),
              },
              {
                key: "Completed Audits",
                value: data?.audit?.completed || 0,
                onClick: () => navigate("/compliance/audit"),
              },
            ]}
          />
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          {/* <RiskHeatMap risk_heat_map={data.risk_heat_map} /> */}
          <div className="px-3">
            <DonutChart
              title="Risk Assessment"
              type="normal"
              labels={["Critical", "High", "Medium", "Low", "Very Low"]}
              colors={["#A30123", "#E8001D", "#F5B207", "#148842", "#7B7DE2"]}
              chartData={[
                data?.risk_heat_map?.[riskAssessment]?.critical | 0,
                data?.risk_heat_map?.[riskAssessment]?.high | 0,
                data?.risk_heat_map?.[riskAssessment]?.medium | 0,
                data?.risk_heat_map?.[riskAssessment]?.low | 0,
                data?.risk_heat_map?.[riskAssessment]?.very_low | 0,
              ]}
              conditionType="Issues"
              total={
                (data?.risk_heat_map?.[riskAssessment]?.critical | 0) +
                (data?.risk_heat_map?.[riskAssessment]?.high | 0) +
                (data?.risk_heat_map?.[riskAssessment]?.medium | 0) +
                (data?.risk_heat_map?.[riskAssessment]?.low | 0) +
                (data?.risk_heat_map?.[riskAssessment]?.very_low | 0)
              }
              legends={[
                {
                  name: `Critical - ${
                    data?.risk_heat_map?.[riskAssessment]?.critical | 0
                  }`,
                  color: "#A30123",
                },
                {
                  name: `High - ${
                    data?.risk_heat_map?.[riskAssessment]?.high | 0
                  }`,
                  color: "#E8001D",
                },
                {
                  name: `Medium - ${
                    data?.risk_heat_map?.[riskAssessment]?.medium | 0
                  }`,
                  color: "#F5B207",
                },
                {
                  name: `Low - ${
                    data?.risk_heat_map?.[riskAssessment]?.low | 0
                  }`,
                  color: "#148842",
                },
                {
                  name: `Very Low - ${
                    data?.risk_heat_map?.[riskAssessment]?.very_low | 0
                  }`,
                  color: "#7B7DE2",
                },
              ]}
              navigateLink="/compliance/risk"
              tooltip="Overview of risks identified during the implementation stage."
              showing={riskAssessment}
              setShowing={setRiskAssessment}
            />
          </div>
        </div>
        <div
          className={`lg:col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <ListingWithBtn
            title="Frameworks"
            tooltip="Overview of active compliance frameworks."
            data={[
              {
                key: "ISO 27001",
                value: data?.framework?.iso_27001 || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.iso_27001 || ""}`,
                    {
                      state: {
                        id: data?.framework?.iso_27001,
                        name: "ISO 27001",
                      },
                    }
                  ),
              },
              {
                key: "SOC 2",
                value: data?.framework?.soc_2 || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.soc_2 || ""}`,
                    { state: { id: data?.framework?.soc_2, name: "SOC 2" } }
                  ),
              },
              {
                key: "NCA",
                value: data?.framework?.nca || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.nca || ""}`,
                    { state: { id: data?.framework?.nca, name: "NCA" } }
                  ),
              },
              {
                key: "GDPR",
                value: data?.framework?.gdpr || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.gdpr || ""}`,
                    { state: { id: data?.framework?.gdpr, name: "GDPR" } }
                  ),
              },
              {
                key: "RM-IT",
                value: data?.framework?.rm_it || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.rm_it || ""}`,
                    { state: { id: data?.framework?.rm_it, name: "RM-IT" } }
                  ),
              },
              {
                key: "HIPAA",
                value: data?.framework?.hipaa || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.hipaa || ""}`,
                    { state: { id: data?.framework?.hipaa, name: "HIPAA" } }
                  ),
              },
              {
                key: "DPTM",
                value: data?.framework?.dptm || false,
                onClick: () =>
                  navigate(
                    `/compliance/framework/${data?.framework?.dptm || ""}`,
                    { state: { id: data?.framework?.dptm, name: "DPTM" } }
                  ),
              },
            ]}
          />
        </div>
      </div>
      <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-6 py-6">
        <div className="grid grid-cols-2 gap-6">
          <div
            className={`lg:col-span-1 sm:col-span-2 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <Header
                title="Action Items"
                tooltip="Overview of implementation action items related to frameworks."
              />
              <div className="grid grid-cols-3 gap-5  h-full items-center">
                <PassFailDonut
                  title="Policy"
                  labels={["Failed", "Passed"]}
                  data={data?.policy}
                  path="/compliance/policy"
                />
                <PassFailDonut
                  title="Evidence"
                  data={data?.evidence}
                  path="/compliance/evidence"
                />
                <PassFailDonut
                  title="Test"
                  data={data?.test}
                  path="/compliance-cloud/test"
                  state={{ title: "Test" }}
                />
              </div>
            </div>
          </div>
          <div
            className={`lg:col-span-1 sm:col-span-2 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <Header
                title="Third Party Assessment"
                tooltip="Overview of vendor management and vendor assessment."
              />
              <div className="grid grid-cols-3 gap-5 h-full items-center">
                <JustDonut
                  title="Not Assessed"
                  colors={["#E8001D", "#148842"]}
                  labels={["Not Assessed", "Total"]}
                  data={[
                    data?.vendor_status?.not_assigned || 0,
                    data?.vendor_status?.total || 0,
                  ]}
                  centerText={`${data?.vendor_status?.not_assigned || 0}/${
                    data?.vendor_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/vendor")}
                />
                <JustDonut
                  title="In-Progress"
                  colors={["#E8001D", "#148842"]}
                  labels={["In-Progress", "Total"]}
                  data={[
                    data?.vendor_status?.in_progress || 0,
                    data?.vendor_status?.total || 0,
                  ]}
                  centerText={`${data?.vendor_status?.in_progress || 0}/${
                    data?.vendor_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/vendor")}
                />
                <JustDonut
                  title="Assessed"
                  colors={["#E8001D", "#148842"]}
                  labels={["Assessed", "Total"]}
                  data={[
                    data?.vendor_status?.assigned || 0,
                    data?.vendor_status?.total || 0,
                  ]}
                  centerText={`${data?.vendor_status?.assigned || 0}/${
                    data?.vendor_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/vendor")}
                />
              </div>
            </div>
          </div>
          <div
            className={`col-span-2 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <Header
                title="Policies"
                tooltip="Overview of policy management and compliance."
              />
              <div className="grid grid-cols-5 gap-5 h-full items-center">
                <JustDonut
                  title="Not Uploaded"
                  colors={["#E8001D", "#148842"]}
                  labels={["Not Uploaded", "Total"]}
                  data={[
                    data?.policy_status?.not_uploaded || 0,
                    data?.policy_status?.total || 0,
                  ]}
                  centerText={`${data?.policy_status?.not_uploaded || 0}/${
                    data?.policy_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/policy")}
                />
                <JustDonut
                  title="Draft"
                  colors={["#E8001D", "#148842"]}
                  labels={["Draft", "Total"]}
                  data={[
                    data?.policy_status?.draft || 0,
                    data?.policy_status?.total || 0,
                  ]}
                  centerText={`${data?.policy_status?.draft || 0}/${
                    data?.policy_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/policy")}
                />
                <JustDonut
                  title="Approved"
                  colors={["#E8001D", "#148842"]}
                  labels={["Approved", "Total"]}
                  data={[
                    data?.policy_status?.approved || 0,
                    data?.policy_status?.total || 0,
                  ]}
                  centerText={`${data?.policy_status?.approved || 0}/${
                    data?.policy_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/policy")}
                />
                <JustDonut
                  title="Needs Review"
                  colors={["#E8001D", "#148842"]}
                  labels={["Needs Review", "Total"]}
                  data={[
                    data?.policy_status?.need_review || 0,
                    data?.policy_status?.total || 0,
                  ]}
                  centerText={`${data?.policy_status?.need_review || 0}/${
                    data?.policy_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/policy")}
                />
                <JustDonut
                  title="Published"
                  colors={["#E8001D", "#148842"]}
                  labels={["Published", "Total"]}
                  data={[
                    data?.policy_status?.published || 0,
                    data?.policy_status?.total || 0,
                  ]}
                  centerText={`${data?.policy_status?.published || 0}/${
                    data?.policy_status?.total || 0
                  }`}
                  onClick={() => navigate("/compliance/policy")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-6">
          <div className="flex flex-col gap-7">
            <div
              className={`${
                isDarkMode ? "header-dark" : "bg-white"
              } w-full rounded-2xl`}
            >
              <Listing
                title="Evidence"
                tooltip="Overview of evidence collection processes."
                data={[
                  {
                    key: "Not Uploaded",
                    value: data?.evidence_status?.not_uploaded || 0,
                    onClick: () => navigate("/compliance/evidence"),
                  },
                  {
                    key: "Draft",
                    value: data?.evidence_status?.draft || 0,
                    onClick: () => navigate("/compliance/evidence"),
                  },
                  // {
                  //   key: "Attention Required",
                  //   value: data?.evidence_status?.need_attention || 0,
                  //   onClick: () => navigate("/compliance/evidence"),
                  // },
                  // {
                  //   key: "Uploaded",
                  //   value: data?.evidence_status?.uploaded || 0,
                  //   onClick: () => navigate("/compliance/evidence"),
                  // },
                  {
                    key: "Published",
                    value: data?.evidence_status?.published || 0,
                    onClick: () => navigate("/compliance/evidence"),
                  },
                ]}
              />
            </div>
            <div
              className={`${
                isDarkMode ? "header-dark" : "bg-white"
              } w-full h-full rounded-2xl`}
            >
              <Listing
                title="Gap Assessment"
                tooltip="Overview of Gap Assessment status."
                data={[
                  {
                    key: "Open",
                    value: data?.gap_assessment?.open || 0,
                    onClick: () => navigate("/compliance/audit"),
                  },
                  {
                    key: "Closed",
                    value: data?.gap_assessment?.closed || 0,
                    onClick: () => navigate("/compliance/audit"),
                  },
                ]}
              />
            </div>
          </div>
          <div
            className={`${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <JustDonutListing
              title="Employee Technical's Overview"
              tooltip="Overview of employee endpoint security."
              data={[
                {
                  key: "Agents Installed",
                  failure: 0,
                  success: data?.agent_collector?.total || 0,
                },
                {
                  key: "AV Status",
                  failure: data?.agent_collector?.av_info?.failure || 0,
                  success: data?.agent_collector?.av_info?.success || 0,
                },
                {
                  key: "Bitlocker Encryption",
                  failure: 0,
                  success: 0,
                },
                {
                  key: "Screenlock Status",
                  failure: data?.agent_collector?.sl_info?.failure || 0,
                  success: data?.agent_collector?.sl_info?.success || 0,
                },
              ]}
            />
          </div>
        </div>
        <div
          className={`col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Cloud Security Assessment"
              tooltip="Overview of the security status of top compliance standards such as ISO 27001, SOC 2, PCI DSS, GDPR, and HIPAA."
            />
            <div className="grid grid-cols-5 gap-5 h-full items-center">
              <PassFailDonut
                title="ISO 27001"
                data={data?.compliance?.ISO27001_2013}
                path="/compliance-cloud/ISO27001_2013"
                state={{
                  title: "ISO 27001",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "ISO27001-2013",
                  },
                }}
              />
              <PassFailDonut
                title="SOC 2"
                data={data?.compliance?.SOC2}
                path="/compliance-cloud/SOC2"
                state={{
                  title: "SOC 2",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "SOC2",
                  },
                }}
              />
              <PassFailDonut
                title="PCI DSS"
                data={data?.compliance?.PCI_3_2_1}
                path="/compliance-cloud/PCI-3.2.1"
                state={{
                  title: "PCI DSS",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "PCI-3.2.1",
                  },
                }}
              />
              <PassFailDonut
                title="GDPR"
                data={data?.compliance?.GDPR}
                path="/compliance-cloud/GDPR"
                state={{
                  title: "GDPR",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "GDPR",
                  },
                }}
              />
              <PassFailDonut
                title="HIPAA"
                data={data?.compliance?.HIPAA}
                path="/compliance-cloud/HIPAA"
                state={{
                  title: "HIPAA",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "HIPAA",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-6">
          <div
            className={`${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <Listing
              title="Trust Center"
              tooltip="Overview of Trust Center portal analytics."
              data={[
                {
                  key: "Portal Views",
                  value: data?.trust_center?.log.view || 0,
                  onClick: () => navigate("/compliance/trust-center"),
                },
                {
                  key: "Downloaded Documents",
                  value: data?.trust_center?.log.download || 0,
                  onClick: () => navigate("/compliance/trust-center"),
                },
                {
                  key: "Access Granted",
                  value: data?.trust_center?.access?.active || 0,
                  onClick: () => navigate("/compliance/trust-center"),
                },
              ]}
            />
          </div>
          <div
            className={`lg:col-span-1 sm:col-span-2 ${
              isDarkMode ? "header-dark" : "bg-white"
            } w-full rounded-2xl`}
          >
            <div className="px-3 flex flex-col h-full">
              <Header
                title="Security Awareness Campaign"
                tooltip="Security Awareness Campaign"
              />
              <div className="grid grid-cols-2 gap-5 h-full items-center">
                <JustDonut
                  title="Campaigns Status"
                  colors={["#E8001D", "#148842"]}
                  labels={["Completed", "Total"]}
                  data={[
                    data?.campaigns?.completed || 0,
                    data?.campaigns?.total || 0,
                  ]}
                  centerText={`${data?.campaigns?.completed || 0}/${
                    data?.campaigns?.total || 0
                  }`}
                />
                <JustDonut
                  title="Quiz Summary"
                  colors={["#E8001D", "#148842"]}
                  labels={["Completed", "Total"]}
                  data={[
                    data?.quizzes?.completed || 0,
                    data?.quizzes?.total || 0,
                  ]}
                  centerText={`${data?.quizzes?.completed || 0}/${
                    data?.quizzes?.total || 0
                  }`}
                />
                {/* <PassFailDonut
                  title="Campaigns Status"
                  data={{
                    failure: data?.campaigns?.failure || 0,
                    success: data?.campaigns?.success || 0,
                  }}
                />
                <PassFailDonut
                  title="Quiz Summary"
                  data={{
                    failure: data?.quizzes?.failure || 0,
                    success: data?.quizzes?.success || 0,
                  }}
                /> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <div className="px-3 flex flex-col h-full">
            <Header
              title="Cloud Security Assessment - Severity"
              tooltip="Classification of cloud security vulnerabilities into distinct categories for clear risk assessment and effective prioritization."
            />
            <div className="grid grid-cols-5 gap-5 h-full items-center">
              <SeverityDonut
                title="ISO 27001"
                data={data?.compliance?.ISO27001_2013}
                path="/compliance-cloud/ISO27001_2013"
                state={{
                  title: "ISO 27001",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "ISO27001-2013",
                  },
                }}
              />
              <SeverityDonut
                title="SOC 2"
                data={data?.compliance?.SOC2}
                path="/compliance-cloud/SOC2"
                state={{
                  title: "SOC 2",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "SOC2",
                  },
                }}
              />
              <SeverityDonut
                title="PCI DSS"
                data={data?.compliance?.PCI_3_2_1}
                path="/compliance-cloud/PCI-3.2.1"
                state={{
                  title: "PCI DSS",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "PCI-3.2.1",
                  },
                }}
              />
              <SeverityDonut
                title="GDPR"
                data={data?.compliance?.GDPR}
                path="/compliance-cloud/GDPR"
                state={{
                  title: "GDPR",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "GDPR",
                  },
                }}
              />
              <SeverityDonut
                title="HIPAA"
                data={data?.compliance?.HIPAA}
                path="/compliance-cloud/HIPAA"
                state={{
                  title: "HIPAA",
                  filter: {
                    scan: data?.last_scan,
                    compliance__framework: "HIPAA",
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`col-span-1 ${
            isDarkMode ? "header-dark" : "bg-white"
          } w-full rounded-2xl`}
        >
          <AreaChart
            title="Critical Issues"
            data={[]}
            colors={["#F14D34", "#FEEDEB"]}
            categories={[0, 0, 0, 0]}
            value={
              Math.round(
                (data?.compliance?.SOC2?.critical /
                  (data?.compliance?.SOC2?.success +
                    data?.compliance?.SOC2?.failure +
                    data?.compliance?.SOC2?.unknown)) *
                  100,
                2
              ) || 0
            }
            tooltip="Overview of the total number of critical issues identified."
          />
        </div>
      </div>
    </>
  );
}
