import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ContextWrapper from "./context";
import { store } from "./redux-toolkit/store/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import NoInternetConnection from "./NoInternetConnection";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NoInternetConnection>
    <Provider store={store}>
      <ContextWrapper>
        {/* <BrowserRouter> */}
        <App />
        <ToastContainer />
        {/* </BrowserRouter> */}
      </ContextWrapper>
    </Provider>
  </NoInternetConnection>
);
reportWebVitals();
