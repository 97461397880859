import ComplianceTable from "../complianceTable";
import AddFindings, { NATURE_OF_FINDINGS, STATUS } from "./AddFindings";

export default function Findings({ data }) {
  const columns = [
    {
      field: "name",
      header: "Request Name",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: STATUS,
    },
    {
      field: "assignee",
      header: "Assignee",
      type: "select",
      value: data?.employees.map((emp) => ({
        label: emp.email,
        value: emp.id,
      })),
    },
    {
      field: "nature",
      header: "Nature of Finding",
      type: "select",
      value: NATURE_OF_FINDINGS,
    },
    {
      field: "user",
      header: "Created By",
      type: "select",
      value: data?.employees.map((emp) => ({
        label: emp.email,
        value: emp.id,
      })),
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Findings"
      columns={columns}
      url_path="/finding/"
      AddComponent={AddFindings}
      filter={data?.filters}
    />
  );
}
