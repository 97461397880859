import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../../context/ThemeContext";
import CloudSurfaceAWSTable from "../../../home/components/tables/AttackSurface/CloudSurfaceAWSTable";
import LiveHosts from "../../../home/components/tables/AttackSurface/LiveHosts";
import SSLExpiredTable from "../../../home/components/tables/AttackSurface/SSLExpiredTable";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { SSLFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";
import { DateTimeToDate } from "../../../../helpers";

const SSLExpired = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails)
  const scanID = userDetails.scan_id

  const [sslExpiredTableData, setSSLExpiredTableData] = useState([]);
  const [sslExpiredFilterData, setSSLExpiredFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({scanID: scanID})
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const { title } = props;

  const getSSLExpiredDataTable = (id) => {
    setLoadingTable(true);
    // DashboardApi.dashboardSSLExpiredTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setSSLExpiredTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    SSLFilterAPI.post({...filterParams, ssl_status:'Expired'})
    .then(res=> {
      setSSLExpiredTableData(res.data.map(item => {
        return {
          ...item, 
          expiry_date: DateTimeToDate(item.expiry_date)
        }
      }));
        setCountData(res.data_len);
        setLoadingTable(false);
    })
    .catch(err => console.log(err))
  };

  const getTableFilterData = () => {
    SSLFilterAPI.get({scanID: scanID, ssl_status:'Expired'})
    .then(res => {
      setSSLExpiredFitlerData(res.data)
      setFilterParams({...filterParams, ...res.data})
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getTableFilterData()
  }, []);

  useEffect(()=>{
    if (Object.keys(filterParams).length > 1) getSSLExpiredDataTable()
  },[filterParams])

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <SSLExpiredTable 
          loadingTable={loadingTable} 
          sslExpiredTableData={sslExpiredTableData}
          sslExpiredFilterData={sslExpiredFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default SSLExpired;
