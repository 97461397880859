import baseAPI, { baseUrl } from "../Api/Repository";

export default function fileUpload(path, file, key = "file") {
  return new Promise((resolve, reject) => {
    if (!file) return;
    let formData = new FormData();
    formData.append(key, file);
    baseAPI
      .post(`${baseUrl}${path}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
