import axios from "axios";
import { Sidebar } from "primereact/sidebar";
import { GrDocument } from "react-icons/gr";

import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function ViewAcceptance({ currentDetails, onHide }) {
  const handleDownload = (file) => {
    axios
      .get(file.file, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: res.headers.contentType,
        });
        const url = URL.createObjectURL(blob);

        // Create a link and trigger a click event to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = file.name; // Change file extension to .yml
        a.click();

        // Release the URL object
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err));
  };

  const acceptPolicy = () => {
    complianceAPI
      .post(`/employee-campaign/`, {
        policy: currentDetails.id,
      })
      .then((res) => {
        onHide();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={onHide}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl text-center">{currentDetails?.name}</p>
      <div className="flex flex-col pt-6 gap-2">
        {currentDetails?.attachments_info?.map((a, i) => (
          <div
            key={i}
            className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border cursor-pointer"
            onClick={() => handleDownload(a)}
          >
            <div className="flex items-center">
              <GrDocument />
              <div className="p-1 ml-2 flex flex-col">
                <p className="text-lg text-color font-normal leading-5">
                  {a.name}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-col justify-between">
        {currentDetails?.urls?.map((c, i) => (
          <a
            href={c}
            target="_blank"
            rel="noreferrer"
            className="text-blue-700"
          >
            <div
              key={i}
              className="flex gap-2 my-1 items-center justify-between p-2 border rounded"
            >
              {c}
            </div>
          </a>
        ))}
      </div>
      <div className="flex w-full justify-end">
        <div className="flex w-1/2 mt-9 gap-2">
          <button
            className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={onHide}
          >
            Back
          </button>
          <button
            className={`p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full text-[#ffff] ${
              currentDetails?.is_accepted ? "bg-[#AAAAAA]" : "bg-[#181818]"
            } `}
            onClick={acceptPolicy}
            disabled={currentDetails?.is_accepted}
            type="submit"
          >
            {currentDetails?.is_accepted ? "Accepted" : "Accept"}
          </button>
        </div>
      </div>
    </Sidebar>
  );
}
