import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import { MultiSelect } from "primereact/multiselect";

export const STATUS = [
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Assessed",
    value: "assessed",
  },
  {
    label: "Treated",
    value: "treated",
  },
  {
    label: "Closed",
    value: "closed",
  },
];
export const category = [
  {
    label: "Customer",
    value: "customer",
  },
  {
    label: "Governance",
    value: "governance",
  },
  {
    label: "Operations",
    value: "operations",
  },
  {
    label: "Peoples",
    value: "peoples",
  },
  {
    label: "Regulatory",
    value: "regulatory",
  },
  {
    label: "Reporting",
    value: "reporting",
  },
  {
    label: "Resilience",
    value: "resilience",
  },
  {
    label: "Technology",
    value: "technology",
  },
  {
    label: "Vendor Management",
    value: "vendor_management",
  },
  {
    label: "Other",
    value: "other",
  },
];

const RISKS = [
  "Risk of induction of unethical resources, leading to potential industrial espionage.",
  "Sharing of employee salary and personal details with competitors by current employees or hired consultants.",
  "Unauthorized logical access to emails and internal networks post-separation.",
  "Failure to return company assets, such as laptops.",
  "Unauthorized copying of sensitive information during separation.",
  "Use of generic user IDs leading to accountability issues.",
  "Lack of awareness regarding information security requirements among employees.",
  "Unauthorized access (hacking) to the internal network.",
  "Unauthorized access to internal IT infrastructure and production servers via remote connections.",
  "Usage of external storage devices like USBs that pose security risks.",
  "Visits to malicious websites.",
  "Installation of pirated or unauthorized software.",
  "Unauthorized access to colleagues' systems.",
  "Failure to update installed software.",
  "Risk of malware attacks.",
  "Removal of antivirus software by end users.",
  "Removal of antivirus software from Active Directory by IT team members.",
  "Receipt of spam emails containing viruses in executable files.",
  "Insecure disposal of hardware such as HDDs, tapes, USBs, laptops, and desktops.",
  "Insecure disposal of critical and sensitive business information stored on these devices.",
  "Inadequate control or unauthorized changes to information systems.",
  "Inability of customers to access production servers due to high resource utilization.",
  "Inability to access the internal network due to high resource (CPU/memory) usage.",
  "Failure of supporting utilities leading to an inability to access the internal network.",
  "Unauthorized physical access to company premises.",
  "Insecure electronic transfer of information (e.g., email) with external parties.",
  "Disruption of internet services at critical locations.",
  "Failure of primary domain controllers.",
  "Failure of network devices such as firewalls and routers.",
  "Power failures affecting information processing facilities.",
  "Riots or pandemics rendering office sites inaccessible.",
  "Natural calamities (e.g., earthquakes, floods) destroying ICT equipment and office facilities.",
  "Major fires destroying ICT equipment and office facilities, impacting main data storage.",
  "Employees downloading data to local systems while using Citrix, leading to confidentiality breaches.",
  "Exploitation of known vulnerabilities in systems (network and application), leading to potential breaches of confidentiality, integrity, and availability (CIA).",
  "FTP and HTTP services enabled on firewalls, sending data in plain text, leading to CIA breaches.",
  "Lack of automated alerts from the PRTG network monitoring tool.",
  "Applications' databases not being encrypted and absence of SSL for certain applications, leading to confidentiality breaches.",
  "Failure to patch Linux servers.",
  "Critical network components not configured to use multi-factor authentication.",
  "Laptop hard drives not encrypted, leading to confidentiality breaches if lost or stolen.",
];

export default function AddRisk({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [employee, setEmployee] = useState([]);
  const [department, setDepartment] = useState([]);
  const [controls, setControls] = useState([]);
  const [entities, setEntities] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/risk/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Risk updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/risk/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Risk created successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getControls = () => {
    complianceAPI
      .post("/controls/filter/")
      .then(({ data }) => {
        setControls(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getDepartment();
    getEntities();
    getControls();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Risk`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Risk`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          {/* <div className="w-full">
            <label className="block text-gray-500 text-sm">
              Potential Risk
            </label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Potential Risk"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div> */}
          <div className="w-full ">
            <label className="block text-gray-500 text-sm">
              Potential Risk
            </label>
            <Dropdown
              filter
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.value })}
              options={RISKS.map((item) => ({ label: item, id: item }))}
              itemTemplate={(option) => {
                return (
                  <p className="w-80	truncate" title={option.label}>
                    {option.label}
                  </p>
                );
              }}
              editable
              optionLabel="label"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Description</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Description"
              value={data.description}
              onChange={(e) => {
                setData({ ...data, description: e.target.value });
                setError({ ...error, description: false });
              }}
            />
            {error.description && (
              <p className="text-red-500 text-sm">{error.description}</p>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Entities</label>
            <Dropdown
              filter
              value={data.entities}
              onChange={(e) => setData({ ...data, entities: e.value })}
              options={entities}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Assignee</label>
            <Dropdown
              filter
              value={data.assignee}
              onChange={(e) => setData({ ...data, assignee: e.value })}
              options={employee}
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Category</label>
            <Dropdown
              value={data.category}
              onChange={(e) => setData({ ...data, category: e.value })}
              options={category}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Department</label>
            <Dropdown
              filter
              value={data.department}
              onChange={(e) => setData({ ...data, department: e.value })}
              options={department}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">
              Application Name
            </label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Application Name"
              value={data.application_name}
              onChange={(e) => {
                setData({ ...data, application_name: e.target.value });
                setError({ ...error, application_name: false });
              }}
            />
            {error.application_name && (
              <p className="text-red-500 text-sm">{error.application_name}</p>
            )}
          </div>
          <div className="mt-3">
            <label className="block text-gray-500 text-sm">Controls</label>
            <MultiSelect
              className="w-full"
              optionLabel="name"
              optionValue="id"
              filter
              value={data.controls}
              options={controls}
              placeholder="Select Controls"
              onChange={(e) => setData({ ...data, controls: e.value })}
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Status</label>
            <Dropdown
              value={data.status}
              onChange={(e) => setData({ ...data, status: e.value })}
              options={STATUS}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Source</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Source"
              value={data?.source}
              onChange={(e) => {
                setData({ ...data, source: e.target.value });
                setError({ ...error, source: false });
              }}
            />
            {error.source && (
              <p className="text-red-500 text-sm">{error.source}</p>
            )}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Source type</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Source type"
              value={data?.source_type}
              onChange={(e) => {
                setData({ ...data, source_type: e.target.value });
                setError({ ...error, source_type: false });
              }}
            />
            {error.source_type && (
              <p className="text-red-500 text-sm">{error.source_type}</p>
            )}
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
