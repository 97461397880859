import { Accordion, AccordionTab } from "primereact/accordion";
import { DateTimeToDate, SeverityLevelLabel } from "../../../helpers";
import AnalysisView from "../../../common/Tables/AnalysisView";

const TableSidebarFilter = (props) => {
  const { columns, setFilterParams, filterParams } = props;

  const handleItemCheckboxChange = (header, item) => {
    let currentArray = filterParams[header] || [];
    let newArray = [];

    if (currentArray.includes(item))
      newArray = currentArray.filter((i) => i != item);
    else newArray = [...currentArray, item];

    if (newArray.length) {
      setFilterParams({ ...filterParams, [header]: newArray });
    } else {
      setFilterParams({ ...filterParams, [header]: [] });
    }
  };

  const handleSelectAll = (header, allValues) => {
    if (
      filterParams[header] &&
      allValues?.every((value) => filterParams[header].includes(value))
    ) {
      setFilterParams({ ...filterParams, [header]: [] });
    } else {
      setFilterParams({ ...filterParams, [header]: allValues });
    }
  };

  return (
    <div className="card table-filter-style">
      {columns
        .filter((item) => item?.value)
        .map((header, i) => (
          <Accordion key={i} activeIndex={i}>
            <AccordionTab header={header.header}>
              <div className="flex items-center gap-2 pt-2">
                <input
                  type="checkbox"
                  className="h-4 w-4"
                  checked={
                    filterParams[header.field] !== undefined &&
                    header.value &&
                    header.value.every &&
                    header.value.every((value) =>
                      filterParams[header.field].includes(
                        header.type === "select" ||
                          header?.type === "multi_select"
                          ? value.value
                          : value
                      )
                    )
                  }
                  onChange={() =>
                    handleSelectAll(
                      header.field,
                      header.type === "select" ||
                        header?.type === "multi_select"
                        ? header.value.map((i) => i.value)
                        : header.value
                    )
                  }
                />
                <label className={`text-base font-normal text-color `}>
                  Select All
                </label>
              </div>
              <p className="m-0">
                {header.value?.map &&
                  header.value.map((item, j) => (
                    <div className="flex items-center gap-2 pt-2" key={j}>
                      <input
                        type="checkbox"
                        className="h-4 w-4"
                        checked={
                          filterParams[header.field]?.includes(
                            header.type === "select" ||
                              header?.type === "multi_select"
                              ? item.value
                              : item
                          )
                            ? true
                            : false
                        }
                        onChange={() =>
                          handleItemCheckboxChange(
                            header.field,
                            header.type === "select" ||
                              header?.type === "multi_select"
                              ? item.value
                              : item
                          )
                        }
                      />
                      <label className="text-base font-normal text-color break-all">
                        {header?.type === "date"
                          ? DateTimeToDate(item)
                          : header?.type === "severity"
                          ? SeverityLevelLabel(item)
                          : header?.type === "select" ||
                            header?.type === "multi_select"
                          ? item.label
                          : header?.type === "AnalysisView"
                          ? AnalysisView(item)
                          : item}
                      </label>
                    </div>
                  ))}
              </p>
            </AccordionTab>
          </Accordion>
        ))}
    </div>
  );
};

export default TableSidebarFilter;
