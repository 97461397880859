import ComplianceTable from "../../modules/home/components/compliance/complianceTable";
import AddAudit from "../../modules/home/components/compliance/audit/AddAudit";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";

export default function Audit() {
  const navigate = useNavigate();
  const framework = useSelector(getUserDetails)?.framework;

  const columns = [
    { field: "name", header: "Audit Name" },
    {
      field: "audit_date",
      header: "Audit Date",
      type: "date",
    },
    {
      field: "observation_period",
      header: "Observation Period",
      type: "date",
    },
    {
      field: "audit_by",
      header: "Audit By",
      type: "select",
      value: [
        {
          label: "Control",
          value: "control",
        },
        {
          label: "Framework",
          value: "framework",
        },
      ],
    },
    {
      field: "start_date",
      header: "Start Date",
      type: "date",
    },
    {
      field: "end_date",
      header: "End Date",
      type: "date",
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: [
        {
          label: "Open",
          value: "open",
        },
        {
          label: "Closed",
          value: "closed",
        },
      ],
    },
    {
      field: "action",
      header: "Action",
      type: "action",
      admins: true,
    },
  ];

  return (
    <ComplianceTable
      title="Audit"
      columns={columns}
      url_path="/audit/"
      AddComponent={AddAudit}
      filter={{ framework }}
      onRowClick={(data) => {
        navigate(`./${data.id}`);
      }}
    />
  );
}
