export function handleSubmitButtonPress(e) {
  e.preventDefault();
  e.target.submit.setAttribute("disabled", "disabled");
  e.target.submit.className = e.target.submit.className + " cursor-wait";
}

export function handleSubmitButtonRelease(e) {
  e.target.submit.removeAttribute("disabled");
  e.target.submit.className = e.target.submit.className.replace(
    " cursor-wait",
    ""
  );
}
