import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import OtpInputComponent from "../../../Component/otp/OtpInputComponent";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { toast } from "react-toastify";

const MultifactorScanVerify = (props) => {
  const {
    setVisibleRight,
    authInstruction,
    setAuthInstruction,
    setEmailVerify,
  } = props;

  const [authScan, setAuthScan] = useState(false);
  const [otp, setOtp] = useState(null);
  // const [emailVerify, setEmailVerify] = useState(false)

  const vpwidth = document.documentElement.clientWidth;

  const handleContinue = () => {
    if (!otp) {
      setAuthScan(true);
    } else {
      TableApi.verifyMFA({ otp })
        .then((res) => {
          if (res.status === "success") {
            toast.success(res.message);
            setVisibleRight(false);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            toast.error(res.message);
          }
        })
        .catch((res) => console.log(res));
    }
  };

  return (
    <>
      <div className="py-5 w-full">
        {!authScan && (
          <div>
            <p className="text-base text-color">
              1. Install <span className="font-bold">Authenticator</span> App on
              your mobile device
            </p>
            <p className="text-base text-color">
              2. Set up your account through add new account flow
            </p>
            <p className="text-base text-color">3. Scan QR-code</p>
            <div className="flex pt-5 w-full justify-center">
              <QRCode value={authInstruction} />
            </div>
          </div>
        )}
        {authScan && (
          <div>
            <p className="text-base text-color">
              Approve the notification we’re sending to your app by entering the
              number show below
            </p>
            <div className="my-5">
              <OtpInputComponent
                numInputs={6}
                inputStyle={{
                  fontSize: "1.5rem",
                  borderRadius: "4px",
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: `${vpwidth > 667 ? "3rem" : "2rem"}`,
                  width: `${vpwidth > 667 ? "3rem" : "2rem"}`,
                  marginRight: "8px",
                  lineHeight: "2rem",
                }}
                otp={otp}
                setOtp={setOtp}
              />
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full">
        <button
          className="p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
          onClick={() => handleContinue()}
        >
          Continue
        </button>
        <button
          className="ml-2 p-1 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full"
          onClick={() => setAuthInstruction(false)}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default MultifactorScanVerify;
