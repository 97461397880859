import React, {useState,useContext, useEffect} from 'react'
import { ThemeContext } from '../../../../context/ThemeContext';
import DNSMonitorTable from '../../../home/components/tables/AttackSurface/DNSMonitorTable';
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import DashboardApi from '../../../../Api/AuthenticationApi/DashboardApi';
import { WhoisFilterAPI } from '../../../../Api/FIlterAPI/tableFilterApi';
import { DateTimeToDate } from '../../../../helpers';
import WhoisTable from '../../../home/components/tables/AttackSurface/WhoisTable';

const Whois = (props) => {
    const { isDarkMode } = useContext(ThemeContext);
    const userDetails = useSelector(getUserDetails)
    const scanID = userDetails.scan_id

    const { title } = props;
    const [countData, setCountData] = useState();
    const [loadingTable, setLoadingTable] = useState(true);
    const [dnsTableData, setDNSTableData] = useState([]);
    const [dnsFilterData, setDnsFitlerData] = useState([]);
    const [filterParams, setFilterParams] = useState({scanID: scanID})

    const getDNSDataTable = ()=>{
      setLoadingTable(true)
      // DashboardApi.getDNSApi(id).then((data)=>{
      // if(data.status === "success"){
      //   setDNSTableData(data.data)
      //   setCountData(data.count)
      //   setLoadingTable(false)
      // }
      // })
      WhoisFilterAPI.post(filterParams)
      .then(res=> {
        setDNSTableData(res.data.map(item => {
          return {
            ...item, 
            date_added: DateTimeToDate(item.date_added)
          }
        }));
          setCountData(res.data_len);
          setLoadingTable(false);
      })
      .catch(err => console.log(err))
    };
  
    const getTableFilterData = () => {
      WhoisFilterAPI.get({scanID: scanID})
      .then(res => {
        setDnsFitlerData(res.data)
        setFilterParams({...filterParams, ...res.data})
      })
      .catch(err => console.log(err))
    }
  
    useEffect(() => {
      getTableFilterData()
    }, []);
  
    useEffect(()=>{
      if (Object.keys(filterParams).length > 1) getDNSDataTable()
    },[filterParams])
  
  return (
   <>
   <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
    <WhoisTable
      loadingTable={loadingTable} 
      dnsTableData={dnsTableData}
      dnsFilterData={dnsFilterData}
      setFilterParams={setFilterParams}
      filterParams={filterParams}
      />
      
      </div>
   </>
  )
}

export default Whois