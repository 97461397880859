import { useState, useContext, useEffect, useRef } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";
import { Sidebar } from "primereact/sidebar";

import TableFilter from "../../../../common/Tables/TableFilter";
import SkeletonDataTable from "../../../SkeletonAnimation/SkeletonDataTable";
import { dataBreachAPI } from "../../../../Api/DRP/base";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import ListWithReadMore from "../../../sidebar/components/drp/ListWithReadMore";
import paginationTemplate from "../../../../utils/paginationTemplate";

export default function CompromisedFiles() {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  // const domain = userDetails?.drp_domain;
  // const created_at = userDetails?.drp_date;
  const scan = userDetails?.drp_scan_id;
  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [filterParams, setFilterParams] = useState({ scan });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const getFilterData = () => {
    dataBreachAPI
      .get("compromised-file/", { scan })
      .then((res) => {
        setFilterData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    dataBreachAPI
      .post("compromised-file/", { ...filterParams, scan })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const columns = [
    { field: "hwid", header: "System ID", value: filterData?.hwid },
    { field: "ip", header: "IP Address", value: filterData?.ip },
    { field: "filenames", header: "File name" },
    {
      field: "computer_name",
      header: "Computer Name",
      value: filterData?.computer_name,
    },
    { field: "date_compromised", header: "Date Compromissed" },
  ];

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (Object.keys(filterParams).length > 1) getData();
  }, [filterParams]);

  return (
    <div className="ssl-active">
      <Sidebar
        visible={currentDetails}
        position="right"
        onHide={() => setCurrentDetails(null)}
        className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
        closeOnEscape={false}
        dismissable={false}
      >
        <p className="font-bold text-2xl text-center">Compromised Files</p>
        <div className="px-6">
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">System ID</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.hwid}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">IP Address</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.ip}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">URL</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.keyword}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Date Compromised</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.date_compromised}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Computer Name</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.computer_name}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Date Added</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.network}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Malware Path</h1>
              <p className="text-xs text-wrap text-color font-normal leading-5">
                {currentDetails?.malware_path}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Malware Family</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.malware_family}
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Operating System</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.operating_system}
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Antivirus</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.antiviruses.map((data) => (
                  <li className="text-xs text-color font-normal leading-5">
                    {data}
                  </li>
                ))} */}
                <ListWithReadMore data={currentDetails?.antiviruses} />
              </p>
            </div>
          </div>
          <div className="flex justify-between pt-6">
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">
                Installed Software
              </h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.installed_softwares.map((data) => (
                  <li className="text-xs text-color font-normal leading-5">
                    {data}
                  </li>
                ))} */}
                <ListWithReadMore data={currentDetails?.installed_softwares} />
              </p>
            </div>
            <div className="w-1/2">
              <h1 className="text-sm text-color font-bold">Files</h1>
              <p className="text-xs text-color font-normal leading-5">
                {/* {currentDetails?.filenames.map((data) => (
                  <li className="text-xs text-color font-normal leading-5">
                    {data}
                  </li>
                ))} */}
                <ListWithReadMore data={currentDetails?.filenames} />
              </p>
            </div>
          </div>
        </div>
      </Sidebar>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            Compromised Files <span> ({data.length}) </span>
          </p>
        </div>
        <div>
          <TableFilter
            columns={columns}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            dt={ref}
            tableData={data}
          />
          {loading ? (
            <SkeletonDataTable columns={columns} />
          ) : (
            <DataTable
              paginator
              paginatorTemplate={paginationTemplate()}
              removableSort
              ref={ref}
              value={data}
              rows={10}
              globalFilter={globalFilter}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) =>
                col.field === "filenames" ? (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                    body={(rowData) => {
                      return (
                        <ListWithReadMore
                          data={rowData.filenames}
                          color="white"
                        />
                      );
                    }}
                  />
                ) : (
                  <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                  />
                )
              )}
              <Column
                header="Full info"
                body={(rowData) => {
                  return (
                    <button
                      onClick={() => {
                        setCurrentDetails(rowData);
                      }}
                    >
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  );
                }}
                exportable={false}
              />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
}
