import { Sidebar } from "primereact/sidebar";
import { useContext, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";

import { ThemeContext } from "../../../../../context/ThemeContext";
import integrationsAPI from "../../../../../Api/integerations/api";
import { complianceAPI } from "../../../../../Api/CSPM/api";

export default function IntegratePeople() {
  const { isDarkMode } = useContext(ThemeContext);

  const [visible, setVisible] = useState(false);
  const [services, setServices] = useState([]);
  const SERVICES = [
    {
      label: "Microsoft Entra",
      value: "entra",
    },
    {
      label: "Zoho People",
      value: "zoho",
    },
    {
      label: "Google workspace",
      value: "googleworkspaceuser",
    },
  ];

  const runService = () => {
    complianceAPI
      .post(`/collect/${services}/`)
      .then((res) => {
        if (res.status === "success") {
          toast.success(
            "Employee collection started in background, will update once completed"
          );
          setVisible(false);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const renderService = () => {
    switch (services) {
      case "entra":
        return (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Employee Data from {services}
            </button>
          </div>
        );
        case "zoho":
        return (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Employee Data from {services}
            </button>
          </div>
        );
        case "googleworkspaceuser":
        return (
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              onClick={runService}
            >
              Collect Employee Data from {services}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <button
        className={`border-[#181818] border-[1px] rounded-[14px] text-[16px] text-[#111] px-3 h-[32px] ${
          isDarkMode ? "border-[#FCFCFC] text-white" : "text-color"
        }`}
        onClick={() => setVisible(true)}
      >
        Integrate
      </button>
      <Sidebar
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
      >
        <p className="font-bold text-2xl">Integrate Employee</p>
        <p className="text-sm py-1">
          Integrate employee data from different sources
        </p>
        <div className="w-full pt-3">
          <label className="block text-gray-500 text-sm">Service</label>
          <Dropdown
            value={services}
            onChange={(e) => setServices(e.value)}
            options={SERVICES}
            optionLabel="label"
            optionValue="value"
            placeholder="Select Service"
            className="w-full md:w-14rem my-1"
          />
        </div>
        {renderService()}
      </Sidebar>
    </>
  );
}
