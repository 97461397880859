import { createElement, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { SplitButton } from "primereact/splitbutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FiMoreVertical } from "react-icons/fi";
import { confirmDialog } from "primereact/confirmdialog";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { DateTimeToDate } from "../../../../../helpers";
import Requirement from "../../../../../screens/compliance/framework/Requirement";
import Requests from "./Requests";
import Findings from "./Findings";
import AddAudit from "./AddAudit";
import { toast } from "react-toastify";

export default function ViewAudit() {
  const { id } = useParams();
  const { isDarkMode } = useContext(ThemeContext);

  const [data, setData] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [frameworks, setFrameworks] = useState([]);
  const [currentTab, setCurrentTab] = useState("findings");
  const [edit, setEdit] = useState(false);

  const TABS = [
    // {
    //   id: "controls",
    //   title: "Controls",
    //   component: () => <>hehe</>,
    // },
    {
      id: "findings",
      title: "Findings",
      component: Findings,
      props: { employees, filters: { audit: id } },
    },
    {
      id: "requests",
      title: "Requests",
      component: Requests,
      props: { employees, filters: { audit: id } },
    },
  ];

  if (data?.audit_by === "framework") {
    // add to index 2
    TABS.push({
      id: "requirements",
      title: "Requirements",
      component: Requirement,
      props: { filters: { framework: data?.framework } },
    });
  }
  const STATUS = {
    in_progress: "In Progress",
    completed: "Completed",
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFrameworks = () => {
    complianceAPI
      .post("/framework/filter/")
      .then((res) => {
        setFrameworks(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    complianceAPI
      .get(`/audit/${id}`)
      .then((res) => {
        setData(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markAsCompleted = () => {
    complianceAPI
      .patch(`/audit/${id}/`, { ...data, status: "completed" })
      .then((res) => {
        getData();
        toast.success("Audit marked as completed.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getData();
    getFrameworks();
  }, []);

  if (!data)
    return (
      <div className="flex justify-center items-center">
        <ProgressSpinner />
      </div>
    );

  return (
    <>
      <h1 className="font-bold mb-2">{data?.name}</h1>
      <div className="grid grid-cols-2 gap-3">
        <div
          className={`rounded-2xl vaultFolder ${
            isDarkMode ? "header-dark" : "header-light white-background"
          }`}
        >
          <div className="flex">
            <p className="flex w-full gap-2 m-5">
              <span className="font-semibold">Owner:</span>{" "}
              {employees.find((emp) => emp.id === data?.user)?.name}
              <span className="font-semibold pl-2">Audit Date:</span>{" "}
              {data?.audit_date}
            </p>
            <div
              className={`flex justify-end ${isDarkMode ? "dark" : "white"}`}
            >
              <SplitButton
                icon={<FiMoreVertical size={18} />}
                className={`p-button-text`}
                model={[
                  {
                    label: "Edit",
                    icon: "pi pi-refresh",
                    command: () => {
                      setEdit(true);
                    },
                  },
                  // {
                  //   label: "Delete",
                  //   icon: "pi pi-plus",
                  //   command: () => {
                  //     confirmDialog({
                  //       header: `Delete ${data?.name}`,
                  //       message: "Are you Sure..?",
                  //       icon: "pi pi-info-circle",
                  //       acceptClassName: "p-button-danger",
                  //       acceptLabel: "Delete",
                  //       rejectLabel: "Cancel",
                  //       accept: () => {
                  //         console.log("Delete");
                  //       },
                  //     });
                  //   },
                  // },
                ]}
              ></SplitButton>
            </div>
          </div>
          <div className="ml-5 mb-5">
            <p>
              <span className="font-semibold">Observation Period: </span>
              {DateTimeToDate(data?.start_date)} -{" "}
              {DateTimeToDate(data?.end_date)}
            </p>
          </div>
        </div>
        <div
          className={`rounded-2xl vaultFolder ${
            isDarkMode ? "header-dark" : "header-light white-background"
          }`}
        >
          <div className="flex">
            <p className="flex w-full gap-2 m-5">
              <span className="font-semibold">Audit Team:</span> Not Assigned
              <span className="font-semibold pl-2">Result:</span>{" "}
              {STATUS[data?.status]}
            </p>
            <div
              className={`flex justify-end ${isDarkMode ? "dark" : "white"}`}
            >
              <SplitButton
                icon={<FiMoreVertical size={18} />}
                className={`p-button-text`}
                model={[
                  {
                    label: "Mark as Completed",
                    command: () => {
                      markAsCompleted();
                    },
                  },
                ]}
              ></SplitButton>
            </div>
          </div>
          {data?.audit_by === "framework" && (
            <div className="ml-5 mb-5">
              <p>
                <span className="font-semibold">Framework:</span>{" "}
                {
                  frameworks?.find(
                    (framework) => framework.id === data?.framework
                  )?.name
                }
              </p>
            </div>
          )}
        </div>
      </div>
      <h1 className="font-bold text-lg mt-3 mx-2">Gap Assessment</h1>
      <div
        className={`flex gap-5 border-b-2 mx-2 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-4 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component, {
          data: TABS.find((tab) => tab.id === currentTab)?.props,
        })}
      </div>
      <ConfirmDialog />
      <AddAudit
        visible={edit}
        onHide={() => {
          setEdit(false);
          getData();
        }}
        currentDetails={data}
      />
    </>
  );
}
