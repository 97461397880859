import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../../context/ThemeContext";
import CloudSurfaceAWSTable from "../../../home/components/tables/AttackSurface/CloudSurfaceAWSTable";
import LiveHosts from "../../../home/components/tables/AttackSurface/LiveHosts";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import ExploitabilityTable from "../../../home/components/tables/AttackSurface/ExploitabilityTable";
import { ExploitFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";

const ExploitAbility = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails)
  const scanID = userDetails.scan_id

  const [cloudAwsTableData, setCloudAwsTableData] = useState([]);
  const [exploitabilityFilterData, setExploitabilityFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({scanID: scanID})
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const { title } = props;

  const getCloudDataTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardExploitsTable(id, "exploitability").then((data) => {
    //   if (data.status === "success") {
    //     setCloudAwsTableData(data.data);
    //     setCountData(data.count);
    //     setLoadingTable(false);
    //   }
    // });
    ExploitFilterAPI.post({...filterParams, exploitability: 'yes'})
    .then(res=> {
      setCloudAwsTableData(res.data);
        setCountData(res.count);
        setLoadingTable(false);
    })
    .catch(err => console.log(err))
  };

  const getTableFilterData = () => {
    ExploitFilterAPI.get({scanID: scanID,  exploitability: 'yes'})
    .then(res => {
      setExploitabilityFitlerData(res.data)
      setFilterParams({...filterParams, ...res.data})
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getTableFilterData()
  }, []);

  useEffect(()=>{
    if (Object.keys(filterParams).length > 1) getCloudDataTable()
  },[filterParams])
  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <ExploitabilityTable
          cloudAwsTableData={cloudAwsTableData}
          loadingTable={loadingTable}
          exploitabilityFilterData={exploitabilityFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default ExploitAbility;
