import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";

import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../../helpers";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";

export const AUDIT_BY = [
  {
    label: "Framework",
    value: "framework",
  },
  {
    label: "Control",
    value: "control",
  },
];

export default function AddAudit({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});

  const [frameworks, setFrameworks] = useState([]);
  const [entities, setEntities] = useState([]);

  const [openAuditDate, setOpenAuditDate] = useState(false);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/audit/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Audit updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/audit/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Audit added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getFrameworks = () => {
    complianceAPI
      .post("/framework/filter/")
      .then((res) => {
        setFrameworks(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFrameworks();
    getEntities();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Add"
      } Audit`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Audit`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Audit name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Entities</label>
            <Dropdown
              value={data.entities}
              onChange={(e) => setData({ ...data, entities: e.value })}
              options={entities}
              filter
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Audit by</label>
            <Dropdown
              value={AUDIT_BY.find((e) => e.value === data.audit_by)?.value}
              onChange={(e) => setData({ ...data, audit_by: e.value })}
              options={AUDIT_BY}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          {data.audit_by === "framework" && (
            <div className="w-full">
              <label className="block text-gray-500 text-sm">Framework</label>
              <Dropdown
                value={data.framework}
                onChange={(e) => setData({ ...data, framework: e.value })}
                filter
                options={frameworks}
                optionLabel="name"
                optionValue="id"
                placeholder="Select"
                className="w-full md:w-14rem my-1"
              />
            </div>
          )}
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Audit Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.audit_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenAuditDate(!openAuditDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openAuditDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data?.audit_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        audit_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenAuditDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                    // minDate={currentDate}
                    // maxDate={scanEndDate}
                  />
                </div>
              </div>
            )}
          </div>
          <hr className="border-[#CECECE] my-4"></hr>
          <p className="font-bold text-xl">Observation Period</p>
          <div className="flex w-full gap-3">
            <div className="w-1/2 pt-3">
              <label className="text-sm font-normal text-[#686868]">
                Start Date
              </label>
              <div className="relative">
                <input
                  value={DateTimeToDate(data.start_date)}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenStartDate(!openStartDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openStartDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(data?.start_date)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          start_date: DateSelectionToDjangoFormat(e.value),
                        });
                        setOpenStartDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      defaultDate={new Date(data?.start_date)}
                      maxDate={new Date(data?.end_date)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="w-1/2 pt-3">
              <label className="text-sm font-normal text-[#686868]">
                End Date
              </label>
              <div className="relative">
                <input
                  value={DateTimeToDate(data.end_date)}
                  placeholder="___ __, ____"
                  className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
                />
                <div
                  className="absolute cursor-pointer top-[12px] right-[12px]"
                  onClick={() => setOpenEndDate(!openEndDate)}
                >
                  <AiOutlineCalendar />
                </div>
              </div>
              {openEndDate && (
                <div className="mx-auto">
                  <div className="">
                    <Calendar
                      value={new Date(data?.start_date)}
                      onChange={(e) => {
                        setData({
                          ...data,
                          end_date: DateSelectionToDjangoFormat(e.value),
                        });
                        setOpenEndDate(false);
                      }}
                      inline
                      style={{ width: "360px" }}
                      minDate={new Date(data?.start_date)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
