import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";

export default function InherentRisk({
  editInherentRisk,
  setEditInherentRisk,
  data,
  setData,
}) {
  const { id } = useParams();

  const options = [
    {
      label: 1,
      value: 1,
    },
    {
      label: 2,
      value: 2,
    },
    {
      label: 3,
      value: 3,
    },
    {
      label: 4,
      value: 4,
    },
    {
      label: 5,
      value: 5,
    },
  ];

  const [editInfo, setEditInfo] = useState({
    likelihood: data?.likelihood,
    impact: data?.impact,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    complianceAPI
      .patch(`/risk/${id}/`, { ...data, ...editInfo })
      .then((res) => {
        if (!res.id) {
          HandleResponseError(res.message);
          e.target.submit.removeAttribute("disabled");
        } else {
          toast.success("Inherent Risk updated successfully");
          setData(res);
          setEditInherentRisk(null);
          e.target.submit.removeAttribute("disabled");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        e.target.submit.removeAttribute("disabled");
      });
  };

  return (
    <Sidebar
      visible={editInherentRisk}
      position="right"
      onHide={() => setEditInherentRisk(null)}
    >
      <p className="font-bold text-2xl">Risk Status</p>
      <p className="font-bold text-1xl">Inherent Risk</p>
      <p className="text-sm py-1">(Inherent Risk = Likelihood * Impact)</p>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Likelihood</label>
          <Dropdown
            value={editInfo.likelihood}
            onChange={(e) => setEditInfo({ ...editInfo, likelihood: e.value })}
            options={options}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Impact</label>
          <Dropdown
            value={editInfo.impact}
            onChange={(e) => setEditInfo({ ...editInfo, impact: e.value })}
            options={options}
            optionLabel="label"
            optionValue="value"
            placeholder="Select"
            className="w-full md:w-14rem my-1"
          />
        </div>
        <div className="w-full">
          <label className="block text-gray-500 text-sm">Loss of CIA</label>
          <input
            className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
            type="text"
            placeholder="Loss of CIA"
            value={data?.loss_of_cia}
            onChange={(e) => {
              setData({ ...data, loss_of_cia: e.target.value });
            }}
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            type="submit"
            id="submit"
          >
            Save
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={() => setEditInherentRisk(null)}
            type="reset"
          >
            Back
          </button>
        </div>
      </form>
    </Sidebar>
  );
}
