import React, { useState } from "react";
import ComplianceFirst from "./ComplianceFirst";
import ComplianceSecond from "./ComplianceSecond";
import ComplianceThird from "./ComplianceThird";
import ComplianceFourth from "./ComplianceFourth";
import ComplianceFifth from "./ComplianceFifth";
import ComplianceSixth from "./ComplianceSixth";

const Compliance = (props) => {
  const { title } = props;
  const hash = window.location.hash.substring(1);

  const TABLES = {
    "IS0-27001": <ComplianceFirst title="ISO 27001:2022" />,
    "PCI-DSS": <ComplianceSecond title="PCI DSS" />,
    "SOC-2": <ComplianceThird title="SOC 2" />,
    "GDPR": <ComplianceFourth title="GDPR" />,
    // NIST: <ComplianceFifth title="NIST 800-53" />,
    "HIPAA": <ComplianceFifth title="HIPAA" />,
    "OWASP": <ComplianceSixth title="OWASP 2021" />,
  };

  return (
    <div className="ssl-status ">
      <h1 className="font-bold">{title}</h1>

      {!hash ? (
        Object.values(TABLES).map((item, index) => (
          <div key={index} className="ssl-active">
            {item}
          </div>
        ))
      ) : (
        <div className="ssl-active">{TABLES[hash]}</div>
      )}
    </div>
  );
};

export default Compliance;
