import Repository, { baseUrl } from "../Repository";
import { removeAuthenticationData } from "../../utils/constants";
import { Navigate } from "react-router-dom";

class DashboardApiFetch {
  dashboardVOCStatus = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/status/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
        if (error.response.status === 500) {
          // removeAuthenticationData();
          <Navigate to="/404" />;
        }
      });
    return reponse;
  };

  dashboardVOCSeverity = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/severity/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardSSLStatus = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/ssl/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardExploitsStatus = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/exploits/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardWebVulnStatus = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/web_vul/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardAverageAgeVocApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/vulnerability/age/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardMeanTimeRemediateApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/dashboard/voc/remediate/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardLiveHostTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/httpx/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardOpenPortsTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/nmap/ports/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardIpAddressTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/nmap/ip/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardSubDomainTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/subdomain/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardEndPointsTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/katana/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardScanHistory = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/scan/scan/history/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getSecureScoreApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/securescore/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getSecureScoreGraphApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/securescoregraph/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getScanReport = (id, params = null) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/report/${id}`,
      { responseType: "blob", params }
    )
      .then((data) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getScanDateApi = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/web/asm/reports/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardExploitsTable = (id, type) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/exploits/${type}/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardCloudAwsTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/cloud/cloudscan/aws/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardCloudAzureTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/cloud/cloudscan/azure/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardDigitalOceanTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/cloud/cloudscan/digitalocean/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardGoogleCloudTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/cloud/cloudscan/google/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardSSLActiveTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/ssl/active/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardSSLExpiredTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/ssl/expired/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardSSLExpireSoonTable = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/ssl/expire_soon/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardAttackSurfaceData = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/assets/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardCompliancesData = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/compliances/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardCompliancesAffectData = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/compliances_assets/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardHttpStatus = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/httpx/status/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardSubdomainStatus = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/subdomain/status/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  dashboardEndpointStatus = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/katana/status/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  applicationVulnerabilityApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/application_vul/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  networkVulnerabilityApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/network_vul/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  exposedCriticalPortsApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/ports/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  technologiesApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/technologies/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  countryApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/countries/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  geoMapApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/countries/map/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getDNSrecordApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/dashboard/dns/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  addVulnerabilityApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/voc/user-vulnerability/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
        return error.response.data;
      });
    return reponse;
  };
  updateVulnerabilityApi = (id, payload) => {
    const reponse = Repository.put(
      `${baseUrl}ultraheals/v1/voc/user-vulnerability/${id}`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
        return error.response.data;
      });
    return reponse;
  };

  getSubDomainApi = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/scan/subdomains/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  createSubDomainApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/asm/subdomain/create/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getTableFilterApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/team/users/filter/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getSubscriptionPlanApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/subscription/payment/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getPlanTypeApi = (payload) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/subscription/plans/`,
      payload
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getTechnologyApi = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/technology/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getDNSApi = (id) => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/web/asm/dns/${id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };
  getComplianceTableApi = (id, type) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/asm/compliances/${type}/${id}`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          removeAuthenticationData();
        }
      });
    return reponse;
  };

  getAgentsApi = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/web/agents/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
    return reponse;
  };
  getTargetAgentsApi = (id) => {
    const reponse = Repository.get(
      `${baseUrl}ultraheals/v1/web/target/${id}/agents`
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
    return reponse;
  };
  updateTargetAgent = (id, data) => {
    const reponse = Repository.post(
      `${baseUrl}ultraheals/v1/web/target/${id}/agents/`,
      data
    )
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
    return reponse;
  };
  getClients = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/web/clients/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
    return reponse;
  };
  getDRPScanHistory = () => {
    const reponse = Repository.get(`${baseUrl}ultraheals/v1/web/digital-risk/scan-history/`)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
    return reponse;
  }
}

export default new DashboardApiFetch();
