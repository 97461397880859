import { useContext, useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";

import { ThemeContext } from "../../../../../context/ThemeContext";
import { Sidebar } from "primereact/sidebar";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import { toast } from "react-toastify";

export default function Overview() {
  const { isDarkMode } = useContext(ThemeContext);

  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(null);

  const getData = () => {
    complianceAPI
      .get("/trust-center/overview/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateData = () => {
    complianceAPI
      .post("/trust-center/overview/", data)
      .then((res) => {
        toast.success("Updated Successfully");
        setEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className={`rounded-2xl p-5  ${
        isDarkMode ? "header-dark" : "header-light white-background"
      }`}
    >
      <div className="flex justify-between">
        <p className="font-bold">About</p>
        <button
          className="p-button-text outline-none flex border p-1 gap-2 items-center rounded-md"
          onClick={() => setEdit(true)}
        >
          Edit <FaRegEdit />
        </button>
      </div>
      <p className="my-2">{data?.about || "No about added"}</p>
      <Sidebar visible={edit} onHide={() => setEdit(false)} position="right">
        <p className="font-bold text-2xl"> Edit About</p>

        <div className="w-full">
          <label className="block text-gray-500 text-sm">About</label>
          <textarea
            row={10}
            className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
            placeholder="Enter about"
            value={data?.about}
            onChange={(e) => {
              setData({ ...data, about: e.target.value });
            }}
          />
        </div>
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            onClick={updateData}
          >
            Save
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={() => setEdit(false)}
            type="reset"
          >
            Back
          </button>
        </div>
      </Sidebar>
    </div>
  );
}
