import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { AiOutlineCalendar } from "react-icons/ai";
import { useParams } from "react-router-dom";

import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../../helpers";
import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";

export const TYPE = [
  {
    label: "VSA",
    value: "vsa",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

export default function AddQuestionnaire({ visible, onHide, currentDetails }) {
  const { id } = useParams();
  const [data, setData] = useState({
    vendor: id,
  });
  const [error, setError] = useState({});

  const [openReviewDate, setOpenReviewDate] = useState(false);
  const [openSubmissionDate, setOpenSubmissionDate] = useState(false);
  const [employee, setEmployee] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/questionnaire/${currentDetails.id}/`, { ...data, vendor: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Questionnaire updated successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/questionnaire/", { ...data, vendor: id })
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Questionnaire added successfully");
            e.target.submit.removeAttribute("disabled");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className={`w-full md:w-20rem lg:w-30rem`}
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Questionnaire`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } Questionnaire`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter Name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Submission Deadline
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.submission_deadline)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenSubmissionDate(!openSubmissionDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openSubmissionDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.submission_deadline)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        submission_deadline: DateSelectionToDjangoFormat(
                          e.value
                        ),
                      });
                      setOpenSubmissionDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Review Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.review_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenReviewDate(!openReviewDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openReviewDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.review_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        review_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenReviewDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Reviewer</label>
            <Dropdown
              value={data.reviewer}
              onChange={(e) => setData({ ...data, reviewer: e.value })}
              options={employee}
              filter
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Type</label>
            <Dropdown
              value={data.type}
              onChange={(e) => setData({ ...data, type: e.value })}
              options={TYPE}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Answer</label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="Enter Answer"
              value={data.answer}
              // required
              onChange={(e) => {
                setData({ ...data, answer: e.target.value });
                setError({ ...error, answer: false });
              }}
            />
            {error.answer && (
              <p className="text-red-500 text-sm">{error.answer}</p>
            )}
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
