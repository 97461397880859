import { useState, useEffect, useRef, useContext } from "react";
import { TbArrowUpRight } from "react-icons/tb";

import GenericLayout from "../../common/layout/GenericLayout";
import { cspmAPI } from "../../Api/CSPM/api";

import { useNavigate } from "react-router";
import ExpandableGraph from "./ExpandableGraph";
import { ThemeContext } from "../../context/ThemeContext";

export default function ResourceMapping() {
  const ref = useRef(null);
  const { isDarkMode } = useContext(ThemeContext);

  const height = ref.current?.clientHeight;
  const width = ref.current?.clientWidth;

  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const getData = () => {
    console.log("data");
    cspmAPI
      .get("/dashboard/resource-graph")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(height, width);

  if (window.location.href.includes("cloud-security-posture-management")) {
    return (
      <div
        ref={ref}
        onDoubleClick={() => {
          navigate("/resource-mapping");
        }}
      >
        {data ? (
          <ExpandableGraph height={300} width={width} graphData={data} />
        ) : (
          <div className="flex justify-center items-center h-[300px]">
            <p className="text-center">Loading...</p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <GenericLayout>
        <div className="flex justify-between dashboard-header items-center">
          <p className="font-bold">Cloud Security Posture Management</p>
          <div className="flex justify-between">
            <button
              className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
              onClick={() => navigate("/cloud-security-posture-management")}
            >
              Go to dashboard
              <TbArrowUpRight />
            </button>
          </div>
        </div>
        <div className="flex justify-between dashboard-header items-center">
          <p className="font-bold">Resource Mapping</p>
        </div>
        <div
          className={`w-max-full h-max-full rounded-2xl ${
            isDarkMode ? "header-dark" : "bg-white"
          }`}
          ref={ref}
        >
          {data ? (
            <ExpandableGraph height={620} width={width} graphData={data} />
          ) : (
            <div className="flex justify-center items-center h-[620px]">
              <p className="text-center">Loading...</p>
            </div>
          )}
        </div>
      </GenericLayout>
    );
  }
}
