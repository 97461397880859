import { useEffect, useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import { MultiSelect } from "primereact/multiselect";
import { AiOutlineCalendar } from "react-icons/ai";
import { Calendar } from "primereact/calendar";

import { complianceAPI } from "../../../../../Api/CSPM/api";
import HandleResponseError from "../../../../../utils/error";
import {
  DateSelectionToDjangoFormat,
  DateTimeToDate,
} from "../../../../../helpers";

export const recurrence = [
  {
    label: "Annually",
    value: "annually",
  },
  {
    label: "Bi-Annually",
    value: "bi_annually",
  },
  {
    label: "Quarterly",
    value: "quarterly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Never",
    value: "never",
  },
];

export default function AddPolicy({ visible, onHide, currentDetails }) {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [openReviewDate, setOpenReviewDate] = useState(false);

  const [employee, setEmployee] = useState([]);
  const [department, setDepartment] = useState([]);
  const [entities, setEntities] = useState([]);
  const [frameworks, setFrameworks] = useState([]);

  const handleCreate = (e) => {
    e.preventDefault();
    e.target.submit.setAttribute("disabled", "disabled");
    if (currentDetails) {
      complianceAPI
        .patch(`/policy/${currentDetails.id}/`, data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Policy updated successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    } else {
      complianceAPI
        .post("/policy/", data)
        .then((res) => {
          if (!res.id) {
            HandleResponseError(res);
            e.target.submit.removeAttribute("disabled");
          } else {
            toast.success("Policy created successfully");
            cleanClose();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message);
          e.target.submit.removeAttribute("disabled");
        });
    }
  };

  const getEmployee = () => {
    complianceAPI
      .post("/employees/filter/")
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getDepartment = () => {
    complianceAPI
      .post("/departments/filter/")
      .then(({ data }) => {
        setDepartment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntities = () => {
    complianceAPI
      .post("/entities/filter/")
      .then(({ data }) => {
        setEntities(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFrameworks = () => {
    complianceAPI
      .post("/framework/filter/")
      .then(({ data }) => {
        setFrameworks(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEmployee();
    getDepartment();
    getEntities();
    getFrameworks();
  }, []);

  useEffect(() => {
    if (currentDetails) setData(currentDetails);
  }, [currentDetails]);

  const cleanClose = () => {
    onHide();
    setData({});
    setError({});
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={cleanClose}
      className="w-full md:w-20rem lg:w-30rem sidebar-style"
    >
      <p className="font-bold text-2xl">{`${
        currentDetails ? "Edit" : "Create New"
      } Policy`}</p>
      <p className="text-sm py-1">{`${
        currentDetails ? "Edit" : "Create a new"
      } policy`}</p>
      <div className="flex flex-wrap my-2 gap-2">
        <form onSubmit={(e) => handleCreate(e)} className="w-full">
          <div className="w-full">
            <label className="block text-gray-500 text-sm ">Name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Enter policy name"
              value={data.name}
              required
              onChange={(e) => {
                setData({ ...data, name: e.target.value });
                setError({ ...error, name: false });
              }}
            />
            {error.name && <p className="text-red-500 text-sm">{error.name}</p>}
          </div>
          <div className="w-full">
            <label className="block text-gray-500 text-sm">
              Policy Requirement
            </label>
            <textarea
              row={10}
              className="rounded border-[1px] h-32 my-1 border-[#CECECE] w-full py-2 px-3 text-base placeholder-color focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]"
              placeholder="policy requirement"
              value={data.requirement}
              // required
              onChange={(e) => {
                setData({ ...data, requirement: e.target.value });
                setError({ ...error, requirement: false });
              }}
            />
            {error.requirement && (
              <p className="text-red-500 text-sm">{error.requirement}</p>
            )}
          </div>
          {/* <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Framework</label>
            <Dropdown
              filter
              value={data?.framework}
              onChange={(e) => setData({ ...data, framework: e.value })}
              options={frameworks}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div> */}
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Frameworks</label>
            <MultiSelect
              className="w-full"
              optionLabel="name"
              optionValue="id"
              filter
              value={data.frameworks}
              options={frameworks}
              placeholder="Select Frameworks"
              onChange={(e) => setData({ ...data, frameworks: e.value })}
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Entities</label>
            <Dropdown
              value={data?.entities}
              onChange={(e) => setData({ ...data, entities: e.value })}
              options={entities}
              filter
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Assignee</label>
            <Dropdown
              filter
              value={data?.assignee}
              onChange={(e) => setData({ ...data, assignee: e.value })}
              options={employee}
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Recurrence</label>
            <Dropdown
              value={data?.recurrence}
              onChange={(e) => setData({ ...data, recurrence: e.value })}
              options={recurrence}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Department</label>
            <Dropdown
              filter
              value={data?.department}
              onChange={(e) => setData({ ...data, department: e.value })}
              options={department}
              optionLabel="name"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Approver</label>
            <Dropdown
              filter
              value={data?.approver}
              onChange={(e) => setData({ ...data, approver: e.value })}
              options={employee}
              optionLabel="email"
              optionValue="id"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="w-full">
            <label className="text-sm font-normal text-[#686868]">
              Review Date
            </label>
            <div className="relative">
              <input
                value={DateTimeToDate(data.review_date)}
                placeholder="___ __, ____"
                className="flex items-center date-picker px-3 py-2 justify-between w-full border-[1px] border-[#AAAAAA] rounded-2xl"
              />
              <div
                className="absolute cursor-pointer top-[12px] right-[12px]"
                onClick={() => setOpenReviewDate(!openReviewDate)}
              >
                <AiOutlineCalendar />
              </div>
            </div>
            {openReviewDate && (
              <div className="mx-auto">
                <div className="">
                  <Calendar
                    value={new Date(data.review_date)}
                    onChange={(e) => {
                      setData({
                        ...data,
                        review_date: DateSelectionToDjangoFormat(e.value),
                      });
                      setOpenReviewDate(false);
                    }}
                    inline
                    style={{ width: "360px" }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="w-full pt-3">
            <label className="block text-gray-500 text-sm">Scope</label>
            <Dropdown
              value={data.out_of_scope}
              onChange={(e) => setData({ ...data, out_of_scope: e.value })}
              options={[
                {
                  label: "Out of Scope",
                  value: true,
                },
                {
                  label: "In Scope",
                  value: false,
                },
              ]}
              optionLabel="label"
              optionValue="value"
              placeholder="Select"
              className="w-full md:w-14rem my-1"
            />
          </div>
          <div className="flex w-full mt-9">
            <button
              className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
              type="submit"
              id="submit"
            >
              {currentDetails ? "Save" : "Add"}
            </button>
            <button
              className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
              onClick={cleanClose}
              type="reset"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}
