import React, { useEffect, useRef, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { REGEX } from "../../../utils/constants/index";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { message } from "antd";
import SkeletonAnimation from "../../SkeletonAnimation/SkeletonAnimation";
import { LineWave } from "react-loader-spinner";
import {
  getUserDetails,
  setUserDetails,
  setLoggedInValue,
} from "../../../redux-toolkit/redux/auth";

const CompleteProfileForm = () => {
  const userDetails = useSelector(getUserDetails);

  // const [countries, setCountries] = useState([]);
  const [show, setShow] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorMobile, setErrorMobile] = useState(false);
  const [errorCompanyName, setErrorCompanyName] = useState(false);
  const [errorCompanyDomain, setErrorCompanyDomain] = useState(null);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);
  const [errorEmailText, setErrorEmailText] = useState("");
  const [errorMobileText, setErrorMobileText] = useState("");
  const [errorConfirmPasswordText, setErrorConfirmPasswordText] = useState("");
  const [errorPasswordText, setErrorPasswordText] = useState("");
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);
  const [checkedBok, setCheckedBox] = useState(false);
  const [requireChecked, setRequireChecked] = useState();
  const [loading, setLoading] = useState(false);

  const firstNameInputRef = useRef(null);

  useEffect(() => {
    firstNameInputRef.current.focus();
  }, []);

  useEffect(() => {
    setFirstName(userDetails.first_name);
    setLastName(userDetails.last_name);
    setCompanyName(userDetails.company);
    setCompanyDomain(userDetails.domain);
    setMobile(userDetails.phone);
  }, [userDetails]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const togglePassword = () => {
    setPasswordEye(!passwordEye);
  };
  const toggle = () => {
    setOpen(!open);
  };
  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    if (firstName === "") {
      setErrorFirstName(true);
    }
    if (lastName === "") {
      setErrorLastName(true);
    }
    if (email === "") {
      setErrorEmail(true);
      setErrorEmailText("Email Required");
    } else if (email) {
      REGEX.EMAIL_ID.test(email) ? setErrorEmail(false) : setErrorEmail(true);
      setErrorEmailText("Invalid Email");
    }

    if (mobile === "") {
      setErrorMobile(true);
      setErrorMobileText("PhoneNumber Required");
    }
    // else if (mobile) {
    //   console.log("VALID", REGEX.MOBILE_NUMBER.test(mobile));
    //   REGEX.MOBILE_NUMBER.test(mobile)
    //     ? setErrorMobile(false)
    //     : setErrorMobile(true);
    //   setErrorMobileText("Invalid Mobile Number");
    // }
    if (companyName === "") {
      setErrorCompanyName(true);
    }
    if (!companyDomain) {
      setErrorCompanyDomain("Company Domain is required");
    }
    // check domain and mail matching
    if (companyDomain && email) {
      const emailDomain = email.split("@");
      if (companyDomain !== emailDomain[1]) {
        setErrorCompanyDomain("Company Domain and Email Domain does not match");
      }
    }
    // if (password === "") {
    //   setErrorPassword(true);
    //   setErrorPasswordText("Password is required");
    // } else if (password) {
    //   REGEX.PASSWORD.test(password)
    //     ? setErrorPassword(false)
    //     : setErrorPassword(true);
    //   setErrorPasswordText(
    //     "Minimum 12 to 16 characters with uppercase and symbols"
    //   );
    // }
    // if (confirmPassword === "") {
    //   setErrorConfirmPassword(true);
    //   setErrorConfirmPasswordText("Confirm password is required");
    // } else if (confirmPassword) {
    //   if (REGEX.PASSWORD.test(confirmPassword)) {
    //     if (password !== confirmPassword) {
    //       setErrorConfirmPassword(true);
    //       setErrorConfirmPasswordText(
    //         "Password and confirm password does not match"
    //       );
    //     } else {
    //       setErrorConfirmPassword(false);
    //     }
    //   } else {
    //     setErrorConfirmPassword(true);
    //     setErrorConfirmPasswordText(
    //       "Minimum 12 to 16 characters with uppercase and symbols"
    //     );
    //   }
    // }
    if (
      firstName !== "" &&
      lastName !== "" &&
      // email !== "" &&
      mobile !== "" &&
      companyName !== "" &&
      // REGEX.PASSWORD.test(confirmPassword) &&
      // !errorCompanyDomain &&
      !errorCompanyDomain
      // password === confirmPassword
    ) {
      if (checkedBok) {
        setLoading(true);
        AuthApi.updateMe({
          first_name: firstName,
          last_name: lastName,
          // email: email,
          phone: mobile,
          company: companyName,
          // password: confirmPassword,
          domain: companyDomain,
        })
          .then((data) => {
            if (data.status === "success") {
              setLoading(false);
              message.success(data.message);
              // localStorage.setItem("userEmail", email);
              // setTimeout(() => {
              //   navigate("/signup/authenticate");
              // }, 1000);
              dispatch(
                setUserDetails({
                  ...userDetails,
                  ...{
                    first_name: firstName,
                    last_name: lastName,
                    phone: mobile,
                    company: companyName,
                    domain: companyDomain,
                  },
                })
              );
              dispatch(setLoggedInValue(true));
            } else {
              setLoading(false);
              message.error(data.message);
            }
          })
          .catch((err) => {
            console.log("api error response", err);
          });
      } else {
        message.error("please check the box if you want to proceed");
      }
    }
  };

  useEffect(() => {}, [loading]);

  return (
    <div className="respon-style flex flex-col items-center w-full py-24 lg:px-10 2xl:px-[115px] px-6 login-style ">
      <div>
        <h1 className="font-bold text-2xl text-center">Complete Profile</h1>
      </div>

      <form className="w-full " onSubmit={handleSignUpSubmit}>
        <div className="pt-9 flex flex-col w-full ">
          <div className="grid grid-cols-2 gap-2">
            <div className="w-full md:mb-0 signup-label">
              <label className="block text-sm pb-1  ">First Name</label>
              <input
                className={` ${
                  errorFirstName && "border-color-error border-error-focus"
                } 
               placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA]  
               block  px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                ref={firstNameInputRef}
                placeholder="John"
                value={firstName}
                onChange={(e) => {
                  setErrorFirstName(false);
                  setFirstName(e.target.value);
                }}
              />
              {errorFirstName && (
                <p className="text-red-500 text-sm">FirstName is required</p>
              )}
            </div>
            <div className="w-full md:mb-0 signup-label">
              <label className="block text-gray-500 text-sm pb-1 ">
                Last Name
              </label>
              <input
                className={`${
                  errorLastName && "border-color-error border-error-focus"
                } 
               placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] 
               block  px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type="text"
                placeholder="Johnson"
                value={lastName}
                onChange={(e) => {
                  setErrorLastName(false);
                  setLastName(e.target.value);
                }}
              />
              {errorLastName && (
                <p className="text-red-500 text-sm">LastName is required</p>
              )}
            </div>
          </div>

          {/* <div className="w-full pt-4">
            <label className="block text-gray-500 text-sm pb-1">Email</label>
            <input
              className={` ${
                errorEmail && "border-color-error border-error-focus"
              } placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] 
               block py-2 px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="email"
              value={email}
              placeholder="john@gmail.com"
              onChange={(e) => {
                setErrorEmail(false);
                setEmail(e.target.value);
              }}
            />
            {errorEmail && (
              <p className="text-red-500 text-sm">{errorEmailText}</p>
            )}
          </div> */}

          <div className="w-full pt-4">
            <label className="block pb-1 text-gray-500 text-sm">
              Phone number
            </label>
            <div
              className={`${
                errorMobile && "border-color-error border-error-focus"
              } ${
                isPhoneNumberFocused &&
                "focus-within:ring-[0.5px] ring-[#717171]"
              } flex border-[1px] relative border-color-active h-[40px] rounded-md  text-lg`}
            >
              <select className="focus:outline-none rounded-l-md pl-2 ">
                <option>UK</option>
              </select>
              <input
                className={` placeholder-color w-full block focus:outline-none border-l-0 rounded-r-md `}
                type="number"
                value={mobile}
                onFocus={() => setIsPhoneNumberFocused(true)}
                placeholder="+44 (__) ___-___-__"
                onChange={(e) => {
                  setErrorMobile(false);
                  setMobile(e.target.value);
                }}
              />
            </div>
            {errorMobile && (
              <p className="text-red-500 text-sm">{errorMobileText}</p>
            )}
          </div>

          <div className="w-full pt-4">
            <label className="block text-gray-500 text-sm pb-1">
              Company Name
            </label>
            <input
              className={`${
                errorCompanyName && "border-color-error border-error-focus"
              } 
               placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] 
               block py-2 px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              value={companyName}
              placeholder="Cyber Heals"
              onChange={(e) => {
                setErrorCompanyName(false);
                setCompanyName(e.target.value);
              }}
            />
            {errorCompanyName && (
              <p className="text-red-500 text-sm">Company Name is required</p>
            )}
          </div>

          <div className="w-full pt-4">
            <label className="block text-gray-500 text-sm pb-1">
              Domain Name
            </label>
            <input
              className={`${
                errorCompanyDomain && "border-color-error border-error-focus"
              } 
               placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] 
               block py-2 px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              value={companyDomain}
              required
              placeholder="Cyber Heals"
              onChange={(e) => {
                setErrorCompanyDomain(null);
                setCompanyDomain(e.target.value);
              }}
            />
            {errorCompanyDomain && (
              <p className="text-red-500 text-sm">{errorCompanyDomain}</p>
            )}
          </div>

          {/* <div className="w-full pt-4">
            <label className="block text-gray-500 text-sm pb-1">
              Password (Min 12 - 16 characters)
            </label>
            <div className="relative">
              <input
                className={`${
                  errorPassword && "border-color-error border-error-focus"
                } 
                 placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] 
                 block py-2 px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type={passwordEye === false ? "password" : "text"}
                placeholder="************"
                value={password}
                onChange={(e) => {
                  setErrorPassword(false);
                  setPassword(e.target.value);
                }}
              />
              {errorPassword && (
                <p className="text-red-500 text-sm">{errorPasswordText}</p>
              )}
              <button
                type="button"
                className="absolute top-5 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                onClick={togglePassword}
              >
                {passwordEye ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
          </div>

          <div className="w-full pt-4">
            <label className="block text-gray-500 text-sm pb-1">
              Confirm Password (Min 12 - 16 characters)
            </label>
            <div className="relative">
              <input
                className={`${
                  errorConfirmPassword &&
                  "border-color-error border-error-focus"
                } placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-md text-lg focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
                type={open === false ? "password" : "text"}
                placeholder="************"
                value={confirmPassword}
                onChange={(e) => {
                  {
                    setErrorConfirmPassword(false);
                    setConfirmPassword(e.target.value);
                  }
                }}
              />
              {errorConfirmPassword && (
                <p className="text-red-500 text-sm">
                  {errorConfirmPasswordText}
                </p>
              )}
              <button
                type="button"
                className="absolute top-5 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                onClick={toggle}
              >
                {open ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
            </div>
          </div> */}

          <p className="flex flex-wrap items-center py-4">
            <input
              className="mr-2 "
              type="checkbox"
              onChange={() => {
                setCheckedBox(!checkedBok);
              }}
            />
            I agree to{" "}
            <span className="ml-1 text-blue-500">
              {" "}
              Terms and Privacy Policy
            </span>{" "}
          </p>
          <div className=" md:items-center">
            <div className="">
              <button
                type="submit"
                className={`w-full p-3 rounded-[48px] text-white text-base relative ${
                  loading ? "button-disabled" : "button-bg-color"
                } flex justify-center items-center`}
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div className="absolute -bottom-3">
                      <LineWave color="#fff" height={100} width={100} />
                    </div>
                    <span className="ml-16">Saving profile...</span>
                  </div>
                ) : (
                  <>
                    <span>Save</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompleteProfileForm;
