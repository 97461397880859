import { Sidebar } from "primereact/sidebar";
import { DateTimeToDate } from "../../../../../helpers";
import handleDownload from "../../../../../utils/fileDownload";
import { GrDocument } from "react-icons/gr";
import { GoDownload } from "react-icons/go";

export default function ViewIncidentManagement({
  currentDetails,
  setCurrentDetails,
  onHide,
  columns,
}) {
  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={onHide}
      className={`w-full md:w-20rem lg:w-30rem custom-sidebar-width`}
    >
      <p className="font-bold text-2xl text-center">{currentDetails?.title}</p>
      <div className="grid grid-cols-2 gap-3 mt-4">
        <div>
          <h1 className="text-sm text-color font-bold">Category</h1>
          <p className="text-sm text-color">{currentDetails?.category}</p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Status</h1>
          <p className="text-sm text-color">
            {currentDetails?.status
              ? columns
                  .find((c) => c.field === "status")
                  .value.find((v) => v.value === currentDetails?.status).label
              : null}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Reported by</h1>
          <p className="text-sm text-color">
            {currentDetails?.reported_by
              ? columns
                  .find((c) => c.field === "reported_by")
                  .value.find((v) => v.value === currentDetails?.reported_by)
                  .label
              : null}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Investigator</h1>
          <p className="text-sm text-color">
            {currentDetails?.investigator
              ? columns
                  .find((c) => c.field === "investigator")
                  .value.find((v) => v.value === currentDetails?.investigator)
                  .label
              : null}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Reported Date</h1>
          <p className="text-sm text-color">
            {DateTimeToDate(currentDetails?.date_time)}
          </p>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Attachment</h1>
          <div className="grid grid-cols-2 w-full p-2 items-center gap-2 rounded border">
            <div className="flex items-center">
              <GrDocument />
              <div className="p-1 ml-2 flex flex-col">
                <p className="text-sm text-color font-normal leading-5">
                  {currentDetails?.attachments_info[0]?.name}
                </p>
              </div>
            </div>
            <div className="justify-self-end">
              <div
                className="text-sm flex items-center gap-1 cursor-pointer"
                onClick={() =>
                  handleDownload(currentDetails?.attachments_info[0])
                }
              >
                <GoDownload />
                <p>Download</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h1 className="text-sm text-color font-bold">Reported Date</h1>
          <p className="text-sm text-color">
            {DateTimeToDate(currentDetails?.date_time)}
          </p>
        </div>
      </div>
      <div className="mt-3">
        <h1 className="text-sm text-color font-bold">Details</h1>
        <p className="text-sm text-color">{currentDetails?.details}</p>
      </div>
    </Sidebar>
  );
}
