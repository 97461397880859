import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../../context/ThemeContext";
import CloudSurfaceAWSTable from "../../../home/components/tables/AttackSurface/CloudSurfaceAWSTable";
import LiveHosts from "../../../home/components/tables/AttackSurface/LiveHosts";
import SSLExpireSoonTable from "../../../home/components/tables/AttackSurface/SSLExpireSoonTable";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import { SSLFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";
import { DateTimeToDate } from "../../../../helpers";

const SSLExpiredSoon = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails)
  const scanID = userDetails.scan_id

  const [sslExpireSoonTableData, setSSLExpireSoonTableData] = useState([]);
  const [sslExpireSoonFilterData, setSSLExpireSoonFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({scanID: scanID})
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const { title } = props;

  const getSSLExpireSoonDataTable = (id) => {
    setLoadingTable(true)
    // DashboardApi.dashboardSSLExpireSoonTable(id).then((data) => {
    //   if (data.status === "success") {
    //     setSSLExpireSoonTableData(data.data);
    //     setCountData(data.count)
    //     setLoadingTable(false)
    //   }
    // });
    SSLFilterAPI.post({...filterParams, ssl_status:'Expire Soon'})
    .then(res=> {
      setSSLExpireSoonTableData(res.data.map(item => {
        return {
          ...item, 
          expiry_date: DateTimeToDate(item.expiry_date)
        }
      }));
        setCountData(res.data_len);
        setLoadingTable(false);
    })
    .catch(err => console.log(err))
  };

  const getTableFilterData = () => {
    SSLFilterAPI.get({scanID: scanID, ssl_status:'Expire Soon'})
    .then(res => {
      setSSLExpireSoonFitlerData(res.data)
      setFilterParams({...filterParams, ...res.data})
    })
    .catch(err => console.log(err))
  }

  useEffect(() => {
    getTableFilterData()
  }, []);

  useEffect(()=>{
    if (Object.keys(filterParams).length > 1) getSSLExpireSoonDataTable()
  },[filterParams])
  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
        <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <SSLExpireSoonTable 
          loadingTable={loadingTable} 
          sslExpireSoonTableData={sslExpireSoonTableData}
          sslExpireSoonFilterData={sslExpireSoonFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
          />
      </div>
    </>
  );
};

export default SSLExpiredSoon;
