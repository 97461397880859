import { Sidebar } from "primereact/sidebar";
import { SEVERITY_COLOR } from "../../modules/home/components/compliance/complianceTable";

export default function ViewPTaaSVulnerability({
  currentDetails,
  setCurrentDetails,
  onHide,
}) {
  return (
    <Sidebar
      visible={currentDetails}
      position="right"
      onHide={onHide}
      className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
    >
      <div className="px-6">
        <div className="flex justify-between pt-6">
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">Host</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.host}
            </p>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">IP Address</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.ip}
            </p>
          </div>
        </div>
        <div className="flex justify-between gap-2 pt-6">
          <div className="w-1/3">
            <div>
              <h1 className="text-sm text-color font-bold">Severity</h1>
              <span className={SEVERITY_COLOR[currentDetails?.severity]}>
                {currentDetails?.severity}
              </span>
            </div>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">CVE</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.cve_id}
            </p>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">CVSS Score</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.cvss_score}
            </p>
          </div>
        </div>
        <div className="flex  gap-2 py-6">
          <div className="w-1/3">
            <div>
              <h1 className="text-sm text-color font-bold">Exploits</h1>
              <p className="text-xs text-color font-normal leading-5">
                {currentDetails?.exploits}
              </p>
            </div>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">Exploitability</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.exploitability}
            </p>
          </div>
          <div className="w-1/3">
            <h1 className="text-sm text-color font-bold">Business Risk</h1>
            <p className="text-xs text-color font-normal leading-5">
              <span className={SEVERITY_COLOR[currentDetails?.risk]}>
                {currentDetails?.risk}
              </span>
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-3">
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">URL</h1>
            <p className="text-xs text-color font-normal leading-5">
              <a
                className="text-blue-500"
                href={currentDetails?.url}
                target="_blank"
              >
                {currentDetails?.url}
              </a>
            </p>
          </div>

          {currentDetails?.exploits === "Yes" && (
            <div className="w-full">
              <h1 className="text-sm text-color font-bold">Exploit URL</h1>
              <p className="text-xs text-color font-normal leading-5">
                <a
                  className="text-blue-500"
                  href={currentDetails?.exploit_url}
                  target="_blank"
                >
                  {currentDetails?.exploit_url}
                </a>
              </p>
            </div>
          )}

          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Vulnerability</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.name}
            </p>
          </div>

          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Description</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.description}
            </p>
          </div>
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">POC</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.poc}
            </p>
          </div>
          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Impact</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.impact}
            </p>
          </div>

          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Mitigation</h1>
            <p className="text-xs text-color font-normal leading-5">
              {currentDetails?.mitigation}
            </p>
          </div>

          <div className="w-full">
            <h1 className="text-sm text-color font-bold">Reference</h1>
            <ul className="list-disc">
              {currentDetails?.reference?.match(/[^\r\n]+/g).map((data) => (
                <li className="text-xs text-color font-normal leading-5">
                  <a href={data} target="_blank" className="text-blue-500">
                    {data}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
