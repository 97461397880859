import React, { useState, useEffect } from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { SidebarSlide } from "../../../Component/Header/SidebarSlide";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import Toggle from "react-toggle";
import { BsCheckLg } from "react-icons/bs";
import SecurityScreenTable from "./SecurityScreenTable";
import { edit } from "../../../assets/svg";

const SecurityScreen = (props) => {
  const { isDarkMode } = props;
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleMultifactorRight, setVisibleMultifactorRight] = useState(false);
  const [checkAuthenticate, setCheckAuthenticate] = useState(false);
  const [appType, setAppType] = useState([]);

  // const appType = ["App", "Email", "Mobile" ];

  const handleChange = (value) => {
    if (!checkAuthenticate) {
      setVisibleMultifactorRight(true);
    }
  };

  useEffect(() => {
    TableApi.mfaToggleVerify().then((data) => {
      if (data.status === "success") {
        setCheckAuthenticate(data.mfa);
        // var lab =["App","Email","Mobile"];
        var lab = ["App", "Email"];
        // var val = [data.app, data.email,data.mobile];
        var val = [data.app, data.email];
        var options = [];
        for (var i = 0; i < val.length; i++) {
          options.push({ label: lab[i], value: val[i] });
        }
        setAppType(options);
      }
    });
  }, []);

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <div className="grid grid-flow-row  gap-4">
            <div className="col-span-2">
              <div
                className={`w-full p-8 rounded-2xl ${
                  isDarkMode
                    ? "header-dark text-white"
                    : "white-background text-color "
                }`}
              >
                <div>
                  <div className="grid grid-cols-3 gap-4">
                    <div
                      className={`col-span-2 ${
                        isDarkMode
                          ? "header-dark text-white"
                          : "white-background text-color "
                      }`}
                    >
                      <p className={`text-[16px] font-bold pb-2`}>Password</p>
                      <p className="text-[16px] font-normal">
                        You can use this password along with your email to login
                        to platform, please keep your password strong and change
                        frequently
                      </p>
                    </div>
                    <div>
                      <div className="flex justify-between profile-security">
                        <input
                          className={` focus:outline-none w-[93px] ${
                            isDarkMode
                              ? "header-dark text-white placeholder-white"
                              : "white-background text-color placeholder-color"
                          } `}
                          placeholder="************"
                          type="password"
                        />

                        <button
                          className="text-[14px] text-[#074EE8]"
                          onClick={() => setVisibleRight(true)}
                        >
                          {/* <MdOutlineModeEditOutline size={24} /> */}
                          <img src={edit} alt="edit" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row-span-2 col-span-2">
              <div
                className={`w-full  p-8 rounded-2xl ${
                  isDarkMode
                    ? "header-dark text-white"
                    : "white-background text-color "
                }`}
              >
                <div>
                  <div className="grid grid-cols-3 gap-4">
                    <div className="col-span-2">
                      <p className="text-[16px] font-bold pb-2">
                        Two-factor Authentication
                      </p>
                      <p className="text-[16px] font-normal">
                        Two-factor authentication (2FA) is a second layer of
                        security to protect your account, it protects your
                        account by asking you to enter verification code sent to
                        your email address, authentication app or phone number
                      </p>
                    </div>
                    <div>
                      <div className=" profile-security">
                        <div className="flex flex-wrap justify-between security-toggle">
                          <div>
                            <Toggle
                              checked={checkAuthenticate}
                              icons={{
                                checked: (
                                  <BsCheckLg
                                    size={20}
                                    style={{
                                      color: "white",
                                      position: "absolute",
                                      left: "-6px",
                                      top: "-5px",
                                    }}
                                  />
                                ),
                                unchecked: null,
                              }}
                              onChange={(e) => handleChange(e.target.checked)}
                            />
                          </div>
                          {checkAuthenticate && (
                            <div>
                              <button
                                className="text-[14px] text-[#074EE8]"
                                onClick={() => setVisibleMultifactorRight(true)}
                              >
                                <img src={edit} alt="edit" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="grid grid-cols-1 gap-2" id="itemForm">
                          {appType.map((appData, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-2 pt-2"
                            >
                              <input
                                type="checkbox"
                                className="h-4 w-4 custom-checkbox"
                                checked={appData.value}
                              />
                              <label className={`text-base font-normal`}>
                                {appData.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={`w-full h-[525px]  py-6 px-[15px] rounded-2xl security-table-style ${
              isDarkMode
                ? "header-dark text-white"
                : "white-background text-color "
            }`}
          >
            <SecurityScreenTable isDarkMode={isDarkMode} />
          </div>
        </div>
      </div>

      <div>
        <SidebarSlide
          title="Change password"
          description="Manage your password"
          visibleRight={visibleRight}
          setVisibleRight={setVisibleRight}
        />
      </div>
      <div>
        <SidebarSlide
          title="Multifactor Authentication"
          description="Secure your UltraHeals account with multifactor verification"
          visibleRight={visibleMultifactorRight}
          setVisibleRight={setVisibleMultifactorRight}
        />
      </div>
    </div>
  );
};

export default SecurityScreen;
