import React, { useContext, useState } from "react";
import { useEffect } from "react";
import DashboardApi from "../../../../Api/AuthenticationApi/DashboardApi";
import { ThemeContext } from "../../../../context/ThemeContext";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux-toolkit/redux/auth";
import ExploitsTable from "../../../home/components/tables/AttackSurface/ExploitsTable";
import { ExploitFilterAPI } from "../../../../Api/FIlterAPI/tableFilterApi";

const Exploit = (props) => {
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scanID = userDetails.scan_id;

  const [cloudAwsTableData, setCloudAwsTableData] = useState([]);
  const [exploitFilterData, setExploitFitlerData] = useState([]);
  const [filterParams, setFilterParams] = useState({ scanID: scanID });
  const [countData, setCountData] = useState();
  const [loadingTable, setLoadingTable] = useState(true);

  const { title } = props;

  const getCloudDataTable = () => {
    setLoadingTable(true);
    // DashboardApi.dashboardExploitsTable(id, "exploits").then((data) => {
    //   if (data.status === "success") {
    //     setCloudAwsTableData(data.data);
    //     setCountData(data.count)
    //     setLoadingTable(false)

    //   }
    // });
    ExploitFilterAPI.post({ ...filterParams, exploits: "yes" })
      .then((res) => {
        setCloudAwsTableData(res.data);
        setCountData(res.count);
        setLoadingTable(false);
      })
      .catch((err) => console.log(err));
  };

  const getTableFilterData = () => {
    ExploitFilterAPI.get({ scanID: scanID, exploits: "yes" })
      .then((res) => {
        setExploitFitlerData(res.data);
        setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTableFilterData();
  }, []);

  useEffect(() => {
    if (Object.keys(filterParams).length > 1) getCloudDataTable();
  }, [filterParams]);

  return (
    <>
      <div
        className={` rounded-2xl userTable-style  ${
          isDarkMode
            ? "header-dark  text-white"
            : "header-light white-background  text-color"
        }`}
      >
        <div className="flex flex-wrap dashboard-table">
          <p className="font-bold">
            {title} <span> ({countData}) </span>
          </p>
        </div>
        <ExploitsTable
          cloudAwsTableData={cloudAwsTableData}
          loadingTable={loadingTable}
          exploitFilterData={exploitFilterData}
          setFilterParams={setFilterParams}
          filterParams={filterParams}
        />
      </div>
    </>
  );
};

export default Exploit;
