import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";

import integrationsAPI from "../../../../Api/integerations/api";

export default function Redirect() {
  const { service } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const addIntegration = () => {
    let code = null;
    switch (service) {
      case "github":
        code = searchParams.get("installation_id");
        break;
      default:
        code = searchParams.get("code");
        break;
    }

    integrationsAPI
      .post(`/redirect/`, { service, code })
      .then((res) => {
        console.log(res);
        setMessage(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setMessage({ ...err, error: true });
      });
  };

  
  useEffect(() => {
    let timer;
    if(!loading) {
      timer = setTimeout(() => {
        window.close();
      }, 5000);  
    }
    
    return () => clearTimeout(timer);
  }, [loading])

  useEffect(() => {
    addIntegration();
  }, [service && searchParams]);

  console.log(searchParams);
  
  return (
    <div className="flex justify-center items-center h-screen w-screen">
      {loading ? (
        <ProgressSpinner />
      ) : (
        <div className="flex flex-col items-center gap-4 p-4 bg-white rounded-lg shadow-lg max-w-[50%]">
          <h1
            className={`text-2xl font-bold ${
              message.error ? "text-[#E8001D]" : "text-[#148842]"
            }`}
          >
            {message.message}
          </h1>
          <p className="text-lg font-semibold">
            {message.error
              ? "Please try again"
              : "Now you can close this tab and go back to the application"}
          </p>
        </div>
      )}
    </div>
  );
}
