import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { BsInfoCircle } from "react-icons/bs";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";
import SkeletonDonut from "../../modules/SkeletonAnimation/SkeletonDonut";
import { InformationIconDark, InformationIconLight } from "../../assets/svg";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { GoThumbsup } from "react-icons/go";
import { Dropdown } from "primereact/dropdown";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = (props) => {
  const {
    title,
    type,
    labels,
    colors,
    chartData,
    conditionType,
    screen,
    total,
    legends,
    border,
    background,
    live,
    navigateLink,
    navigateState = {},
    tooltip,
    showing = null,
    setShowing = null,
  } = props;
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const backgroundRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });

  const updateBackgroundDimensions = () => {
    const backgroundDiv = backgroundRef.current;

    if (backgroundDiv) {
      backgroundDiv.style.width = canvasSize.width + "px";
      backgroundDiv.style.height = canvasSize.height + "px";
    }
  };

  useEffect(() => {
    // Get the canvas element and its dimensions
    const canvas = canvasRef.current;

    if (canvas) {
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      // Set the dimensions of the inner div
      setCanvasSize({ width: canvasWidth, height: canvasHeight });
    }
  }, []); // This effect runs only once

  useEffect(() => {
    // Update the background dimensions whenever canvasSize changes
    updateBackgroundDimensions();
  }, [canvasSize]);

  useEffect(() => {
    // Add an event listener for window resize to handle changes in viewport size
    const handleWindowResize = () => {
      const canvas = canvasRef.current;

      if (canvas) {
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        setCanvasSize({ width: canvasWidth, height: canvasHeight });
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //   const legends= [
  //     {name:"critical" , color:"#a30123"},
  //     {name:"hign" , color:"#E8001D"},
  //     {name:"medium" , color:"#F5B207"},
  //     {name:"low" , color:"#148842"},
  // ]

  const footer = () => {
    return total;
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: total === 0 ? [100] : chartData,
        backgroundColor: total === 0 ? ["#F8F8F8"] : colors,
        borderColor: total === 0 ? ["#F8F8F8"] : colors,
        cutout: "90%",
        borderRadius: 50,
        // offset: 10,
        spacing: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (ctx, data) => {
            console.log("datalabel", data, ctx);
            if (
              ctx.dataset.label === "Risk by severity" ||
              ctx.dataset.label === "Issues by severity"
            ) {
              return total === 0
                ? "0"
                : `${((ctx.formattedValue / total) * 100).toFixed(0)}%`;
            } else {
              return total === 0 ? "0" : ctx.formattedValue;
            }
          },
          title: (ctxs) => {
            console.log("datatitle", ctxs);
            return null;
          },
        },
      },
    },
    elements: {
      arc: {
        spacing: 2,
        borderWidth: 0,
      },
    },
  };

  // const total = chartData.reduce((acc, value) => acc + value, 0);

  const title1 = (
    // <p className=''>Sample</p>
    <div className="relative">
      <div className="w-[90%]">
        <h1
          className="flex items-center justify-center text-base font-bold leading-normal"
          title={title}
        >
          {title.split("").length > 25 ? `${title.slice(0, 22)}...` : title}
        </h1>
      </div>
      <button className="flex justify-end items-end w-[10%]">
        <BsInfoCircle />
      </button>
    </div>
  );

  return (
    // <div className="card flex justify-content-center w-full">
    <>
      <div className="px-3 flex items-center justify-evenly mt-5 relative">
        <div className="w-[100%] flex justify-center">
          <h1 className="flex items-center justify-center text-base font-bold leading-normal overflow-hidden whitespace-nowrap">
            <span className="truncate" title={title}>
              {/* {" "}
              {title.split("").length > 25 ? `${title.slice(0, 22)}...` : title} */}
              {title}
            </span>
          </h1>
        </div>
        <button className="flex justify-end items-end cursor-pointer absolute right-0">
          <img
            className="w-[18px]"
            data-tooltip-id={title}
            src={isDarkMode ? InformationIconDark : InformationIconLight}
            alt="info"
          />
        </button>
      </div>
      {showing && (
        <Dropdown
          value={showing}
          onChange={(e) => setShowing(e.value)}
          options={[
            {
              label: "Inherent Risk",
              value: "inherent_risk",
            },
            {
              label: "Residual Risk",
              value: "residual_risk",
            },
          ]}
          optionLabel="label"
          optionValue="value"
          placeholder="Select"
          className="w-full h-7 my-1 risk-assessment"
        />
      )}
      {/* {title === "Vulnerabilities by status" && total === 0 ? (
        <>
          <Card className="rounded-2xl card-style">
            <div
              className="donut-chart flex justify-center"
              style={{ minHeight: "200px" }}
              onClick={() => navigate(navigateLink)}
            >
              <div className="w-[112px] h-[112px] rounded-full border-2 border-[#C4E1CF] bg-[#C4E1CF] flex justify-center items-center">
                <GoThumbsup size={64} color={"#148842"} />
              </div>
            </div>
            <div className="text-center my-2 h-[60px]">
              <p className="font-bold text-[#111]">Congratulation!</p>
              <p>You have 0 vulnerabilities</p>
            </div>
          </Card>
        </>
      ) : ( */}
      <>
        <Card className="rounded-2xl card-style">
          <div
            className="donut-chart flex justify-center"
            style={{
              minHeight: "200px",
              cursor: navigateLink ? "pointer" : "default",
            }}
            onClick={() => navigate(navigateLink, { state: navigateState })}
          >
            <Doughnut ref={canvasRef} data={data} options={options} />
            {type != "normal" && (
              <div
                ref={backgroundRef}
                className="absolute rounded-full w-[1rem] h-[1rem]"
                style={{
                  background: `${background}`,
                  border: `10px solid ${border}`,
                }}
              ></div>
            )}
            <div className="absolute donut-font flex flex-col items-center justify-center w-full ">
              <h1
                className={`${
                  isDarkMode ? "dark-mode text-white" : " text-[#111] "
                } font-bold`}
              >
                {total}
                {screen == "liveHosts" ? (
                  <span className="text-[18px]">/{live}</span>
                ) : (
                  ""
                )}
              </h1>
              {type != "normal" ? (
                <p className={`text-base font-normal text-[${border}]`}>
                  {conditionType}
                </p>
              ) : (
                <p
                  className={`${
                    isDarkMode ? "dark-mode text-white" : " text-[#111] "
                  } text-base font-normal`}
                >
                  {conditionType}
                </p>
              )}
            </div>
          </div>
        </Card>

        <div className="flex flex-wrap items-center justify-center  my-2 h-[60px]">
          {legends.map((legend) => (
            <div
              className={`flex gap-2 items-center mx-1 py-1 ${
                legend.onClick && "cursor-pointer"
              }`}
              onClick={() => {
                if (legend.onClick) {
                  legend.onClick();
                }
              }}
            >
              <div
                className={`h-[8px] w-[8px] bg-[${legend.color}] rounded-full`}
                style={{ backgroundColor: legend.color }}
              />
              <h1 className="text-sm">{legend.name}</h1>
            </div>
          ))}
        </div>
      </>
      {/* )} */}

      <ReactTooltip
        id={title}
        place="bottom-end"
        content={tooltip}
        style={{ maxWidth: "300px" }}
      />
    </>
    //   <div className="relative py-5 overflow-hidden">
    //   <div className='px-3 pb-5 flex items-center justify-evenly'>
    //     <div className='w-[90%]'>
    //       <h1 className='flex items-center justify-center text-base font-bold leading-normal'>{title}</h1>
    //     </div>
    //     <div className='flex justify-end items-end w-[10%]'>
    //       <BsInfoCircle />
    //     </div>
    //   </div>
    //   <div className='lg:w-full w-fit h-[20rem] m-auto'>
    //     <Doughnut
    //       data={data}
    //       options={options}
    //     />
    //   </div>
    //   <div className='absolute lg:top-[40%] top-[40%] flex flex-col items-center justify-center w-full'>
    //     <h1 className='text-4xl font-bold'>{total}</h1>
    //     <p className='text-base font-normal '>{conditionType}</p>
    //   </div>
    // </div>
  );
};

export default DonutChart;
