import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { REGEX } from "../../../utils/constants";
import AuthApi from "../../../Api/AuthenticationApi/AuthApi";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {
  setLoggedInValue,
  setLoginToken,
  getIsLoggedIn,
} from "../../../redux-toolkit/redux/auth";
import SkeletonAnimationLogin from "../../SkeletonAnimation/SkeletonAnimationLogin";
import { LineWave } from "react-loader-spinner";
import Auth0 from "../../../services/auth0";
import { AppleLogo, google, MSlogo } from "../../../assets/svg";

const LoginForm = () => {
  // Initializing our Auth0Lock

  const userEmail = localStorage.getItem("userEmail");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("Invalid Email");
  const [passwordErrorMessage, setPasswordErrorMessage] =
    useState("Invalid Password");
  const [accountActivate, setAccountActivate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordLogin, setPasswordLogin] = useState(false);

  const emailInputRef = useRef(null);

  useEffect(() => {
    emailInputRef.current.focus();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedin = useSelector(getIsLoggedIn);

  const handlePasswordVisibilityToggle = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordInputFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordInputBlur = () => {
    setIsPasswordFocused(false);
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();

    setIsEmailError(false);
    setIsPasswordError(false);

    if (email === "") {
      setIsEmailError(true);
      setEmailErrorMessage("Email Field required");
    } else if (!validateEmail(email)) {
      setIsEmailError(true);
      setEmailErrorMessage("Invalid Email");
    }

    if (password === "") {
      setIsPasswordError(true);
      setPasswordErrorMessage("Password Field required");
    } else if (password) {
      // if (REGEX.PASSWORD.test(password)) {
      //   setIsPasswordError(false);
      // } else {
      //   setIsPasswordError(true);
      //   setPasswordErrorMessage(
      //     "Min 8 character used with Uppercase,symbols and numbers"
      //   );
      // }
    }

    if (email && password !== "") {
      setLoading(true);
      Auth0.login(
        {
          email: email,
          password: password,
        },
        (res) => {
          message.error(res.description);
          setLoading(false);
        }
      );
      // AuthApi.login({ username: email, password: password })
      //   .then((data) => {
      //     if (data.status === "success") {
      //       setLoading(false);
      //       message.success(data.message);
      //       setTimeout(() => {
      //         dispatch(setLoggedInValue(true));
      //         dispatch(setLoginToken(data.data));
      //         localStorage.setItem("userEmail", data.data.email);
      //         navigate("/login/authenticate");
      //       }, 1000);
      //     } else {
      //       setLoading(false);
      //       message.error(data.message);
      //     }
      //   })
      //   .catch((err) => {
      //     console.log("api error response", err);
      //   });
    }
  };

  const loginWithOTP = () => {
    setIsEmailError(false);
    setIsPasswordError(false);

    if (email === "") {
      setIsEmailError(true);
      setEmailErrorMessage("Email Field required");
      return false;
    } else if (!validateEmail(email)) {
      setIsEmailError(true);
      setEmailErrorMessage("Invalid Email");
      return false;
    }

    AuthApi.checkEmail({ email: email })
      .then(() => {
        Auth0.passwordlessStart(
          {
            connection: "email",
            send: "code",
            email: email,
          },
          (err, result) => {
            console.log(err, result);
            setLoading(false);
            if (!err) {
              message.success(
                "Please check your registered email to login to your account"
              );
              setTimeout(() => {
                dispatch(setLoggedInValue(true));
                // dispatch(setLoginToken(data.data));
                localStorage.setItem("userEmail", email);
                navigate("/login/authenticate", {
                  state: { type: "otp" },
                });
              }, 1000);
            } else message.error(err.errorDescription);
          }
        );
      })
      .catch((err) => {
        message.error("Email not found");
        return false;
      });

    // AuthApi.loginOtp({ email: email })
    //   .then((data) => {
    //     if (data.status === "success") {
    //       setLoading(false);
    //       message.success(data.message);
    //       setTimeout(() => {
    //         dispatch(setLoggedInValue(true));
    //         dispatch(setLoginToken(data.data));
    //         localStorage.setItem("userEmail", data.data.email);
    //         navigate("/login/authenticate", {
    //           state: { type: data.data.type },
    //         });
    //       }, 1000);
    //     } else {
    //       setLoading(false);
    //       message.error(data.message);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("api error response", err);
    //   });
  };

  useEffect(() => {
    let tokenCode = window.location.search.split("?").length
      ? window.location.search.split("?")[1]
      : "";
    if (tokenCode) {
      navigate("/login");
      AuthApi.accountActivate({ email: userEmail, code: tokenCode }).then(
        (data) => {
          if (data.status === "success") {
            setAccountActivate(true);
            message.success(data.message);
          } else {
            setAccountActivate(false);
            message.error(
              data.message ? data.message : "account Not activated"
            );
          }
        }
      );
    }
    if (!isLoggedin) {
      // window.location.reload()
      localStorage.removeItem("userDetails");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userTable");
    }
  }, [loading, isLoggedin]);

  return (
    <div className="flex flex-col items-center w-full py-24 lg:px-6 2xl:px-[75px] px-3 login-style ">
      {isLoggedin && accountActivate ? (
        <>
          <p className="font-bold text-2xl text-center">Congratulation!</p>
          <div>
            <p className="text-center ">
              Your account is activated. You can now log in your Ultra Heals App
              account
            </p>
          </div>
        </>
      ) : (
        <div>
          <h1 className="text-2xl font-bold text-center text-color">Sign in</h1>
        </div>
      )}

      <form className="w-full" onSubmit={handleLoginSubmit}>
        <div className="pt-12 flex flex-col gap-5 items-center">
          {/* Login with Email Form */}
          <div className="flex flex-col w-full label-color">
            <label className="label-color text-sm">Email</label>
            <input
              name="email"
              type="email"
              placeholder="john@gmail.com"
              className={`py-2 px-4 rounded-md text-lg placeholder-color text-color 
            ${
              isEmailError
                ? "border-color-error border-error-focus"
                : "border-color-disable border-focus"
            } `}
              value={email}
              ref={emailInputRef}
              autoFocus
              onChange={(e) => {
                setIsEmailError(false);
                setEmail(e.target.value);
              }}
            />
            <div>
              {isEmailError && (
                <h1 className="error text-sm">{emailErrorMessage}</h1>
              )}
            </div>

            {passwordLogin ? (
              <>
                <div className="py-4">
                  <label className="label-color text-sm">Password</label>
                  <div
                    className={`relative py-2 px-4 rounded-md text-lg 
            ${isPasswordFocused ? "focus-within:ring-[0.5px] ring-[#717171]" : ""}
            ${isPasswordError ? "border-color-error" : "border-color-disable"}
            ${
              isPasswordError && isPasswordFocused
                ? "focus-within:ring-[0.5px] ring-[#CD1F43]"
                : ""
            }
            `}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="******"
                      value={password}
                      onChange={(e) => {
                        setIsPasswordError(false);
                        setPassword(e.target.value);
                      }}
                      onFocus={handlePasswordInputFocus}
                      onBlur={handlePasswordInputBlur}
                      className="focus:outline-none focus:ring-0 focus:border-none placeholder-color text-color w-[95%]"
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 icon-color focus:outline-none"
                      onClick={handlePasswordVisibilityToggle}
                    >
                      {showPassword ? (
                        <AiFillEyeInvisible size={20} />
                      ) : (
                        <AiFillEye size={20} />
                      )}
                    </button>
                  </div>
                  <div>
                    {isPasswordError && (
                      <h1 className="error text-sm">{passwordErrorMessage}</h1>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-1/2">
                    <span
                      className="cursor-pointer link-color text-base"
                      onClick={loginWithOTP}
                    >
                      Login with OTP
                    </span>
                  </div>
                  <div className="w-1/2 text-right">
                    <Link to="/reset/password" className="link-color text-base">
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <button
                  type="submit"
                  className={`w-full p-3 my-2 rounded-[48px] text-white text-base relative ${
                    loading ? "button-disabled" : "button-bg-color"
                  } flex justify-center items-center`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center">
                      <div className="absolute -bottom-3">
                        <LineWave color="#fff" height={100} width={100} />
                      </div>
                      <span className="ml-16">Logging in...</span>
                    </div>
                  ) : (
                    <>
                      <span>Log in</span>
                    </>
                  )}
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setPasswordLogin(true)}
                  className={`w-full p-3 mt-5 my-2 rounded-[48px] text-white text-base relative ${
                    loading ? "button-disabled" : "button-bg-color"
                  } flex justify-center items-center`}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex items-center">
                      <div className="absolute -bottom-3">
                        <LineWave color="#fff" height={100} width={100} />
                      </div>
                      <span className="ml-16">Logging in...</span>
                    </div>
                  ) : (
                    <>
                      <span>Next</span>
                    </>
                  )}
                </button>
                <div className="flex justify-center">
                  <span
                    className="cursor-pointer link-color text-base"
                    onClick={loginWithOTP}
                  >
                    Login with OTP
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </form>

      {passwordLogin && (
        <div>
          <Link to="/login/company">
            <div className="link-color pt-6 text-base">Login with company</div>
          </Link>
        </div>
      )}
      <p className="m-2 text-gray-400">or</p>
      {/* Div for OAuth */}
      <div className="flex flex-col lg:flex-row gap-2 w-full label-color text-white">
        <div 
          className="lg:w-1/2 lg:mb-0 mb-2 flex items-center cursor-pointer p-2 rounded-md border border-zinc-400 bg-blue-600"
          onClick={() => {
            Auth0.authorize({ connection: "google-auth" });
          }}
        >
          <img src={google} className="w-7 mr-auto bg-white rounded-sm" alt="" />
          <p className="xl:text-sm text-xs mr-auto">Sign-in with Google</p>
        </div>
        <div 
          className="lg:w-1/2 flex items-center cursor-pointer p-2 rounded-md border border-zinc-400 bg-[#282828]"
          onClick={() => {
            Auth0.authorize({ connection: "ms-login" });
          }}
          >
          <img src={MSlogo} className="w-6 mr-auto bg-white rounded-sm" alt="" />
          <p className="text-xs xl:text-sm mr-auto">Sign-in with Microsoft</p>
        </div>
      </div>
      {/* <div className="flex gap-4">
        <div
          className="flex flex-col items-center justify-between cursor-pointer p-3"
          onClick={() => {
            Auth0.authorize({ connection: "google-auth" });
          }}
        >
          <img src={google} className="w-10" />
          <p>Google</p>
        </div>
        <div
          className="flex flex-col items-center justify-between cursor-pointer p-3"
          onClick={() => {
            Auth0.authorize({ connection: "ms-login" });
          }}
        >
          <img src={MSlogo} className="w-10" />
          <p>Microsoft</p>
        </div> */}
        {/* <div
          className="flex flex-col items-center justify-between cursor-pointer p-3"
          onClick={() => {
            Auth0.authorize({ connection: "apple" });
          }}
        >
          <img src={AppleLogo} className="w-10" />
          <p>Apple</p>
        </div> */}
      {/* </div> */}
      {/* <button
        type="submit"
        onClick={() => {
          Auth0.authorize({ connection: "google-oauth2" });
        }}
        className={`w-full p-3 my-2 rounded-[48px] text-white text-base relative button-bg-color flex justify-center items-center`}
      >
        <span>Login with Google</span>
      </button> */}
    </div>
  );
};

export default LoginForm;
