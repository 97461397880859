import { usePdf } from "@mikecousins/react-pdf";
import { Sidebar } from "primereact/sidebar";
import { useRef, useState } from "react";

export default function PdfViewer({ viewPDF, setViewPDF }) {
  const canvasRef = useRef(null);
  const [page, setPage] = useState(1);

  const { pdfDocument, pdfPage } = usePdf({
    file: viewPDF,
    page,
    canvasRef,
  });

  return (
    <Sidebar
      visible={viewPDF}
      position="right"
      onHide={() => setViewPDF(null)}
      className="w-full md:w-20rem lg:w-30rem custom-sidebar-width"
    >
      {/* <iframe src={viewPDF} /> */}
      <div className="flex flex-col gap-3 w-full justify-center">
        {!pdfDocument && <span>Loading...</span>}
        <canvas ref={canvasRef} />
        {Boolean(pdfDocument && pdfDocument.numPages) && (
          <nav>
            <ul className="pager flex justify-between">
              <li className="previous">
                <button
                  className="p-button-text flex p-1 gap- items-center rounded-md border border-gray-300"
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  Previous
                </button>
              </li>
              <li className="next">
                <button
                  className="p-button-text flex p-1 gap- items-center rounded-md border border-gray-300"
                  disabled={page === pdfDocument.numPages}
                  onClick={() => setPage(page + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </Sidebar>
  );
}
